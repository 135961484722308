/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 15. 1. 2020 16:09
 */

import * as modal from "../../components/Modal/actions";
import api from "../../utils/api";
import i18n from 'i18next';
import {createTrackingDataString} from "../../utils/tracking";
import {openRegisterModal} from '../../Modules/RegisterLoginModal/actions';

export {closeSearchPage} from '../MyProjects/actions';
export {openRegisterModal, openLoginModal, MODAL_SECTION_REGISTRATION, MODAL_SECTION_LOGIN} from '../../Modules/RegisterLoginModal/actions'; // TODO mozna to cele presunout sem .. nebo naopak do vlastniho reduceru


export const SET_INIT_PAGE = 'SET_INIT_PAGE';
export const SET_CURRENT_PAGES = 'SET_CURRENT_PAGES';
export const SET_ADD_PROJECT_ERROR = 'SET_ADD_PROJECT_ERROR';
export const SET_ADD_PROJECT_VALUE = 'SET_ADD_PROJECT_VALUE';
export const TRACK_ADD_PROJECT_INPUT = 'TRACK_ADD_PROJECT_INPUT';
export const SET_ADD_PROJECT_CAPTCHA_VALUE = 'SET_ADD_PROJECT_CAPTCHA_VALUE';

export const OPEN_SEARCH_PAGE = 'OPEN_SEARCH_PAGE';
export const CLOSE_SEARCH_PAGE = 'CLOSE_SEARCH_PAGE';


export const CLOSE_PAGE_SUBPAGE = 'CLOSE_PAGE_SUBPAGE';
export const CLOSE_PAGE_DETAIL = 'CLOSE_PAGE_DETAIL';
export const CLOSE_PAGE_SEARCH = 'CLOSE_PAGE_SEARCH';

export const MODAL_SECTION_ADD_PROJECT = 'Layout/MODAL_SECTION_ADD_PROJECT';


export const initPage = (data) => ({type: SET_INIT_PAGE, data});

/**
 * @param {any[]} pages
 * @param {string} projectId
 * @param {string} projectName
 * @return {{pages: any[], type: string, projectName: string, projectId: string}}
 */
export function setCurrentPages(pages = [], projectId = null, projectName = null)
{
	return {type: SET_CURRENT_PAGES, pages, projectId, projectName};
}

export const openAddProjectModal = (clickSource) => (dispatch) =>
{
	dispatch(openRegisterModal({clickSource}));
};

export const closeModal = modal.close;

export const setAddProjectError = (error, captcha = null, jsAntiSpamData = undefined) =>
	({type: SET_ADD_PROJECT_ERROR, error, captcha, jsAntiSpamData});
export const setAddProjectFullname = (fullname) => ({type: SET_ADD_PROJECT_VALUE, fullname});
export const setAddProjectCaptchaValue = (captcha) => ({type: SET_ADD_PROJECT_CAPTCHA_VALUE, captcha});
export const trackAddProjectInput = (trackingEvent) => ({type: TRACK_ADD_PROJECT_INPUT, trackingEvent});

/** TODO presunout asi k registracim */
export const addProject = (fullname, trackingData, captcha, jsAntiSpamData) => (dispatch) =>
{
	dispatch(modal.setLoading(true));
	api.projects.create(fullname, createTrackingDataString(trackingData), captcha, jsAntiSpamData)
		.then((data) => {
			if (data.success)
			{
				window.location.replace(data.wizardUrl);
			}
			else
			{
				dispatch(setAddProjectError(data.errors['fullname'], data.captcha, data.jsAntiSpamData))
				dispatch(modal.setLoading(false));
			}
		})
	.catch(
		() =>
		{
			dispatch(setAddProjectError(i18n.t(
				'text[webnode_com][__global_signup-failed__]',
				'Error, please try later...'
			)));
			dispatch(modal.setLoading(false));
		}
	)
}
