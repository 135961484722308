import {isString} from "../../../utils/utils";

class ReCaptchaApi
{
     ACTION_REGISTRATION = 'registration';
     ACTION_LOGIN = 'login';


    #v2PublicKey = null;
    #v3PublicKey = null;
    #language = null;
    #gReCaptcha = null;


    init({v2PublicKey, v3PublicKey, language})
    {
        if (this.#isInitialized())
        {
            throw new Error('ReCaptchaApi already initialized');
        }
        this.#v2PublicKey = v2PublicKey;
        this.#v3PublicKey = v3PublicKey;
        this.#language = language;
        this.#gReCaptcha = globalThis.grecaptcha;
        this.#checkInitialized();
        this.#asyncGrecaptchaLoad();
    }


    #asyncGrecaptchaLoad()
    {
        if (!globalThis.grecaptcha?.ready)
        {
            setTimeout(() => this.#asyncGrecaptchaLoad(), 10);
        }
        else
        {
            this.#gReCaptcha = globalThis.grecaptcha;
        }
    }

    #isInitialized(fully = false)
    {
        return (
            isString(this.#v2PublicKey) &&
            isString(this.#v3PublicKey) &&
            isString(this.#language) &&
            this.#gReCaptcha !== null &&
            (!fully || this.#gReCaptcha?.ready)
        );
    }

    #checkInitialized()
    {
        if (!this.#isInitialized())
        {
            throw new Error('ReCaptchaApi not initialized');
        }
        return true;
    }

    waitOnLoaded() {
        return new Promise((resolve)=> {
            const wait = () => {
                if (this.#gReCaptcha?.ready)
                {
                    resolve();
                    return;
                }
                setTimeout(wait, 10);
            }
            wait();
        });
    }

    renderWhenReady(widget, onChange)
    {
        this.#checkInitialized();
        this.waitOnLoaded().then(() => {
            //https://developers.google.com/recaptcha/docs/faq#can-i-run-recaptcha-v2-and-v3-on-the-same-page
            this.#gReCaptcha.ready(() => {
                this.#gReCaptcha.render(widget, {
                    sitekey: this.#v2PublicKey,
                    hl: this.#language,
                    callback: onChange,
                    "expired-callback": (e = null) => {
                        console.error('Captcha expired', e);
                        onChange(null);
                    },
                    "error-callback": (e = null) => {
                        console.error('Captcha error', e);
                        onChange(null);
                    },
                })
            });
        });
    }
    executeV3WhenReady(action)
    {
        this.#checkInitialized(true)
        //https://developers.google.com/recaptcha/docs/faq#can-i-run-recaptcha-v2-and-v3-on-the-same-page
        return new Promise((resolve, reject) => this.#gReCaptcha.ready(() => {
            this.#gReCaptcha
                .execute(this.#v3PublicKey, {action})
                .then(token => resolve(token))
                .catch(e => reject(e))
            ;
        }));
    }
}

export default new ReCaptchaApi();