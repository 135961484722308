import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import UnsafeHtml from "../UnsafeHtml";
import Label from "./Label";

/**
 * @deprecated - use TextInput component instead
 */
export default class TextField extends React.Component {

	static defaultProps = {
		classic: true,
		disabled: false,
	};

	constructor(props)
	{
		super(props);

		this.state = {
			value: props.value === undefined || props.value === null ? '' : props.value,
		};
	}


	componentDidUpdate(prevProps, prevState, snapshot)
	{
		if ((this.props.value || this.props.value === '') && this.props.value !== prevProps.value)
		{
			this.setState({value: this.props.value});
		}
	}


	handleChange = (event) =>
	{
		this.setState({
			value: event.target.value
		});

		if (this.props.onChange)
		{
			this.props.onChange(event, this.props.name, event.target.value);
		}
	};

	handleBlur = (event) =>
	{
		if (this.props.onBlur)
		{
			this.props.onBlur(event, this.props.name, event.target.value)
		}
	};

	handleFocus = (event) =>
	{
		if (this.props.onFocus)
		{
			this.props.onFocus(event)
		}
	};


	render()
	{
		const {
			name,
			label,
			placeholder,
			// DEPRECATED mela by se pouzivat struktura s errorMessage
			error,
			type,
			required,
			className,
			errorMessage,
			forwardedRef,
			hint,
			newTooltip,
			classic,
			valid,
			disabled,
			id: predefinedId,
		} = this.props;

		const id = predefinedId || 'field-' + name;

		const forwardRef = forwardedRef ? {ref: forwardedRef} : {};

		const hideHintClass = this.props.hideHintClass || false;

		return (
			<>
				<div className={classNames(
					className,
					{
						'form-input-classic': classic,
						'form-input': !classic,
						'not-empty': this.state.value,
						'error': error != null || errorMessage != null,
						'wrong': error != null && !classic,
						'valid': valid,
						'hint': this.props.hint && !hideHintClass ? 'hint' : '',
					}
				)}>
					{classic && label && <Label label={label} htmlFor={id}/>}
					<input
						id={id}
						name={name}
						type={type || 'text'}
						required={required}
						value={this.state.value}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						onFocus={this.handleFocus}
						placeholder={placeholder}
						disabled={disabled}
						{...forwardRef}
					/>
					{classic && error && (
						typeof error === 'string'
						? <UnsafeHtml content={error} className="error"/>
						: <span className="error">{error}</span>
					)}

					{!classic && <Label label={label} htmlFor={id}/> }
					{!classic && error &&  (
						typeof error === 'string'
						? <UnsafeHtml content={error} elem="span" className="inputAlert"/>
						: <span className="inputAlert">{error}</span>
					)}
					{!valid && hint && (newTooltip ? hint : <span className="hint">{hint}</span>)}
				</div>
				{errorMessage && (
					typeof errorMessage === 'string'
					? <UnsafeHtml content={errorMessage} className="w-input-message w-input-error"/>
					: <span className="w-input-message w-input-error">{errorMessage}</span>
				)}
			</>
		)
	}

}

TextField.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.node,
	error: PropTypes.node,
	type: PropTypes.string,
	required: PropTypes.bool,
	className: PropTypes.string,
	errorMessage: PropTypes.node,
	forwardedRef: PropTypes.object,
	hint: PropTypes.node,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	newTooltip: PropTypes.bool,
	classic: PropTypes.bool,
	hideHintClass: PropTypes.bool,
	disabled: PropTypes.bool
}
