import {fetchGraphQLWithCache} from "../../utils/graphql";

export default {
    shouldExecuteReCaptchaV3: (email) => fetchGraphQLWithCache(`
            query FetchShouldExecuteReCaptchaV3(
                $email: String!
            ) {
                shouldExecuteReCaptchaV3(
                    email: $email
                )
            }`,
            {email}
        ).then(data => {
            let shouldExecuteReCaptchaV3 = data.data?.shouldExecuteReCaptchaV3 || false;
            if (typeof shouldExecuteReCaptchaV3 !== 'boolean')
            {
                shouldExecuteReCaptchaV3 = false;
            }
            return shouldExecuteReCaptchaV3;
        }),
};