import {fetchGraphQL, prepareGraphQLData} from '../../utils/graphql';

export default {
	scrapeSiteName: (url) => fetchGraphQL(`
            query ScrapeSiteName(
                $url: String!
            ) {
                scrapeSiteName(
                    url: $url
                )
            }`,
	{url},
	).then((data) => prepareGraphQLData(data, 'scrapeSiteName')),
};
