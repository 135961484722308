import React from "react";
import i18n from "i18next";
import ActionLink from "../../../components/Ui/ActionLink";
import classNames from "classnames";

export class UserMenu extends React.Component
{

	/**
	 * Constructor
	 */
	constructor(props, context)
	{
		super(props, context);

		this.state = {
			// otevřený level
			level: 1,
		};
	}

	/**
	 * Událost při kliknutí na otevření mobilního submenu a back
	 */
	handleBadgeClick = (e) => this.setState({level: 2});
	handleBackClick = (e) => this.setState({level: 1});

	/**
	 * Událost při kliku na login, zde v user menu musí nejdrive user manu zavřít
	 */
	handleLoginClick = (e) => {
		this.props.onClose();
		this.props.onLoginClick();
	}

	static MenuProject = ({url, text}) => <ul>
		<li><a href={url}>{text}</a></li>
	</ul>;


	render()
	{
		return <div className="w-user-menu">
			<div className="w-user-menu-content-outer">
				<div className="w-user-menu-content-inner">

					<div className={classNames("w-user-menu-level-1", {"state-hidden": this.state.level === 2})}>

						{this.props.tabs}

						{this.props.showUserLogin && this.props.userIsLoggedIn
							?
							<ul>
								<li className="w-user-menu-badge">
									<ActionLink onClick={this.handleBadgeClick}>
										<span className="w-user-menu-badge-title">{i18n.t('text[portal-2019][navline][user]', 'User:')}</span>
										<span className="w-user-menu-badge-email">{this.props.userEmail}</span>
									</ActionLink>
								</li>
							</ul>
							:
							<ul>
								<li className="w-user-menu-login">
									<ActionLink onClick={this.handleLoginClick} className="login-submit">
										<span>{i18n.t('text[system][pages][sign-in][name]', 'Login')}</span>
									</ActionLink>
								</li>
							</ul>
						}
					</div>

					{this.props.userIsLoggedIn &&
					<div className={classNames("w-user-menu-level-2", {"state-hidden": this.state.level === 1})}>

						<div className="w-user-menu-username">
							<span><span>{i18n.t('text[portal-2019][navline][user]', 'User:')}</span></span>
						</div>

						<div className="w-user-menu-email">
							<span>{this.props.userEmail}</span>
						</div>

						{/*this.props.project ? <UserMenu.MenuProject {...this.props.project}/> : ''*/}

						<ul>
							{this.props.items.map(({url, text, newWindow}, index) =>
								<li key={'menuItem' + index}>
									<a href={url} target={newWindow ? '_blank' : '_self'}>{text}</a>
								</li>
							)}
						</ul>

						<ul>
							<li className="w-user-menu-logout">
								<a href={this.props.logoutUrl}>
									{i18n.t('text[portal-2019][claim][user-menu][logout]', 'Log out')}
								</a>
							</li>
						</ul>

						<ActionLink onClick={this.handleBackClick} className="w-user-menu-back">
							<span>{i18n.t('text[portal-2019][navline][back]', 'Back')}</span>
						</ActionLink>
					</div>
					}

				</div>
			</div>
			<ActionLink onClick={this.props.onClose} className="w-user-menu-close">
				<span>{/*<!-- Close -->*/}</span>
			</ActionLink>
		</div>;
	}
}
