import i18n from 'i18next';

import api from '../../utils/api';
import * as modal from '../../components/Modal/actions';
import {setData as setRegisterData} from '../Register/actions';
import {setFullName as setRegisterFullName} from '../Register/actions';
import {setData as setLoginData} from '../Login/actions';
import {setError as setRegisterError} from '../Register/actions';
import {createTrackingDataString} from '../../utils/tracking';
import {loginModalEvent, registrationModalEvent} from '../../utils/keenIo';
import queries from './queries.js';

export {
	setError as setLoginError,
	changeInput as changeLoginInput,
	sendSocial as sendLoginSocial,
	send as sendLogin,
	trackEvent as trackLoginEvent,
} from '../Login/actions';

export {
	setError as setRegisterError,
	changeInput as changeRegisterInput,
	sendSocial as sendRegisterSocial,
	send as sendRegister,
	trackEvent as trackRegisterEvent,
} from '../Register/actions';

export {close as closeModal} from '../../components/Modal/actions';


export const MODAL_SECTION_REGISTRATION = 'RegisterLoginModal/MODAL_SECTION_REGISTRATION';
export const MODAL_SECTION_LOGIN = 'RegisterLoginModal/MODAL_SECTION_LOGIN';

export const openRegisterModal = (data = {}) => (dispatch) =>
{
	dispatch(setRegisterData({source: 'modal', ...data}));
	registrationModalEvent(data.clickSource);
	dispatch(modal.open(MODAL_SECTION_REGISTRATION));
};

export const openLoginModal = (data = {}) => (dispatch) =>
{
	dispatch(setLoginData({source: 'modal', ...data}));
	loginModalEvent(data.clickSource);
	dispatch(modal.open(MODAL_SECTION_LOGIN));
};


export const startAIMigrationRegistration = (data, sourceButton) => (dispatch) =>
{
	// open modal with loading
	dispatch(modal.open(MODAL_SECTION_REGISTRATION));
	dispatch(modal.setLoading(true));

	// fetch website title
	queries.scrapeSiteName(data.scrapedURL)
		.then((websiteName) => {
			// fill the website name and hide loading
			dispatch(setRegisterFullName(websiteName));

			// set registration data and hide loading
			dispatch(setRegisterData({
				source: 'AIMigrationTool',
				...data,
			}));
			registrationModalEvent(data.clickSource);
			dispatch(modal.setLoading(false));
		})
		.catch(() => {
			dispatch(modal.close());

			// error handling - pridani classy a zmena textu input hintu
			const errorClassElement = sourceButton.parentElement.parentElement;
			const errorTextElement = errorClassElement.nextSibling;

			errorClassElement.classList.add('error');
			errorTextElement.textContent = i18n.t(
				'text[portal-2015][registration][scraperFailed]',
				'Unable to fetch your website.',
			);
		});
};

export const addProject = (fullname, trackingData, captcha = {}, data = {}) => (dispatch) =>
{
	// tady jsem mimo běžný flow a náš antispam vyžaduje update, jelikož sme tu konstantu mohli předchozím requestem invalidovat
	// neříkám že by to nešlo, jen už to teď fakt potřebuju rozhejbat
	const jsAntiSpamData = window.wnd.$dataStatic['jsAntiSpamData'];

	dispatch(modal.setLoading(true));
	api.projects.create(fullname.value, createTrackingDataString(trackingData), captcha.value, jsAntiSpamData, data)
		.then((data) => {
			if (data.success)
			{
				window.location.replace(data.wizardUrl);
			}
			else
			{
				dispatch(setRegisterError('fullname', data.errors['fullname']));
				// ten antispam je nutno updatovat mimo react flow, ja na těchto polo react stránkách
				// aniž bych to musel vše překopávat
				if (data.jsAntiSpamData)
				{
					window.wnd.$dataStatic['jsAntiSpamData'] =  data.jsAntiSpamData;
				}
				// vypne loading a nastaví captchu + nová antispamData
				dispatch(modal.changeSection(MODAL_SECTION_REGISTRATION, {captcha: data.captcha}));
			}
		})
		.catch(
			() =>
			{
				dispatch(setRegisterError(i18n.t(
					'text[webnode_com][__global_signup-failed__]',
					'Error, please try later...',
				)));
				dispatch(modal.setLoading(false));
			},
		);
};
