import React from 'react';
import PropTypes from 'prop-types';

/**
 * Pointa tohohle je, že kdykoliv používáme dangerouslySetInnerHTML, jde o texty, HTML renderované na straně PHP
 * Psát všude dangerouslySetInnerHTML je mi přijde zbytečně krkolomné.
 * @param elem
 * @param content
 * @param props
 * @return {React.Component}
 * @constructor
 */
export default function UnsafeHtml({elem: Elem = 'div', content, ...props})
{
	return <Elem {...props}  dangerouslySetInnerHTML={{__html: content}} />
}

UnsafeHtml.propTypes = {
	elem: PropTypes.string,
	content: PropTypes.string.isRequired
}

/*
příklad:
	{
		data.content &&
		<UnsafeHtml elem='div' className='super-trida' content={data.content}/>
	}
Oproti:
 	{
		data.content &&
		<div className='super-trida' dangerouslySetInnerHTML={{__html: data.content}}/>
	}

 */

