import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from './actions';
import NonStandardModal from "../../components/Modal/NonStandardModal";
import RegisterModalContent from "../Register/RegisterModalContent";
import LoginModalContent from "../Login/LoginModalContent";
import AddProjectModalContent from "../../Pages/Layout/AddProjectModalContent";

/**
 * Modal s Craeate Website / Login modaly
 */
class RegisterLoginModal extends React.Component {


	componentDidMount()
	{
		// delegace dat pro jsAntiSpam
		const jsAntiSpamData = this.props.jsAntiSpamData;
		this.props.changeRegisterInput(jsAntiSpamData['id'], jsAntiSpamData['value']);
		this.props.changeLoginInput(jsAntiSpamData['id'], jsAntiSpamData['value']);

		if (this.props.captchaChallenge)
		{
			this.props.changeRegisterInput('g-recaptcha-response', '');
			this.props.changeLoginInput('g-recaptcha-response', '');
		}

	}

	/**
	 * Render
	 */
	render()
	{
		const {
			termsAndConsUrl,
			privacyUrl,
			maxProjectNameLength,
			forgotPasswordUrl,
			socialUrl,
			loginUrl,
			registerUrl,
			userIsLoggedIn,
			upgradeNotificationUrl,

			section,
			loading,
		} = this.props; // TODO vyresit predani ze staticData a modal v registerLoginPopups

		if (section === actions.MODAL_SECTION_LOGIN && !this.props.loginForm)
		{
			throw new Error('V js bundlu stranky neni pripojený reducer pro loginForm"');
		}
		if (section === actions.MODAL_SECTION_REGISTRATION && !this.props.registerForm)
		{
			throw new Error('V js bundlu stranky neni pripojený reducer pro registerForm!');
		}

		return (
			<NonStandardModal onClose={this.props.closeModal}>

				{section === actions.MODAL_SECTION_REGISTRATION && userIsLoggedIn &&
				<AddProjectModalContent
					termsAndConsUrl={termsAndConsUrl}
					privacyUrl={privacyUrl}
					loading={loading}
					opened={true}
					onClose={this.props.closeModal}

					{...(this.props.captcha || {})}

					projectMaxLength={maxProjectNameLength}
					onChange={this.props.changeRegisterInput}

					onSubmit={() =>
						this.props.addProject( // todo poslat wizard data
							this.props.registerForm.inputs.fullname,
							this.props.registerForm.trackingData,
							this.props.registerForm.inputs[AddProjectModalContent.CAPTCHA_FORM_KEY],
							this.props.registerForm.data
						)}

					inputs={this.props.registerForm.inputs}
					trackEvent={this.props.trackRegisterEvent}
					captchaChallenge={this.props.captchaChallenge}
				/>
				}

				{section === actions.MODAL_SECTION_REGISTRATION && !userIsLoggedIn &&
				<RegisterModalContent

					termsAndConsUrl={termsAndConsUrl}
					privacyUrl={privacyUrl}
					loading={loading}
					opened={true}
					onClose={this.props.closeModal}

					onSocial={(type) => this.props.sendRegisterSocial(socialUrl, type, this.props.registerForm)}
					onChange={this.props.changeRegisterInput}
					onSubmit={() => this.props.sendRegister(registerUrl, this.props.registerForm)}
					inputs={this.props.registerForm.inputs}
					projectMaxLength={maxProjectNameLength}
					trackEvent={this.props.trackRegisterEvent}
					setError={this.props.setRegisterError}
					captchaChallenge={this.props.captchaChallenge}
				/>
				}

				{section === actions.MODAL_SECTION_LOGIN &&
				<LoginModalContent

					termsAndConsUrl={termsAndConsUrl}
					upgradeNotificationUrl={upgradeNotificationUrl}
					privacyUrl={privacyUrl}
					loading={loading}
					opened={true}
					onClose={this.props.closeModal}

					onSocial={(type) => this.props.sendLoginSocial(socialUrl, type, this.props.loginForm)}
					onChange={this.props.changeLoginInput}
					onSubmit={() => this.props.sendLogin(loginUrl, this.props.loginForm)}
					inputs={this.props.loginForm.inputs}
					forgotPasswordUrl={forgotPasswordUrl}
					trackEvent={this.props.trackLoginEvent}
					setError={this.props.setLoginError}
					captchaChallenge={this.props.captchaChallenge}
				/>}
			</NonStandardModal>)
	}
}

export default connect(
	(state, ownProps) => ({...state, ...ownProps}),
	(dispatch) => bindActionCreators(actions, dispatch),
)(RegisterLoginModal)
