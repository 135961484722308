/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 6. 1. 2021 13:00
 */

import {deepExtend} from "./objectUtils";

class Tracker {

	constructor()
	{
		this.jsonpUnique = 0;
	}


	addKeenEvent(id, eventData = {})
	{
		try
		{
			if (!globalThis.trackerConfig?.events?.[id])
			{
				return;
			}

			const collection = globalThis.trackerConfig.collection;

			// Pro data vytvoříme samostatnou kopii.
			var data = JSON.parse(JSON.stringify(globalThis.trackerConfig.data));

			data.action.identifier= id;
			data.action.name = globalThis.trackerConfig.events[id].name ?? null;
			data.browser.resolution	= window.screen.width + 'x' + window.screen.height;

			data = deepExtend(data, eventData);

			this.jsonpSubmit(collection, data, function(result) {});
		}
		catch (e)
		{
			console.error('Tracker error:', e);
		}
	}

	jsonpSubmit(collection, data, callback) {
		const dataEncoded = encodeURIComponent(btoa(unescape(encodeURIComponent(JSON.stringify(data)))));
		const now = new Date().getTime();
		const jsonpId = 'trackerJSONPCallback' + now + '_' + this.jsonpUnique++;
		const urlPrefix = globalThis.trackerConfig.urlPrefix;
		const url = `${urlPrefix}${collection}?api_key=-&data=${dataEncoded}&modified=${now}&jsonp=${jsonpId}`;

		// Create script
		let script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;

		// Setup handler
		window[jsonpId] = data => {
			callback(data)
			document.getElementsByTagName('head')[0].removeChild(script);
			script = null;
			delete window[jsonpId];
		};
		// Load JSON
		document.getElementsByTagName('head')[0].appendChild(script);
	}


}
const tracker = new Tracker();
export default tracker;