import React from 'react';
import i18next from 'i18next';
import {Trans as OriginalTrans} from 'react-i18next';

const defaultOptions = {
	NB:'\u00A0', // non-breaking space
	NBH: '\u2011', // non-breaking hyphen
};

const defaultComponents = {
	em: <em/>,
};


// TODO POKUD NĚKDO NĚCO UPRAVÍTE, otestujte IMPORT
/**
 * Nepodporuje zatím všechny propsy Trans, ale pokud bude třeba přidat je možno. (Akorát je třeba tam dát adekvátní default hodnotu)
 * @param {import('react-i18next').TransProps}) props
 * @return {*}
 */
export default function Trans({values = {}, i18nKey, defaults, components = {}, replace = {}, children = undefined, ...rest})
{
	const defaultTransTags = !components || (typeof components === 'object' && !Array.isArray(components));

	if (defaultTransTags && !children)
	{
		components =
			{...defaultComponents,
				...(components || {}),
			};
	}

	try
	{
		// NESM9 SE JMENOVAT Trans, protože jinak padá parser pro extrakci textů
		return (
			<OriginalTrans
				i18nKey={i18nKey}
				defaults={defaults}
				i18n={i18next}
				values={{...defaultOptions, ...values}}
				t={(key, options) => translate(key, options, replace)}
				components={components}
				{...rest}
			>
				{children}
			</OriginalTrans>
		);
	}
	// pokud to textu napíšou blbost, tak ať bnepadne stránka
	catch
	{
		return '';
	}
}

function translate(key, options, replace)
{
	let text = i18next.t(key, options).replaceAll('&nbsp;', '\u00A0');
	for (const replaceKey of Object.keys(replace))
	{
		text = text.replaceAll(replaceKey, replace[replaceKey]);
	}
	return text;
}
