import React from 'react';
import i18n from 'i18next';
import classNames from 'classnames';
import Trans from '../../../components/Ui/Trans';
import WebnodeLogo from '../../../components/Ui/Layout/WebnodeLogo';
import {UserMenu} from './UserMenu';
import ActionLink from '../../../components/Ui/ActionLink';
import UnsafeHtml from '../../../components/Ui/UnsafeHtml';
import PropTypes from 'prop-types';

/**
 * Navline
 */
export default class Navline extends React.Component 
{

	static ROLE_NONE = null;
	static ROLE_HP = 'hp';
	static ROLE_WIZ = 'wiz';

	static defaultProps = {
		logo: <WebnodeLogo/>,
		showCreateWebsite: true,
		showUserLogin: true,
		showHelp: true,
		role: Navline.ROLE_NONE,
	};

	constructor(props, context)
	{
		super(props, context);

		this.state = {
			// vždy muže být otevřeno právě jedno menu
			menu: null, // notifications|user
		};
	}


	/**
	 * Zpracování události kliku na ikonu menu/notifikací
	 */
	handleToggleUserMenu = () => this.toggleMenu('user');
	handleToggleNotificationsMenu = () => this.toggleMenu('notifications');

	/**
	 * Připojení komponenty
	 */
	componentDidMount()
	{
		window.document.addEventListener('click', this.handleDocumentClick);
		window.document.addEventListener('keyup', this.handleDocumentEscapeKeyUp);
		window.document.addEventListener('closeMenu', this.closeMenu);
	}

	/**
	 * Odpojení komponenty
	 */
	componentWillUnmount()
	{
		window.document.removeEventListener('click', this.handleDocumentClick);
		window.document.removeEventListener('keyup', this.handleDocumentEscapeKeyUp);
		window.document.removeEventListener('closeMenu', this.closeMenu);
	}

	/**
	 * Zpracování clicku do dokumentu
	 */
	handleDocumentClick = (e) =>
	{
		if (this.state.menu === null)
		{
			return;
		}

		const menuTriggerSelector = '.w-navline-menu-item-' + this.state.menu
			// menu se přes back ještě přepínají mezi sebou =>
			// při kliku na back taky nechceme menu zavřít, přestože není v aktuálně otevřeném menu
			+ ', .w-notifications-menu-back, .w-user-menu-back'
			// notifikace maji jeste jeden speciální trigger
			+ (this.state.menu === 'notifications' ? ', .w-user-menu-notifications' : '');

		const menuContentSelector = '.w-' + this.state.menu + '-menu';

		// pokud kliknu do elementu pro otevření daného menu, nebo do čehokoliv v menu, tak nezavírám
		if (
			e.target.matches(menuTriggerSelector) || e.target.closest(menuTriggerSelector) ||
			e.target.matches(menuContentSelector) || e.target.closest(menuContentSelector)
		)
		{
			return;
		}

		this.closeMenu();
	};

	/**
	 * Zpracování stisku ESC
	 */
	handleDocumentEscapeKeyUp = (e) =>
	{
		if ((+e.keyCode) === 27)
		{
			this.closeMenu();
		}
	};

	/**
	 * Otevře/Zavře aktuální menu
	 *
	 * @param {string} menu
	 */
	toggleMenu = (menu) => 
	{
		this.state.menu === menu
			? this.closeMenu()
			: this.openMenu(menu);
	};

	/**
	 * Otevře zadané menu a zavře všechny ostatní
	 *
	 * @param {string} menu
	 */
	openMenu = (menu) => 
	{

		// prohodíme třidu active-user-menu|active-notifications-menu
		if (this.state.menu !== null)
		{
			window.document.body.classList.replace('active-' + this.state.menu + '-menu', 'active-' + menu + '-menu');
		}
		else
		{
			window.document.body.classList.add('active-' + menu + '-menu');
		}

		this.setState({ menu: menu });
	};

	/**
	 * Zavře zadané menu
	 * @param {string} menu
	 */
	closeMenu = () =>
	{
		if (this.state.menu === null)
		{
			return;
		}

		// oddelame body class
		window.document.body.classList.remove('active-' + this.state.menu + '-menu'); //active-user-menu|active-notifications-menu

		// nastavime stav zavřeného menu
		this.setState({ menu: null });
	};

	/**
	 * Vykreslí jednu položku tabu
	 */
	static Tab = ({active, href, children, className, ...props}) => (
		<li className={classNames(className, {'state-active': active})} {...props}>
			{href !== null
				? <a href={href}>{children}</a>
				: <span>{children}</span>
			}
		</li>
	);

	/**
	 * Vykresli přehled kroků pro mobil
	 */
	static TabsMobile = ({step, total}) => (
		<span className="w-navline-tabs-mobile">
			<Trans
				i18nKey={'text[portal-2019][navline][progress][steps]'}
				components={{strong: <strong/>}}
				defaults={'Step <strong>{step}</strong> of <strong>{total}</strong>'}
				values={{step, total}}
			/>
		</span>
	);

	/**
	 * Backlink pod navline
	 */
	static Backlink = ({href, onClick, children}) => (
		<div className="w-navline-back-link">
			<span>
				{onClick
					? <ActionLink onClick={onClick}>{children}</ActionLink>
					: <a href={href}>{children}</a>
				}
			</span>
		</div>
	);

	static Logo = ({url, logo}) => (
		(typeof logo === 'string')
			? (url
				? <div className="w-navline-logo"><UnsafeHtml href={url} elem="a" content={logo}/></div>
				: <UnsafeHtml className="w-navline-logo" content={logo}/>)
			: (
				<div className="w-navline-logo">{url ? <a href={url}>{logo}</a> : logo }</div>
			)
	);


	/**
	 * Vykreslí komponentu
	 */
	render() 
	{
		return (
			<div className={classNames('w-navline', this.props.role && ('w-navline-role-' + this.props.role))}>
				<div className="w-navline-holder">

					<Navline.Logo url={this.props.isPartner ? null : this.props.homepageUrl} logo={this.props.logo}/>

					{
						!this.props.isPartner
							? <>
								<div className="w-navline-tabs">
									{this.props.children}
								</div>

								<div className="w-navline-menu">
									<ul>

										{this.props.showHelp &&
									<li className="w-navline-menu-item-help">
										<a href={this.props.supportUrl} target="_blank" rel="noreferrer">
											<span>{i18n.t('text[portal-2015][claim][nav][help]', 'Do you need any help?')}</span>
										</a>
									</li>
										}

										{this.props.showCreateWebsite &&
									<li className="w-navline-menu-item-create">
										<ActionLink onClick={() => this.props.onCreateWebsiteClick()}
											className="signup-submit"
											data-ref="navline-sign-up-button"
										>
											{i18n.t('text[portal-2015][claim][side][createWebsite]', 'Create a Website')}
										</ActionLink>
									</li>
										}

										{this.props.showUserLogin && !this.props.userIsLoggedIn &&
									<li className="w-navline-menu-item-login">
										<ActionLink onClick={() => this.props.onLoginClick()} className="login-submit">
											<span>{i18n.t('text[system][pages][sign-in][name]', 'Login')}</span>
										</ActionLink>
									</li>
										}
										{this.props.showUserLogin &&
									<li className="w-navline-menu-item-user">
										<ActionLink onClick={this.handleToggleUserMenu} className="w-user-menu-submit">
											<span>{this.props.userIsLoggedIn ? this.props.userName : ''}</span>
										</ActionLink>
									</li>
										}

									</ul>


									<UserMenu
										onNotificationsMenuTriggerClick={this.handleToggleNotificationsMenu}
										onLoginClick={this.props.onLoginClick}
										onClose={this.closeMenu}
										tabs={this.props.children}
										project={this.props.project}
										showUserLogin={this.props.showUserLogin}
										userIsLoggedIn={this.props.userIsLoggedIn}
										userName={this.props.userName}
										userEmail={this.props.userEmail}
										myProjectsUrl={this.props.myProjectsUrl}
										logoutUrl={this.props.logoutUrl}
										items={this.props.userMenuItems}
									/>

								</div>
							</>
							: <div className="w-navline-tabs">{this.props.children}</div>
					}
				</div>

			</div>
		);
	}
}

Navline.propTypes = {
	role: PropTypes.string,
	isPartner: PropTypes.bool,
	homepageUrl: PropTypes.string,
	logo: PropTypes.any,
	children: PropTypes.any,
	showHelp: PropTypes.bool,
	supportUrl: PropTypes.string,
	showCreateWebsite: PropTypes.bool,
	onCreateWebsiteClick: PropTypes.func,
	showUserLogin: PropTypes.bool,
	userIsLoggedIn: PropTypes.bool,
	onLoginClick: PropTypes.func,
	userName: PropTypes.string,
	userEmail: PropTypes.string,
	project: PropTypes.any,
	myProjectsUrl: PropTypes.string,
	logoutUrl: PropTypes.string,
	userMenuItems: PropTypes.array,
};
