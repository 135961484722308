import {
	CLOSE_PAGE_SUBPAGE, SET_ADD_PROJECT_CAPTCHA_VALUE,
	SET_ADD_PROJECT_ERROR,
	SET_ADD_PROJECT_VALUE,
	SET_CURRENT_PAGES,
	// SET_MY_PROJECTS_CLICK_ACTION,
	TRACK_ADD_PROJECT_INPUT,
	OPEN_SEARCH_PAGE,
	CLOSE_SEARCH_PAGE, SET_INIT_PAGE
} from "./actions";
import React from "react";
import i18n from "i18next";
import { extractProjectNameFromStaticData, getStaticData } from "../../utils/utils";
import modal, * as fromModal from '../../components/Modal/reducer';


const initialState = {
	projectId: null,
	projectName: null,
	myProjectsAction: CLOSE_PAGE_SUBPAGE,
	pages: [],

	userName: null,
	userIsLoggedIn:  true, // první ředpoklad bude true, to bvvyžaduje mene paramtetrl
	userIsMailReader: null,

	menuData: {
		myProjectsUrl: null,
		logoutUrl: null,
		userName: null,
		userEmail: null,
		project: null,
		userMenu: null,
	},
	
	// // TODO presunout do MY-PRojects nebo do clastního modalu
	addProjectModal: {
		error: null,
		value: '',
		trackingData: [],
		jsAntiSpamData: null,
	},

	logo: null,

	termsAndConsUrl:  null,
	privacyUrl:  null,
	maxProjectNameLength:  null,
	forgotPasswordUrl:  null,
	socialUrl:  null,
	loginUrl:  null,
	homepageUrl: null,
	submitTicketUrl: null,
	myProjectsUrl: null,
	mailboxUrl: null,
	featuresUrl:  null,
	templatesUrl:  null,
	pricelistUrl:  null,
	supportUrl:  null,
	blogUrl:  null,
	registerUrl:  null,

	jsAntiSpamData: null,
	captchaPublicKey: null,

	footer: null,
	captcha: null, // {captchaPublicKey: string, captchaLanguage: string}
	modal: fromModal.initialState,

	isPartner: true, // defaultne se chovame jako by to byl partnner (partnerovi chybí nějteré parametry)
	partnerName: 'Webnode'
};

export default function (state = initialState, action)
{
	switch (action.type)
	{
		case SET_INIT_PAGE:
			return {
				...state,
				logo: action.data['logo'],
				userName: action.data['userName'],
				userIsLoggedIn:  action.data['userIsLoggedIn'],
				userIsMailReader: action.data['userIsMailReader'],
				termsAndConsUrl:  action.data['termsAndConsUrl'],
				privacyUrl:  action.data['privacyUrl'],
				maxProjectNameLength:  action.data['maxProjectNameLength'],
				forgotPasswordUrl:  action.data['forgotPasswordUrl'],
				socialUrl:  action.data['socialUrl'],
				loginUrl:  action.data['loginUrl'],
				homepageUrl: action.data['homepageUrl'],
				submitTicketUrl: action.data['submitTicketUrl'],
				myProjectsUrl: action.data['myProjectsUrl'],
				mailboxUrl: action.data['mailboxUrl'],
				featuresUrl:  action.data['featuresUrl'],
				templatesUrl:  action.data['templatesUrl'],
				pricelistUrl:  action.data['pricelistUrl'],
				supportUrl:  action.data['supportUrl'],
				blogUrl:  action.data['blogUrl'],
				registerUrl:  action.data['registerUrl'],
				upgradeNotificationUrl:  action.data['upgradeNotificationUrl'],

				jsAntiSpamData: action.data['jsAntiSpamData'],
				captchaPublicKey: action.data['captchaPublicKey'],

				footer: action.data['footer'],
				isPartner:  action.data['isPartner'],
				partnerName:  action.data['partnerName'],

				menuData: {
					...state.menuData,
					myProjectsUrl: action.data['myProjectsUrl'],
					logoutUrl: action.data['logoutUrl'],
					userName: action.data['userName'],
					userEmail: action.data['userEmail'],
					userMenu: action.data['userMenu'],
				},
				addProjectModal: {
					...state.addProjectModal,
					jsAntiSpamData: action.data['jsAntiSpamData'],
				}
			};
		case SET_CURRENT_PAGES:

			return {
				...state,
				pages: action.pages,
				projectId: action.projectId,
				projectName: action.projectId ? extractProjectNameFromStaticData(getStaticData(), action.projectId) : null,
				menuData: {
					...state.menuData,
					project: action.projectId
						? {
							url: `${getStaticData()['myProjectsUrl']}${action.projectId}/`,
							text: extractProjectNameFromStaticData(getStaticData(), action.projectId)
						}
						: null
				}
			};

		case OPEN_SEARCH_PAGE:
			return {
				...state,
				pages: [{text: i18n.t('text[blocks][catalogue][list][search_label]', 'Search projects')}],
			};

		case CLOSE_SEARCH_PAGE:
			return {
				...state,
				pages: [],
			};

		case SET_ADD_PROJECT_ERROR:
			return {
				...state,
				addProjectModal: {
					...state.addProjectModal,
					error: action.error,
					jsAntiSpamData: action.jsAntiSpamData || state.addProjectModal.jsAntiSpamData,
				},
				captcha: action.captcha,
			};
		case SET_ADD_PROJECT_VALUE:
			return {
				...state,
				addProjectModal: {
					...state.addProjectModal,
					value: action.fullname
				}
			};
		case SET_ADD_PROJECT_CAPTCHA_VALUE:
			return {
				...state,
				addProjectModal: {
					...state.addProjectModal,
					captcha: action.captcha
				}
			};
		case TRACK_ADD_PROJECT_INPUT:
			return {
				...state,
				addProjectModal: {
					...state.addProjectModal,
					trackingData: [
						...state.addProjectModal.trackingData,
						action.trackingEvent
					]
				}
			}


		default:
			return {
				...state,
				modal: modal(state.modal, action),
			};
	}
}
