export const filled = (value) => !!value;
export const email = (value) => /^([\w\-+"]|[\w\-+](?:\.(?!\.)))*[\w\-+]@((?!\-)[a-zA-Z0-9\-]+\.)+([a-zA-Z]{2,4})+$/.test(value + ''); // sjednoceno s EmailAddress::VALIDATION_REGEXP
export const ip = (value) => /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|(\*(?!.*\d)))(\.|$)){4}$/.test(value + '');
export const minlength = (minlength) => (value) => (value + '').length >= minlength;
export const maxlength = (maxlength) => (value) => (value + '').length <= maxlength;
// "phone" může začít pluskem, předvolba může být v závorkách a ůže obsahovat tečky, pomlčky a bílé znaky, musí končit číslem
// minimální délku neřešíme šalamounovy ostrovy můžou mít klidně 5 znaků, kdo ví jestli nemá někdo ještě míň
export const phone = (value) => /^\+?\s*(?:\([0-9]{3}\))?[\s\-.0-9]{0,14}[0-9]/.test(value + '');
export const minMaxNumber = (value, min, max, onlyInt = true) =>
{
    let intValue = value * 1;

    if (isNaN(intValue))
    {
       return false;
    }

    if (onlyInt && intValue % 1 !== 0)
    {
        return false;
    }

    if (intValue >= min && intValue <= max)
    {
        return true;
    }
    return false;
}

// export const createValidator = (validator, errorMessage) => (value) => validator(value) ? null : errorMessage;

export const notEmpty = (value) => value && !!value.length;
