/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 6. 11. 2020 11:25
 */
import {get} from './cookies';
import Keen from "./Tracker";

export const loginModalEvent = (clickSource) => modalEvent('login_modal', clickSource);
export const registrationModalEvent = (clickSource) => modalEvent('project_registration_modal', clickSource);


function modalEvent(event, clickSource)
{
	Keen.addKeenEvent(event, {'data': {
			'userHash': get('tu'),
			'firstDay': get('mfd') === '1',
			'hpVersion': window.wnd.$dataStatic['hpVersion'],
			'clickSource': clickSource
	}});
}

