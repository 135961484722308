export const MODAL_OPEN = 'Modal/MODAL_OPEN';
export const MODAL_CLOSE = 'Modal/MODAL_CLOSE';
export const MODAL_CHANGE_SECTION = 'Modal/MODAL_CHANGE_SECTION';
export const MODAL_SET_LOADING = 'Modal/MODAL_SET_LOADING';

/**
 * Otevře modalni okno na zadane sekci
 * @param {string} section
 * @param {object|undefined} props -  Deprecated
 */
export const open = (section, props= undefined) =>
{
	return {
		type: MODAL_OPEN,
		section,
		props,
	};
};

/**
 * Přepne modalni okno na zadanou sekci
 * @param {string} section
 * @param {object|undefined} props -  Deprecated
 */
export const changeSection = (section, props= undefined) =>
{
	return {
		type: MODAL_CHANGE_SECTION,
		section,
		props
	}
};

/**
 * Zavře modalni okno
 */
export const close = () =>
{
	return {
		type: MODAL_CLOSE
	}
};

/**
 * Přepne modal do modu loading
 */
export const setLoading = (loading = true) =>
{
	return {
		type: MODAL_SET_LOADING,
		loading
	}
};



