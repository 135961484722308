import * as types from './actions';

export const initialState = {
	section: null,
	loading: false,
	queue: [],
};

export default function (state = initialState, action)
{
	switch (action.type) {

		case types.MODAL_OPEN:
			// pokud je uz neco otevrene zaradime action do fronty
			if (action.props?.useQueue === true && state.section !== null)
			{
				return {
					...state,
					queue: [action, ...state.queue],
				};
			}

			return  {
				...state,
				section: action.section,
				...action.props
			};

		case types.MODAL_CHANGE_SECTION:
			return  {
				...state,
				section: action.section,
				loading: false, // vypne loading
				...action.props
			};

		case types.MODAL_CLOSE:
			// pokud je neco ve fronte zpracujeme to
			if (state.queue.length > 0)
			{
				const queue = [...state.queue];
				const queuedAction = queue.pop();
				if (queuedAction !== undefined)
				{
					return {
						...state,
						section: queuedAction.section,
						queue,
						...queuedAction.props,
					};
				}
			}
			return initialState;

		case types.MODAL_SET_LOADING:
			return {
				...state,
				loading: action.loading
			};

		default:
			return state
	}
}

