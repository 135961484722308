import React from 'react';
import PropTypes from "prop-types";
import RecaptchaApi from "./RecaptchaApi";

export default class ReCaptchaV2 extends React.Component
{
    wrapper = React.createRef();
    componentDidMount()
    {
        const widget = document.createElement("div");
        try
        {
            RecaptchaApi.renderWhenReady(widget, this.handleChange)
        }
        catch(e)
        {
            // if(process && process?.env?.JEST_WORKER_ID !== undefined)
            // {
            //     console.error('Captcha could not be rendered', e, process?.env?.JEST_WORKER_ID);
            // }
        }
        this.wrapper.current.appendChild(widget);
    }

    handleChange = (token) => this.props.onChange(token);


    render()
    {
        return <div ref={this.wrapper}/>;
    }

}

ReCaptchaV2.propTypes = {
    onChange: PropTypes.func.isRequired,
}