export function set(cname, cvalue, exdays = -1, path = '/')
{
	let expires = -1;
	if (exdays > 0)
	{
		const d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		expires = d.toUTCString();
	}
	window.document.cookie = `${cname}=${cvalue};expires=${expires};path=${path}`;
}

export function get(cname)
{
	const name = cname + "=";
	const decodedCookie = decodeURIComponent(window.document.cookie);
	const ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}