/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 13. 10. 2020 8:39
 */

import {productionLog} from "./utils";

/**
 * Třída provádějící request s přesměrováním, stejně jako submit formuláře
 */
export class FormRequest {

	/**
	 *
	 * @param {?string} action
	 * @param {object} data
	 * @param attributes
	 */
	constructor(action = undefined, data= {}, attributes = {})
	{
		this.action = action;
		this.data = data;
		this.atts = attributes;

		productionLog('Request: prepared');
	}


	addData(name, value)
	{
		this.data[name] = value;
	}

	static inputsToData(inputs)
	{
		productionLog('Request: prepare-input-start');
		const result = Object.keys(inputs).reduce((acc, curr) => {
			acc[curr]=inputs[curr].value;
			return acc;
		}, {});

		productionLog('Request: prepare-input-done');
		return result;
	}

	send(post = true) {
		productionLog('Request: send-init');
		const form = document.createElement('form');
		// museji byt set attribut kvůli IE
		form.setAttribute('style', 'display: none;')
		form.setAttribute('method', post?'post':'get');
		for (let attrKey of Object.keys(this.atts))
		{
			form.setAttribute(attrKey, this.atts[attrKey]);
		}

		if (this.action)
		{
			form.action = this.action;
		}

		for (let name of Object.keys(this.data)) {
			if (this.data[name] !== undefined)
			{
				let input = document.createElement('input');
				input.type = 'hidden';
				input.name = name;
				input.value = this.data[name];
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		productionLog('Request: request-form-added');
		form.submit();
		productionLog('Request: request-form-sent');
	}
}