

let isMobile = undefined;
export const deviceIsMobile = () => {
    if (isMobile === undefined)
    {
        isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
    return isMobile;
};


/**
 * Přiznak vykonávání JS a detekce typu zařízení
 * View fix pro IE10 na Windows 8 a Windows Phone 8
 */
export function deviceDetection()
{
    // Příznak vykonávání JS
    // toto resi modernizer
    // $('html').removeClass('no-js').addClass('js');

    // Detekce typu zařízení
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.document.body.classList.add('touched'); // body
    }
    else {
        document.documentElement.classList.add('pc'); // html
        window.document.body.classList.add('desktop'); // body
    }

    // IE 10 in Windows 8 and Windows phone 8 view fix
    // http://getbootstrap.com/getting-started/#support-ie10-width
    if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
        var msViewportStyle = document.createElement('style');
        msViewportStyle.appendChild(
            document.createTextNode(
                '@-ms-viewport{width:auto!important}'
            )
        );
        document.querySelector('head').appendChild(msViewportStyle);
    }

    // JMA: zatím tu třídu používám jen pro tiskové styly ve firefoxu pro order detail,
    // koncepčně to ale chci mít obecně pro portál jako takový, tj. ne jen pro orders.
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
    {
        document.getElementsByTagName("html")[0].classList.add('firefox');
    }
}

export function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        return {
            status: true,
            version: parseInt(v[1], 10) ,
            info: parseInt(v[1], 10)+'.'+parseInt(v[2], 10)+'.'+parseInt(v[3] || 0, 10)
        };
    }
    return {status:false, version: false, info:''}
}
