import React from 'react';
import PropTypes from 'prop-types';
import Trans from "../Trans";
import exact from 'prop-types-exact';
import ShoutBoard from '../ShoutBoard.jsx';



export default function UpgradeNotificationShoutBoard({kbLink})
{
	return <ShoutBoard show={true} scheme={ShoutBoard.SCHEME_3} className="type-ico ico-warning">
		<p>
			<Trans
				i18nKey={'text[dashboard][notification][upgrade][messageBar]'}
				defaults={'Webnode is currently upgrading its infrastructure. We appreciate your patience during this process. <link1><span>Learn more</span></link1>.'}
				components={{
					link1: <a href={kbLink} target={'_blank'}/>,
					span: <span/>,
				}}
			/>
		</p>
	</ShoutBoard>;
}

UpgradeNotificationShoutBoard.propTypes = exact({
	kbLink: PropTypes.string,
});
