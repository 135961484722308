import React from 'react';
import i18n from 'i18next';
import Layout from "./Layout";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "./actions";
import PropTypes from "prop-types";
import UpgradeNotificationMessageBar from '../../components/Ui/SystemNotifications/UpgradeNotificationMessageBar.jsx';

/**
 * Layout pro přihlášené stránky Dashboardu
 */
class DashboardLayout extends React.Component
{

	static updateSections(action, suffixes)
	{
		if (typeof suffixes === 'string')
		{
			suffixes = suffixes.split(' ');
		}

		action('dashboard');
		action('dashboard-section');
		for (let suffix of suffixes)
		{
			action(`dashboard-section-${suffix}`);
		}
	}

	componentDidMount()
	{
		if (this.props.section)
		{
			DashboardLayout.updateSections((x) => window.document.body.classList.add(x), this.props.section);
		}
	}

	componentWillUnmount()
	{
		if (this.props.section)
		{
			DashboardLayout.updateSections((x) => window.document.body.classList.remove(x), this.props.section);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
		// přetypování na string vyřeší problémy s polem
		if ((''+this.props.section) !== (''+prevProps.section))
		{
			if (prevProps.section)
			{
				DashboardLayout.updateSections(x => window.document.body.classList.remove(x), prevProps.section);
			}
			if (this.props.section)
			{
				DashboardLayout.updateSections(x => window.document.body.classList.add(x), this.props.section);
			}
		}
	}

	/**
	 * Vykresli vývchozí taby
	 */
	renderDefaultTabs = () => (
		<ul>
			<li className="w-navline-projects-overview">
				<a href={this.props.myProjectsUrl}>{i18n.t('text[portal-2015][claim][nav][myProjects]', 'My projects')}</a>
			</li>
			{this.props.userIsMailReader &&
			<li>
				<a href={this.props.mailboxUrl} target="_blank">{i18n.t('text[portal-2015][claim][side][email]', 'E-mail account')}</a>
			</li>
			}
		</ul>
	);

	/**
	 * Vykreslí komponentu s layoutem
	 *
	 * @returns {JSX.Element}
	 */
	render()
	{
		const {children, tabs} = this.props;
		let top = this.props.top;
		if (this.props.upgradeNotificationUrl)
		{
			top = <UpgradeNotificationMessageBar kbLink={this.props.upgradeNotificationUrl}/>
		}

		return (
			<Layout
				top={top}
				tabs={tabs ?? this.renderDefaultTabs()}
				showCreateWebsite={false}
			>
				{children}
			</Layout>
		)
	}
}

DashboardLayout.propTypes = {
	...Layout.propTypes,
	upgradeNotificationUrl: PropTypes.string,
	section: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	children: PropTypes.node,
};


export default connect(
	(state, ownProps) => ({...state.layout, ...ownProps}),
	(dispatch) => bindActionCreators(actions, dispatch)
)(DashboardLayout)
