import api from "../../utils/api";
// import {CLOSE_PAGE_DETAIL, CLOSE_PAGE_SEARCH, setMyProjectsClickAction} from "../Layout/actions";
import * as modal from '../../components/Modal/actions';
import Keen from '../../utils/Tracker';
export {/*setMyProjectsClickAction, */setCurrentPages, openAddProjectModal, openRegisterModal} from "../Layout/actions";


export {modal};

/*
 * action types
 */
export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_COMPLETE = 'FETCH_PROJECTS_COMPLETE';
export const FETCH_PROJECTS_ERROR = 'FETCH_PROJECTS_ERROR';
export const SEARCH_PROJECTS = 'SEARCH_PROJECTS';
export const OPEN_PROJECT = 'OPEN_PROJECT';
export const CLOSE_PROJECT = 'CLOSE_PROJECT';
export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_COMPLETE = 'DELETE_PROJECT_COMPLETE';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';
export const OPEN_SEARCH_PAGE = 'OPEN_SEARCH_PAGE';
export const CLOSE_SEARCH_PAGE = 'CLOSE_SEARCH_PAGE';

/*
 * action creators
 */

/**
 * Vyhleda v projekrech
 */
export function search(phraze)
{
	return {
		type: SEARCH_PROJECTS,
		phraze: phraze
	};
}

/**
 * Otevře detail projektu, ktery neni prednacteny
 */
export const fetchItem = (name) => (dispatch) => {

	dispatch({
		type: FETCH_PROJECTS_START,
		page: 0,
		perPage: 1,
		hideThumbnails: true,
	});

	return api.projects.fetchAll({'projects.name': name}).then((data) => {
		dispatch(
			fetchProjectsComplete({...data, totalItems: null})  // totalItems nevime, protoze sme si vytahli jen jeden projekt
		);
		return data;
	}).catch((error) => dispatch({
		type: FETCH_PROJECTS_ERROR,
		error: error
	}));

};



/**
 * Nacte řádky do tabulky
 */
export const fetchItems = (search, page, perPage = 9) => (dispatch) =>
{
		dispatch({
			type: FETCH_PROJECTS_START,
			perPage,
			page,
			search
		});

		const filter = search ? {'projects.searchPhrase': search} : {};
		return api.projects.fetchAll(
			filter,
			{
				from: page * perPage,
				count: perPage
			},
			[]
		).then(
			(data) => dispatch(fetchProjectsComplete(data))
		).catch(
			(error) => dispatch({
				type: FETCH_PROJECTS_ERROR,
				error: error
			})
		);
};

/**
 * Akce pro dokonceni nacitani dat
 * @param data
 * @return {Function}
 */
const fetchProjectsComplete = (data) => (dispatch) => {

	const {items , metadata, totalItems} = data;
	const order = data.order || [];

	dispatch({
		type: FETCH_PROJECTS_COMPLETE,
		totalItems: totalItems,
		items: order.map(function (key) {
			const item = items[key];

			// url screenshotu (item.avatar doplnuje DbProjectsReader) nebo fallback
			const screenshotUrl = item.avatar === null? metadata.templateFallbackUrl : item.avatar;

			return {
				...item,
				screenshot: {
					url: screenshotUrl,
					fallback: screenshotUrl === metadata.templateFallbackUrl,
				},
			};
		}),
		emptyProjectsImage: metadata.emptyProjectsImage,
	});
};

/**
 * Otevře projekt
 *
 * @param project
 */
export const openProject = (project) =>
{
	return {
		type: OPEN_PROJECT,
		project: project
	};
};


/**
 * Zavre projekt
 */
export const closeProject = () =>
{
	return {
		type: CLOSE_PROJECT,
	};
};


/**
 * Otevře dialog pro potvrzení smazání, nebo zpravu o tom ze to nelze
 */
export const openDeleteProjectConfirm = (allow = true) => modal.open(allow ? 'confirm' : 'cannot');


/**
 * Otevře dialog pro zadání hesla vybráno důvodu
 */
export const openDeleteProjectReason  = () => modal.open('reason');


/**
 * Otevře dialog Capterra review APP-2505
 */
export const openReviewModal = () =>
{
	return modal.open('review', {
		isOverlayClickClosing: false,
		useQueue: true,
	});
};


/**
 * Zavře modal
 */
export const closeModal = () => modal.close();


/**
 * Otevře dialog pro zadání hesla vybráno důvodu
 */
export const deleteProject  = (project, data) => (dispatch) =>
{
	dispatch({
		type: DELETE_PROJECT_START,
	});

	return api.projects.delete(
		project,
		data
	).then(() => {

		dispatch({
			type: DELETE_PROJECT_COMPLETE,
			project: project,
			announcement: true,
		});

		// po uspesnem smazani zavreme modal i detail projektu
		dispatch(modal.close());
		dispatch(closeProject());

		// za 5 vteřin zrušíme sticky announcment
		setTimeout(() => {
			dispatch({
				type: DELETE_PROJECT_COMPLETE,
				announcement: false,
			})
		}, 5000);

	}).catch((err) => {

		dispatch({
			type: DELETE_PROJECT_ERROR,
		});

		throw err;
	});
};

/**
 * Otevre dialog s predstavenim benefitu balicku business bez info o tom, ze on jen vysel
 */
export const openBusinessBenefitsPromotion  = () =>
{
	Keen.addKeenEvent('business_benefits_promotion_show');
	return modal.open('businessBenefitsPromo', { useQueue: true });
};

/**
 * Otevre dialog pro predstaveni funkce na fulltext site search
 *
 * @returns {{section: string, type: string, props: Object|undefined}}
 */
export const openFulltextSiteSearchPromotion  = () =>
{
	Keen.addKeenEvent('fulltext_site_search_promotion_show');
	return modal.open('fulltextSearchPromo', { useQueue: true });
};

export function openSearchPage()
{
	return (dispatch) => {
		dispatch({type: OPEN_SEARCH_PAGE});
		// dispatch(setMyProjectsClickAction(CLOSE_PAGE_SEARCH));
	};
}

export function closeSearchPage()
{
	return (dispatch) => {
		dispatch({type: CLOSE_SEARCH_PAGE});
		// dispatch(setMyProjectsClickAction(CLOSE_PAGE_DETAIL));
	};
}
