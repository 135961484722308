import React from 'react';
import i18n from "i18next";
import {getEventTrackingValue, inputTrackingEvents} from "../../utils/tracking";
import TextField from "../../components/Ui/FormFields/TextField";
import PropTypes from 'prop-types'
import * as Validator from "../../utils/validators";
import {MAX_PASSWORD_LENGTH} from "./actions";


export const EmailField = ({onChange, data, onTrack, classic = true, setError = undefined, id = undefined, defaultAtChar= false}) =>{
	const ref = React.useRef(null);

	return <TextField
		forwardedRef={ref}
		onChange={(e, name, value) => {
			onChange(name,value);
			onTrack(getEventTrackingValue(name, e.nativeEvent.type))
		}}
		name="email"
		type="email"
		classic={classic}
		label={i18n.t('text[webnode_com][__global_labels-email__]', 'Email')}
		{...data}
		autoCorrect="off"
		tabIndex="0"
		id={id}
		maxLength="255"
		{...inputTrackingEvents('email', onTrack, {
			// onFocus: defaultAtChar
			// 		 ? () =>
			// 		 {
			// 		 	if (data.value === '')
			// 			{
			// 				onChange('email', '@');
			// 			}
			// 		 }
			// 		 : () => true,
			onBlur: () => setError && setError('email', Validator.email(data.value) ? null : i18n.t(
				'text[webnode][__global_signup-error-email__]',
				'Fill in valid email address'
			))
		})}
	/>
}
EmailField.propTypes = {
	onChange: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	onTrack: PropTypes.func.isRequired,
};

export const PasswordField = ({onChange, data, onTrack, label, classic = true, setError = undefined, id = undefined}) => <TextField
	onChange={(e, name, value) => {
		onChange(name,value);
		onTrack(getEventTrackingValue(name, e.nativeEvent.type))
	}}
	classic={classic}
	id={id}
	name="password"
	type="password"
	label={label}
	value={data.value}
	{...data}
	autoCorrect="off"
	tabIndex="0"
	{...inputTrackingEvents('password', onTrack, {
		onBlur: () => {
			if (setError)
			{
				if (!Validator.minlength(6)(data.value))
				{
					return setError('password', i18n.t(
						'text[webnode][__global_signup-error-user-pwd-short__]',
						'Password must contain atleast __PASSWORD_MIN_LENGTH__ caharacters.'
					).replace('__PASSWORD_MIN_LENGTH__', '6'));
				}

				if (!Validator.maxlength(MAX_PASSWORD_LENGTH)(data.value))
				{
					return setError('password', i18n.t(
						'text[webnode][passwordToLong]',
						'Maximum password length is {length}.',
						{length: MAX_PASSWORD_LENGTH}
					));
				}
			}
		}
	})}
/>
PasswordField.propTypes = {
	onChange: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	onTrack: PropTypes.func.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export const FullNameField = (
	{
		onChange,
		data,
		onTrack,
		label,
		className,
		projectMaxLength,
		classic= true,
		placeholder = undefined,
		hint = undefined,
		setError = undefined,
		id = undefined
	}
) => <TextField
	onChange={(e, name, value) =>
	{
		onChange(name, value);
		onTrack(getEventTrackingValue(name, e.nativeEvent.type))
	}}
	className={className}
	name="fullname"
	type="text"
	classic={classic}
	id={id}
	maxLength={projectMaxLength}
	label={label}
	placeholder={placeholder}
	{...data}
	autoCorrect="off"
	tabIndex="0"
	hint={hint}
	hideHintClass={true}
	{...inputTrackingEvents('fullname', onTrack, {
		onBlur: () => setError && setError('fullname', Validator.filled(data.value) ? null : i18n.t(
			'text[webnode][__global_signup-error-user-4__]',
			'You can use your name, the name of your company or association.'
		))
	})}
/>
FullNameField.propTypes = {
	onChange: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	onTrack: PropTypes.func.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	className: PropTypes.string,
	projectMaxLength: PropTypes.number,
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};
