import { getStaticData } from './utils';

const getBaseUrl = () => getStaticData()['baseUrl'] ?? '/';

const currentURLParams = (new URLSearchParams(window.location.search));
const authToken = currentURLParams.has('token') ? currentURLParams.get('token') : null
const getUrlWithToken = () => {
	let url = new URL(getBaseUrl());
	if (authToken)
	{
		url.searchParams.append('token', authToken)
	}
	return url.toString();
}


/**
 * Vrátí request v transakci
 *
 */
const toTransaction = (request) => {
	const id = (new Date()).getTime() + '_' + Math.round(Math.random() * 1000000);
	return JSON.stringify({
		"id": 'transaction_' + id,
		"type": 'universal',
		"headers": {
			"langBe": wnd.$dataStatic['backendLanguage'],
			"langFe": wnd.$dataStatic['frontendLanguage'],
			"authToken": authToken
		},
		"requests": [{id: 'request_' + id, ...request}],
	});
};


/**
 * Vrátí response z transakce
 *
 * @param {Object} transaction
 * @return {Object}
 */
const fromTransaction = (transaction) => {
    const resp = transaction.requests ? transaction.requests[0] : {};
	if (resp.result !== true) {
		let error = new Error(resp.error ? resp.error.text : 'Ajax request failed!')
		error.code = (resp.error && resp.error.code) ? resp.error.code : 0;
		throw error;
	}
	return resp.data;
};

/**
 * Tato funkce převede settings do normoveného jsonu,
 * implementace musí odpovídat \wnd\base\DataSettings::jsonSerialize
 *
 * @param {object} settings
 * @return {string}
 */
const getSettingsKey = (settings) => {

    if (!settings || Object.keys(settings).length === 0)
    {
        return 'no_settings';
    }

    var result = {};

    if (settings['id']) {
        result['id'] = settings['id'];
    }

    if (settings['projectName']) {
        result['projectName'] = settings['projectName'];
    }

    if (settings['filter'] && settings['filter'].length > 0) {
        result['filter'] = settings['filter'].filter(function (filter) {
            return typeof (filter) === 'object' && Object.keys(filter).length > 0;
        });
    }

    if (settings['limit'] && Object.keys(settings['limit']).length > 0) {
        result['limit'] = settings['limit'];
    }

    if (Object.keys(result).length === 0) {
        return 'no_settings';
    }

    return JSON.stringify(result);
};


/**
 * Zkusi nejdrive nacist data z wnd.$data a pokud neexistuji tak ajax requestem
 *
 * @param dataType
 * @param settings
 * @return {Promise<Object>}
 */
let skipLoad = {}; // aby se info zobrazilo jen poprve
const loadUsingPreloadWithSettings = (dataType, settings) => {
    const settingsKey = getSettingsKey(settings);
    const wnd = window.wnd || null;

    // DEBUG
	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
	{
	    if (wnd && wnd.$data && skipLoad[dataType] !== true) {
	        if (!wnd.$data[dataType]) {
	            console.warn('Snazime se prednacist data wnd.$data[' + dataType + '] ktera neexistuji');
	        } else if (!wnd.$data[dataType][settingsKey]) {
	            console.warn('Snazime se prednacist data wnd.$data[' + dataType + '] s nastavenim ktera neexistuje:');
	            console.warn(settingsKey);
	            if (typeof wnd.$data[dataType] === 'object') {
	                console.warn('Existuji jen: ');
	                Object.keys(wnd.$data[dataType]).map((settingsKey) => console.warn(settingsKey));
	            }
	        }
	    }
    }

    if (wnd && wnd.$data && wnd.$data[dataType] && wnd.$data[dataType][settingsKey] && skipLoad[dataType] !== true) {
        skipLoad[dataType] = true;
        return Promise.resolve(wnd.$data[dataType][settingsKey])
    }

    skipLoad[dataType] = true;
    return loadWithSettings(dataType, settings);
};

/**
 * Vymaze prednactenou kolekci z cache
 * @param {string} dataType
 */
const clearPreloadCache = (dataType) => {
    const wnd = window.wnd || null;
    if (wnd.$data && dataType in wnd.$data) {
        wnd.$data[dataType] = {};
    }
};


/**
 * Metoda pro ziskani dat s konkretnimi settings
 *
 * @param {string} dataType
 * @param {Object} settings
 * @returns {Promise<Object>}
 */
const loadWithSettings = (dataType, settings) => sendRequest({
    type: 'DbCollectionRequest',
    data: {
        dataType,
        settings
    }
});

/**
 * Metoda pro ziskani dat s konkretnimi settings
 *
 * @param {Object} request
 * @returns {Promise<Object>}
 */
const sendRequest = (request) => fetch(
	getUrlWithToken(),
	{
		method: 'POST',
		headers: {
			"Content-Type": "application/json",
			"X-Request-Type": "transaction"
		},
		body: toTransaction(request),
		credentials: 'same-origin',
	}
)
	.then(resp => resp.json())
	.then(fromTransaction);


/**
 * Odeslani requestu pomoci xhr s moznosti sledovat prubeh uploadu
 *
 * @param request
 * @param handleUploadProgress handleUploadProgress(percent)
 * @returns {Promise<unknown>}
 */
const sendXhrRequest = (request, handleUploadProgress = null) => {
	return new Promise(function (resolve, reject) {
		let xhr = new XMLHttpRequest();
		xhr.responseType = 'json';
		xhr.onload = () => {
			if (xhr.status >= 200 && xhr.status < 300) {
				resolve(fromTransaction(resp));
			}
			else
			{
				reject({
					status: this.status,
					statusText: xhr.statusText
				});
			}
		}
		xhr.onerror = () => {
			reject({
				status: this.status,
				statusText: xhr.statusText
			});
		}
		if (handleUploadProgress !== null)
		{
			xhr.upload.onprogress = (event) => {
				handleUploadProgress(
					parseInt((event.loaded / event.total) * 100)
				)
			}
		}

		xhr.open('POST',getBaseUrl())
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.setRequestHeader("X-Request-Type", "transaction");
		xhr.send(toTransaction(request))
	});
}

/**
 * Prevede jednoduchy key => value filter na formu pro DataSettings
 */
const convertFilter = (filter) => Object.keys(filter).map((key) => {
    return {
        prefix: '',
        key: key,
        operator: Array.isArray(filter[key]) ? 'IN' : '=',
        value: filter[key],
        suffix: '',
    };
});


/**
 * Metoda pro ziskani dat
 *
 * @param {string} dataType
 * @param {string} projectName
 * @param {object} filter
 * @param {object} limit
 * @param {object} order
 * @param {string|null} id
 * @param {string|null} parentId
 * @return {Promise}
 */
const load = (dataType, projectName, filter = {}, limit = {}, order = [], id = null, parentId = null) => loadUsingPreloadWithSettings(
    dataType,
    {
        projectName,
        id,
        limit,
        order,
        parentId,
        filter: convertFilter(filter),
    }
);

/**
 * Metoda pro ziskani dat
 *
 * @param {string} dataType
 * @param {string} projectName
 * @param {object} filter
 * @param {object} limit
 * @param {object} order
 * @param {string|null} id
 * @param {string|null} parentId
 * @return {Promise}
 */
const loadWithCache = (dataType, projectName, filter = {}, limit = {}, order = [], id = null, parentId = null) => {
    window.wnd.data = window.wnd.data || {};
    window.wnd.data[dataType] = window.wnd.data[dataType] || {};


    let key = getSettingsKey({projectName, filter: convertFilter(filter), limit, order, id});
    if (window.wnd.data[dataType][key])
    {
        return Promise.resolve(window.wnd.data[dataType][key]);
    }
    return load(dataType, projectName, filter, limit, order, id, parentId).then((data) => {
        window.wnd.data[dataType][key] = data;
        return data;
    })
};


const clearCache = (dataType) => {
    window.wnd.data = window.wnd.data || {};
    window.wnd.data[dataType] = {};
}

/**
 * Metoda mofifikaci dat (Create/Insert/Update)
 *
 * @param {string} dataType
 * @param {string} action
 * @param {string} projectName
 * @param {string} id
 * @param {object} data
 * @return {Promise}
 */
const modify = (dataType, action, projectName, id, data = {}) => fetch(
    getUrlWithToken(),
    {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
			'X-Request-Type': 'transaction',
        },
        body: toTransaction({
            type: 'DbDataRequest',
            data: {
                dataType,
                action,
                settings: {
                    projectName,
                    id,
                },
                data
            }
        })
    }
)
    .then(resp => resp.json())
    .then(fromTransaction);


const getJsonRequest = (url) => fetch(url, {
    method: 'GET',
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    credentials: 'same-origin',
}).then(resp => resp.json());


export const sortItems = ({order, items, ...other}) => {
    return {
        items: (order || []).map(key => items[key]),
        ...other
    }
};

/**
 * API
 */
export default {
	projects: {
		fetchAll: (filter, limit) => load('projects', '', filter, limit, []),
		update: (projectName, data) => modify('projects', 'update', projectName, null, data),
		create: (fullname, trackingData, captcha= null, jsAntiSpamData, data = {}) => modify(
			'projects',
			'insert',
			'',
			null,
			{
				fullname,
				trackingData,
				captcha,
				[jsAntiSpamData['id']]: jsAntiSpamData['value'],
				...data
			}
		),
		delete: (projectName, data) => sendRequest({
			type: 'DeleteProjectRequest',
			data: {projectName, ...data}
		}),
		fetchEshopSettings: (projectName) => load('eshopSettings', projectName),
		fetchEshopContacts: (projectName) => load('eshopContacts', projectName),
		downgradePackage: (projectName) => sendRequest({
			type: 'DowngradePackageRequest',
			data: {
				projectName
			}
		}),
		logProjectActivity: (projectName) =>
		{
			sendRequest({
				type: 'ProjectActivityLogRequest',
				data: {
					projectName
				}
			}).then()
		},
	},
	members: {
		fetchAll: (projectName, filter, limit, order) => load('fe_users', projectName, filter, limit, order),
		create: (projectName, data) => modify('fe_users', 'insert', projectName, null, data),
		update: (projectName, id, data) => modify('fe_users', 'update', projectName, id, data),
		updateAllExcept: (projectName, exceptionIds, searchPhrase, data) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'fe_users',
				action: 'update',
				settings: {
					projectName,
					exceptionIds,
					search: searchPhrase,
				},
				data,
			},
		}),
		delete: (projectName, id) => modify('fe_users', 'delete', projectName, id),
		deleteAllExcept: (projectName, exceptionIds, searchPhrase) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'fe_users',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					search: searchPhrase,
				},
			},
		}),
		exportAll: (projectName) => sendRequest({
			type: "ExportDataCollection",
			data: {
				dataType: "fe_users",
				settings: {
					projectName: projectName,
				}
			}
		}),
	},
	formsData: {
		fetchFormList: (projectName) => load('forms_list', projectName),
		fetchById: (projectName, id) =>  load('forms_data', projectName, {'formId': 'all'}, null, null, id),
		fetchAll: (projectName, filter, limit, order) => load('forms_data', projectName, filter, limit, order),
		delete: (projectName, id) => modify('forms_data', 'delete', projectName, id),
		checkMalware: (projectName, fileId) => modify(
			'forms_data', 'update', projectName, fileId, {'action': 'scan', fileId}
		),
		deleteAllExcept: (projectName, exceptionIds, search, formId) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'forms_data',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					search,
					formId,
				},
			},
		}),
		exportAll: (projectName, formId = null) => sendRequest({
			type: "ExportDataCollection",
			data: {
				dataType: "forms_data",
				settings: {
					projectName: projectName,
					filter: convertFilter({formId})
				}
			}
		}),
	},
	eshopCustomers: {
		fetchAll: (projectName, filter, limit) => load('customers', projectName, filter, limit),
		fetchOne: (projectName, id) => load('customers', projectName, {}, {}, [], id),
		exportAll: (projectName) => sendRequest({
			type: "ExportDataCollection",
			data: {
				dataType: "customers",
				settings: {projectName: projectName}
			}
		}),
		deleteAll: (projectName, filter) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'customers',
				action: 'delete',
				settings: {
					projectName,
					filter: convertFilter(filter)
				}
			}
		}),
		deleteAllExcept: (projectName, exceptionIds, searchPhrase) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'customers',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					filter: convertFilter({ search: searchPhrase }),
				}
			}
		}),
	},
	eshopW1Orders: {
		fetchAll: (projectName, filter, limit, order) => load('orders_w1', projectName, filter, limit, order),
		fetchOne: (projectName, orderNumber) => load('orders_w1', projectName, {}, {}, [], orderNumber),
		fetchItems: (projectName, orderNumber, limit = {}) => load('es_products_mn_orders', projectName, {}, limit, [], null, orderNumber),
		update: (projectName, id, data) => modify('orders_w1', 'update', projectName, id, data),
		deleteByFilter: (projectName, filter) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'orders_w1',
				action: 'delete',
				settings: {
					projectName,
					filter: convertFilter(filter)
				}
			}
		}),
		exportAll: (projectName, exportType, filter) => sendRequest({
			type: "ExportDataCollection",
			data: {
				dataType: "orders_w1",
				settings: {
					projectName: projectName,
					exportType: exportType,
					filter: convertFilter(filter)
				}
			}
		}),
	},
	eshopOrders: {
		fetchAll: (projectName, filter, limit, order) => load('e_order', projectName, filter, limit, order),
		fetchItems: (projectName, orderNumber, limit = {}) => load('e_order_item', projectName, {}, limit, [], null, orderNumber),
		fetchOne: (projectName, orderNumber) => load('e_order', projectName, {}, {}, [], orderNumber),
		update: (projectName, id, data) => modify('e_order', 'update', projectName, id, data),
		delete: (projectName, id) => modify('e_order', 'delete', projectName, id),
		updateAll: (projectName, filter, data) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'e_order',
				action: 'update',
				settings: {
					projectName,
					filter: convertFilter(filter)
				},
				data: data
			}
		}),
		updateAllExcept: (projectName, exceptionIds, filter, data) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'e_order',
				action: 'update',
				settings: {
					projectName,
					exceptionIds,
					filter: convertFilter(filter),
				},
				data: data
			}
		}),
		deleteAll: (projectName, filter) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'e_order',
				action: 'delete',
				settings: {
					projectName,
					filter: convertFilter(filter)
				}
			}
		}),
		deleteAllExcept: (projectName, exceptionIds, filter) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'e_order',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					filter: convertFilter(filter),
				}
			}
		}),
		exportAll: (projectName, exportType, filter) => sendRequest({
			type: "ExportDataCollection",
			data: {
				dataType: "e_order",
				settings: {
					projectName: projectName,
					exportType: exportType,
					filter: convertFilter(filter)
				}
			}
		}),
		exportAllExcept: (projectName, exceptionIds, exportType, filter) => sendRequest({
			type: "ExportDataCollection",
			data: {
				dataType: "e_order",
				settings: {
					projectName,
					exceptionIds,
					exportType,
					filter: convertFilter(filter)
				}
			}
		}),
		sendInvoice: (projectName, orderNumber, status) => sendRequest({
			type: 'InvoiceSendRequest',
			data: {projectName, orderNumber, status},
		}),
	},
	orders: {
		fetchOne: (projectName, id) => load('orders', projectName, {}, {}, [], id),
		update: (projectName, id, data, integrityToken) => modify('orders', 'update', projectName, id, {...data, integrityToken}),
	},
	payments: {
		fetchAll: (projectName, orderIdentifier) => load('payment_services', projectName, {}, {}, [], orderIdentifier),
		finish: (projectName, id, currentMethod) => loadWithSettings('payment_services', {
			projectName,
			id,
			lastReloadBeforeSubmit: 1,
			currentMethod
		}),
		secure:  (projectName, orderIdentifier, inputs) => sendRequest({
			type: 'PaymentSecureRequest',
			data: {fields: {...inputs, orderIdentifier, projectName}}
		})
	},
	user: {
		emailVerificationSend: (email = null) => sendRequest({
			type: 'EmailVerificationRequest',
			data: {email}
		}),
		disableReviewModal: () => sendRequest({
			type: 'ReviewModalRequest',
			data: {
				reviewModalDisable: true
			}
		})
	},
	statistics: {
		fetch: (types, url) => getJsonRequest(url) // data už přijdou ve správném formátu
	},
	loginRefresher: {
		appRefresh: () => sendRequest({type: 'LoginRefresh', data: {}}).catch((e) => console.error('login refresh failed', e)),
	},
	servicesAndBilling: {
		fetchAllServices: (limit) => loadWithCache('services', '', {}, limit),
		fetchService: (projectName) => loadWithCache('services', projectName),
		fetchInvoices: (limit, project = null) => loadWithCache('billing', project || '', {}, limit),
		cancelOrder: (project, identifier) => modify('orders', 'update',project, identifier, {cancel: true}),
		updateSubscription: (id, activate) => modify(
			'subscriptions',
			'update',
			'',
			id,
			{action: activate ? 'activate' : 'deactivate'}
		),
		clearServicesCache: () => clearCache('services'),
		fetchMoveService: (projectName, type, domain) => loadWithCache('moveServices', projectName, {type, domain}),
		executeMoveService: (from, to, type, domain = null) => modify('moveServices', 'update', from,null, {to, type, domain}),
		clearMoveServiceCache: () => clearCache('moveServices'),

	},
	shippingMethods: {
		fetchAllLocations: (projectName) => loadWithCache('shippingLocations', projectName),
		eraseLocationsCache: () => {clearCache('shippingLocations'); clearPreloadCache('shippingLocations');},
		addLocation: (projectName, type, locationCode) =>
			modify('shippingLocations', 'insert', projectName, null, {type, locationCode}),
		updateLocation: (projectName, id, type, locationCode) =>
			modify('shippingLocations', 'update', projectName, id, {type, locationCode}),
		fetchUsedLocations: (projectName, limit, locationOutOfOrder) =>
			load('locationShippingMethods', projectName, (locationOutOfOrder !== null ? {locationOutOfOrder} : {}), limit),
		deleteLocation: (projectName, locationId) =>
			modify('shippingLocations', 'delete', projectName, locationId),
		deleteMethod: (projectName, methodId) =>
			modify('e_shipping_method', 'delete', projectName, methodId),
		update: (projectName, id, data) => modify('e_shipping_method', 'update', projectName, id, data),
		create: (projectName, data) => modify('e_shipping_method', 'insert', projectName, null, data),
		updateMethodsOrder: (projectName, locationId, methodsOrder) =>
			modify('e_shipping_method', 'update', projectName, null, {locationId, methodsOrder})
	},
	productDefaultSettings: {
		update: (projectName, weight, width, length, thickness) =>
			modify('productDefaultSettings', 'update', projectName, 1, {weight, width, length, thickness}),
	},
	adminsAndEditors:{
		fetchAllUsers: (projectName,filter,limit) =>
			load('adminsAndEditors', projectName, filter, limit),
		update: (projectName, id, email, role) =>
			modify('adminsAndEditors', 'update',projectName,id,{email, role}),
		createUser: (projectName, email, role) =>
			modify('adminsAndEditors', 'insert',projectName,null,{email, role}),
		delete: (projectName, id) =>
			modify('adminsAndEditors', 'delete',projectName, id,{id}),
		deleteAllExcept: (projectName, exceptionIds, searchPhrase) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'adminsAndEditors',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					searchPhrase,
				},
			},
		}),
	},
	domains: {
		fetchDomainsData: (projectName) => load('domainsManagement', projectName),
		eraseDomainsCache: () => clearCache('domainsManagement'),
		setMasterDomain: (projectName, domainName) => sendRequest({
			type: 'SetMasterDomainRequest',
			data: {projectName, domainName}
		}),
		changeAutorenew: (projectName, domainName, activate) => sendRequest({
			type: 'ChangeAutorenewRequest',
			data: {projectName, domainName, activate}
		}),
		removeTransferredDomain: (projectName, domainName) => sendRequest({
			type: 'RemoveTransferredDomainRequest',
			data: {projectName, domainName}
		}),

    },
    myAccount: {
        fetchUserData: () => load('myAccount', ''),
        saveUserData: (values) => sendRequest({
            type: 'UpdateUserRequest',
            data: {values}
        })
    },
    backupAndRestore: {
        fetchAll: (projectName, filter, limit) =>
            load('backupAndRestore', projectName, filter, limit),
        //maze vybrane radky, ne vsechno! => lepsi by bylo delateAllSelected
        deleteAll: (projectName, backupIds, requestCode) => sendRequest({
            type: 'DbDataRequest',
            data: {
                dataType: 'backupAndRestore',
                action: 'delete',
                settings: {
                    projectName,
                    backupIds: backupIds,
                    requestCode: requestCode,
                },
            }
        }),
        deleteAllExcept: (projectName, exceptionIds, searchPhrase) => sendRequest({
            type: 'DbDataRequest',
            data: {
                dataType: 'backupAndRestore',
                action: 'delete',
                settings: {
                    projectName,
                    exceptionIds,
					searchPhrase,
					requestCode: 200,
                },
            }
        }),
        create: (projectName, backupName, requestCode) =>
            modify('backupAndRestore', 'insert', projectName, null, {backupName, requestCode}),
        checkProgresStatus: (projectName, id, timestampCreated, restoreOrBackup, requestCode) =>
            modify('backupAndRestore', 'update', projectName, id, {timestampCreated, restoreOrBackup, requestCode}),
        restoreBackup: (projectName, id, requestCode, isAuto, userId) =>
            modify('backupAndRestore', 'update', projectName, id, {requestCode, isAuto, userId}),
		toggleAutoBackupPause: (projectName, pause, requestCode) =>
			modify('backupAndRestore', 'update', projectName, '0', {pause, requestCode}),
    },
	emailAccounts: {
		fetchAll: (projectName, filter, limit) => load('emailAccounts', projectName, filter, limit),
		fetchOne: (projectName, id) => load('emailAccounts', projectName, {}, {}, [], id),
        create: (projectName, data) =>
            modify('emailAccounts', 'insert', projectName, null, data),
        update: (projectName, id, data) =>
            modify('emailAccounts', 'update', projectName, id, data),
		deleteAll: (projectName, filter) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'emailAccounts',
				action: 'delete',
				settings: {
					projectName,
					filter: convertFilter(filter)
				}
			}
		}),
		deleteAllExcept: (projectName, exceptionIds, filter) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'emailAccounts',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					filter: convertFilter(filter),
				}
			}
		}),
	},
    eshopTaxRates: {
        fetchAll: (projectName, filter, limit) => load('eshopTaxRates', projectName, filter, limit),
        create: (projectName, data) => modify('eshopTaxRates', 'insert', projectName, null, data),
        update: (projectName, id, data) => modify('eshopTaxRates', 'update', projectName, id, data),
        deleteAll: (projectName, filter) => sendRequest({
            type: 'DbDataRequest',
            data: {
                dataType: 'eshopTaxRates',
                action: 'delete',
                settings: {
                    projectName,
                    filter: convertFilter(filter)
                },
            }
        }),
        deleteAllExcept: (projectName, exceptionIds, searchPhrase) => sendRequest({
            type: 'DbDataRequest',
            data: {
                dataType: 'eshopTaxRates',
                action: 'delete',
                settings: {
                    projectName,
                    exceptionIds,
                    searchPhrase,
                },
            }
        }),
    },
	paymentMethods: {
		fetchAll: (projectName, filter, limit) => load('paymentMethods', projectName, filter, limit, ['id', 'DESC']),
		save: (projectName, data) => modify('paymentMethods', data.id ? 'update' : 'insert', projectName, data.id, data),
		delete: (projectName, ids) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'paymentMethods',
				action: 'delete',
				settings: {
					projectName,
					filter: convertFilter({ids})
				}
			},
		}),
		deleteAllExcept: (projectName, exceptionIds, searchPhrase) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'paymentMethods',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					searchPhrase,
				}
			},
		}),
	},
	domainRegistration: {
		init: (projectName) => load('domains', projectName, {}, {from: 0, count: 2}),
		fetch: (projectName, search, from, count) => load('domains', projectName, {search}, {from, count}),
	},
	paymentSuccess: {
		fetch: (projectName, orderId) => sendRequest({
			type: 'PaymentSuccessVoucherRequest',
			data: {
				project: projectName,
				order: orderId
			}
		})
	},
	orderForm: {
		fetch: (requestType, projectName, filter = {}, limit = {}) => loadWithCache(
			({'products': 'products', 'variants': 'productVariants'})[requestType],
			projectName,
			filter,
			limit
		),
		fetchOrderSummary: (data) =>  sendRequest({type: 'CalculateOrderPriceRequest',data}),
	},
	newOrder: {
		submit:  (projectName, data) => modify('manualOrder', 'insert', projectName, null, data),
	},
	editOrder: {
		submit:  (projectName, orderNumber, data) => modify('manualOrder', 'update', projectName, orderNumber, data),
	},
	testEmails: {
		send: (projectName, data) => modify('testEmails', 'insert', projectName, null, data),
	},
	currencies: {
		fetch: (projectName) =>  load('projectCurrencies', projectName),
		create: (projectName, data) => modify('projectCurrencies', 'insert', projectName, null, data),
		update: (projectName, id, action, values = null) => modify('projectCurrencies', 'update', projectName, id, {values, action}),
		delete: (projectName, id) => modify('projectCurrencies', 'delete', projectName, id),
		loadExchangeRate: (projectName, currency) =>  sendRequest({type: 'LoadExchangeRateRequest', data: {projectName, currency}}),
	},
	pricelist: {
		fetch: (projectName, feature) =>  loadUsingPreloadWithSettings(
			'price_list',
			{
				projectName,
				data: {feature}
			}
		),
	},
	domainsPricelist: {
		fetch: (projectName) =>  loadUsingPreloadWithSettings(
			'domains_price_list',
			{
				projectName,
				data: {}
			}
		),
	},
	siteSecurity: {
		fetchIpAccess: (projectName, filter, limit) => load('ipAccess', projectName, filter, limit),
		fetchAllLocations: (projectName) => loadWithCache('ipLocations', projectName),
		createIpAccessRestriction: (projectName, data) => modify('ipAccess', 'insert', projectName, null, data),
		updateIpAccessRestriction: (projectName, id, data) => modify('ipAccess', 'update', projectName, id, data),
		swapIpAccessRestrictionPositions: (projectName, fromId, toId) => modify('ipAccess', 'update', projectName, null, {fromId, toId}),
		deleteIpAddresses: (projectName, ids) => modify('ipAccess', 'delete', projectName, ids.join(';')),
		activateIpAddresses: (projectName, ids, searchIpPhrase, searchIpAccessType, isAllChecked) => modify('ipAccess', 'update', projectName, null, {activate: ids, searchIpPhrase: searchIpPhrase, searchIpAccessType: searchIpAccessType, isAllChecked: isAllChecked}),
		deactivateIpAddresses: (projectName, ids, searchIpPhrase, searchIpAccessType, isAllChecked) => modify('ipAccess', 'update', projectName, null, {deactivate: ids, searchIpPhrase: searchIpPhrase, searchIpAccessType: searchIpAccessType, isAllChecked: isAllChecked}),
		fetchSpamProtection: (projectName, filter, limit) => load('spamProtection', projectName, filter, limit),
		swapSpamPositions: (projectName, fromId, toId) => modify('spamProtection', 'update', projectName, null, {fromId, toId}),
		deleteSpam: (projectName, ids) => modify('spamProtection', 'delete', projectName, ids.join(';')),
		activateSpamAddresses: (projectName, ids, searchSpamPhrase, isAllChecked) => modify('spamProtection', 'update', projectName, null, {activate: ids, searchSpamPhrase: searchSpamPhrase, isAllChecked: isAllChecked}),
		deactivateSpamAddresses: (projectName, ids, searchSpamPhrase, isAllChecked) => modify('spamProtection', 'update', projectName, null, {deactivate: ids, searchSpamPhrase: searchSpamPhrase, isAllChecked: isAllChecked}),
		createSpamProtection: (projectName, data) => modify('spamProtection', 'insert', projectName, null, data),
		updateSpamProtection: (projectName, id, data) => modify('spamProtection', 'update', projectName, id, data),
		turnOnOffMalwareScan: (projectName, turnOnOff) => modify('malwareScan', 'update', projectName, null, {onOff: turnOnOff}),
		deleteAllIpsExcept: (projectName, exceptionIds, filter) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'ipAccess',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					filter: convertFilter(filter),
				},
			},
		}),
		deleteAllSpamsExcept: (projectName, exceptionIds, filter) => sendRequest({
			type: 'DbDataRequest',
			data: {
				dataType: 'spamProtection',
				action: 'delete',
				settings: {
					projectName,
					exceptionIds,
					filter: convertFilter(filter),
				},
			},
		}),
	},
	submitTicket: {
		submit: (formData) => fetch(
			new URL(globalThis.location.href),
			{method: 'POST', body: formData}
		),

	}
};

