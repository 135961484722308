import React from 'react';
import NonStandardModal from "../../components/Modal/NonStandardModal";
import PropTypes from "prop-types";
import i18n from "i18next";
import {FullNameField} from "../../Modules/Register/inputs";
import ReCaptchaV2 from "../../components/Ui/ReCaptcha/ReCaptchaV2";


export default class AddProjectModalContent extends React.Component {

	static CAPTCHA_FORM_KEY = 'g-recaptcha-response';


	handleSubmit = (e) => {
		e.preventDefault();
		this.props.onSubmit();
	}


	render()
	{
		return (
			<NonStandardModal.Content className="modal-box form-reg" opened={true} loading={this.props.loading}>
				<NonStandardModal.Form
					onSubmit={this.handleSubmit}
					className="form modal-box-content"
					onClose={this.props.onClose}
				>
					<h3>{i18n.t('text[portal-2015][claim][registrationForm][header]', 'Create your website')}</h3>
					<FullNameField
						id="registrantFullName"
						onChange={this.props.onChange}
						data={this.props.inputs.fullname}
						projectMaxLength={this.props.projectMaxLength}
						onTrack={this.props.trackEvent}
						label={i18n.t('text[signup][registrantFullName]', 'Website name')}
						className="form-input"
					/>
					<div className="cleaner boxSpacer"/>
					{
						this.props.captchaChallenge &&
						<ReCaptchaV2
							onChange={response => this.props.onChange(AddProjectModalContent.CAPTCHA_FORM_KEY, response)}
						/>
					}
					<div className="form-submit">
						<input type="submit"
							   value={i18n.t('text[portal-2015][registration][submitLogged]')}
							   name="register"
						/>
					</div>
				</NonStandardModal.Form>
			</NonStandardModal.Content>
		);
	}
}

AddProjectModalContent.propTypes = {
	loading: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	projectMaxLength: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	trackEvent: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,

	inputs: PropTypes.shape({
		fullname: PropTypes.shape({value: PropTypes.string, error: PropTypes.string}).isRequired,
	}).isRequired,


	captchaChallenge: PropTypes.bool,
};
