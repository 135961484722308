import React from 'react';
import i18n from "i18next";
import Tooltip from "../../components/Ui/Tooltip/Tooltip";
import UnsafeHtml from "../../components/Ui/UnsafeHtml";
import PropTypes from 'prop-types';
import Trans from "../../components/Ui/Trans";


export function RegisterHintTooltip(props)
{
	return <Tooltip {...props} element="span">
		{i18n.t('text[portal-2015][registration][projectTooltip]', 'Your website name')}
	</Tooltip>;
}

export function Step2Text()
{
	return (
		<h4>
			{i18n.t('text[portal-2015][registration][step2]', '2. Chode how to register')}
		</h4>
	);
}

export function TermsOfUse({termsAndConsUrl, privacyUrl})
{
	return (
		<>
			<p>
				<UnsafeHtml content={
					i18n.t(
						'text[portal-2015][registration][forcedTerms]',
						'By registration you consent to terms and conditions'
					)
						.replace('%0%', termsAndConsUrl)
						.replace('%1%', privacyUrl)
				} elem="span"/>
				<br/>
				<Trans
					i18nKey="text[portal][recaptchaTerms]"
					defaults="This site is protected by reCAPTCHA and the Google <link1>Privacy Policy</link1> and <link2>Terms of Service</link2> apply."
					components={{
						link1: <a href="https://policies.google.com/privacy" target="_blank"/>,
						link2: <a href="https://policies.google.com/terms" target="_blank"/>,
					}}
				/>
			</p>
		</>
	);
}

export function HiddenInputs({wizardTemplate= null, moduleId = null})
{
	// todo zjistit odkud vzít moduleId
	return <>
		{
			wizardTemplate && <>
				<input type="hidden" name="wizardModule" value="web"/>
				<input type="hidden" name="wizardTodule" value={wizardTemplate}/>
			</>
		}
		{moduleId && <input type="hidden" name="moduleId" value={moduleId}/>}
	</>;
}


TermsOfUse.propTypes = {
	termsAndConsUrl: PropTypes.string.isRequired,
	privacyUrl: PropTypes.string.isRequired,
};

HiddenInputs.propTypes = {
	gclid: PropTypes.string,
};
