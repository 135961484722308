
/**
 * @param {string} fileName
 * @param {Object} options
 * @param {Array} content
 */
export const createBlobAndDownload = (fileName, options, content) => {
	const blob = new Blob(
		content,
		options
	);

	const url = window.URL.createObjectURL(blob);

	// Pro IE je specialni reseni, jak vynutit stazeni Blob jako souboru
	if (navigator['msSaveBlob'])
	{
		navigator['msSaveBlob'](blob, fileName);
	}
	else if (navigator['msSaveOrOpenBlob'])
	{
		navigator['msSaveOrOpenBlob'](blob, fileName);
	}
	else
	{
		let a = document.createElement('a');
		document.body.appendChild(a);
		a.style.display = 'none';
		a.href = url;
		a.download = fileName;
		a.click();

		setTimeout(function() {
			window.URL.revokeObjectURL(url);
		}, 2500);
	}
};


/**
 * Base64 string na pole unicode znaku
 * @param {string} base64
 * @return {Uint8Array}
 */
export const base64ToUint8Array = (base64) => {
	const byteChars = atob(base64);
	let l = byteChars.length;
	let byteNumbers = new Array(l);

	for (let i = 0; i < l; i++)
	{
		byteNumbers[i] = byteChars.charCodeAt(i);
	}

	return new Uint8Array(byteNumbers);
};


/**
 * Z identifikátoru projektu získá jeho shortName
 * (pokud neexistuje, vrací zpět identifikátor)
 * @param {object} staticData
 * @param {string} projectIdentifier
 * @return {string}
 */
export const extractProjectNameFromStaticData = (staticData, projectIdentifier = staticData.attributes?.project) => {
	return extractProjectNameFromMap(staticData?.projectsNameMap || {}, projectIdentifier);
};


export const extractProjectNameFromMap = (projectsNameMap, projectIdentifier ) => {
	return projectsNameMap && projectsNameMap[projectIdentifier] || projectIdentifier;
};


export const getProjectUrl = (staticData, projectIdentifier) => {
	return staticData.myProjectsUrl + (projectIdentifier ? (projectIdentifier + '/') : '');
}

export const isString = (x) => typeof x === 'string';
export const isObject = (o) => typeof o === 'object' && !Array.isArray(o) && o !== null;


/**
 * vrací bool, jestli je zařízení dotykové
 * @returns {boolean}
 */
export const isTouchable = () =>
{
	// výchozí test dotykového zařízení
	let test_touch = (
		('ontouchend' in window) ||
		('undefined' !== typeof window.DocumentTouch && document instanceof window.DocumentTouch)
	);

	// pokud je ve Windows zapnute TabletPC, potom se desktopový Chrome a firefox tvari jako dotykovy a nic nefunguje
	const ua = navigator.userAgent;

	// u chromu a firefoxu pokud není androjid není dotykový
	if (true === test_touch && null == ua.match(/Android/) && (ua.match(/Chrome/) || ua.match(/Firefox/)))
	{
		test_touch = false;
	}

	return test_touch;
};

/**
 * Feature released
 * @param {string} identifier
 * @return {boolean}
 */
export const isReleased = (identifier) => !!(
	window.wnd.$dataStatic.released &&
	window.wnd.$dataStatic.released[identifier]
);


/**
 *
 * @param {string} message
 */
export function productionLog(message)
{
	const url = new URL(window.location.href);
	if (url.searchParams.get('debug') === '1')
	{
		console.log(message);
	}
}

/**
 * @return {Record<string, unknown>}
 */
export const getStaticData = () => window.wnd?.$dataStatic ?? {};
