import React from 'react';
import UnsafeHtml from "../UnsafeHtml";
import PropTypes from 'prop-types';

/**
 * @deprecated
 *
 * used in SelectBoxField and TextField which are deprecated
 */
// není jako children, aby tom ohl být HTML string
export default function Label({label, ...props})
{
	if (!label)
	{
		return '';
	}
	if (typeof label === 'string')
	{
		return <UnsafeHtml elem="label" content={label} {...props}/>;
	}
	return <label {...props}>{label}</label>
}
Label.propTypes = {
	label: PropTypes.node
}
