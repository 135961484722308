import React from "react";
import i18n from "i18next";
import WebnodeLogo from "../../components/Ui/Layout/WebnodeLogo";
import ActionLink from "../../components/Ui/ActionLink";
import * as PropTypes from "prop-types";
import Collapsible from "../../components/Ui/Accordion/Collapsible";
import classNames from "classnames";
import UnwrappedTransition from "../../components/Ui/Transitions/UnwrappedTransition";
import {deviceIsMobile} from "../../utils/deviceUtils";

export default class Footer extends React.Component
{
	/**
	 * Defaultni properites
	 */
	static defaultProps = {
		showColumns: true,
	}


	constructor(props, context)
	{
		super(props, context);
		const isDesktop = !deviceIsMobile();
		this.state = {
			company: isDesktop,
			webnode: isDesktop,
			how: isDesktop,
			lang: false,
		};
	}

	/**
	 * Komponenta s jedním sloupcem patičky
	 */
	static Column = ({name, header, children, onHeaderClick}) => (
		<div className={"column " + name}>
			<h3 onClick={onHeaderClick}>{header}</h3>
			{children}
		</div>
	);

	/**
	 * Komponenta s jedním sloupcem patičky
	 */
	static CollapsibleColumn = ({name, header, children, onHeaderClick, open = false}) => (
		<div className={classNames("column " + name, /*open && "open",*/ open && "open-switch")}>
			<h3 onClick={onHeaderClick}>{header}</h3>
			<Collapsible
				opened={open}
				duration={300}
				interruptBehavior={UnwrappedTransition.INTERRUPT_WAIT}
				onComplete={el => !open && el.removeAttribute('style')}
			>
				{children}
			</Collapsible>
		</div>
	);

	/**
	 * Komponenta s listem odkazů sloupce
	 */
	static ColumnList = ({items, localizedUrls}) => (
		<>
		{items
			.filter(item => item.show ?? true) // pokud show neni uvedeno, zobrazime
			.map((item, inx) => (
				<Footer.ColumnListItem key={inx} {...item} localizedUrls={localizedUrls ?? {}} />
			))}
		</>
	);

	/**
	 * Komponenta s položkou listu sloupce
	 */
	static ColumnListItem = ({link, className, target, text, title, hrefLang, localizedUrls}) => (
		<li>
			<a hrefLang={hrefLang}  href={localizedUrls[hrefLang?.toLowerCase()] ?? link} className={className} title={title} target={target}>
				<span>{text}</span>
			</a>
		</li>
	);

	handleToggleColumn = (column) => {
		this.setState({
			[column]: !this.state[column]
		})
	}

	replaceLocalizedLinks = (linkItems, activeCategory) => {
		linkItems.map(function (item){
			return item.link = activeCategory.localisedUrls[item.hreflang];
		})
	};

	/**
	 * Vykreslí patičku
	 *
	 * @returns {JSX.Element}
	 */
	render()
	{
		const {columns, footerHref, lineLinks, year, showColumns, activeCategory} = this.props;

		return (
			<footer>
				{showColumns &&
					<div className="footer-full">
						<div className="section-inner">
							<div className="footer-columns cf">

								<Footer.CollapsibleColumn
									name="company"
									header={i18n.t('text[system][footer][portal]', 'Company')}
									onHeaderClick={() => this.handleToggleColumn('company')}
									open={this.state.company}
								>
									<ul>
										<Footer.ColumnList items={columns.company} />
									</ul>
								</Footer.CollapsibleColumn>

								<Footer.CollapsibleColumn
									name="webnode"
									header={i18n.t('text[system][footer][about-webnode]', 'Webnode')}
									onHeaderClick={() => this.handleToggleColumn('webnode')}
									open={this.state.webnode}
								>
									<ul>
										<Footer.ColumnList items={columns.webnode} />
									</ul>
								</Footer.CollapsibleColumn>

								<Footer.CollapsibleColumn
									name="how"
									header={i18n.t('text[system][footer][create-websites]', 'How to use Webnode?')}
									onHeaderClick={() => this.handleToggleColumn('how')}
									open={this.state.how}
								>
									<ul>
										<Footer.ColumnList items={columns.howToUse} />
									</ul>
								</Footer.CollapsibleColumn>

								<div className="follow-separ cf" />

								<Footer.Column
									name="follow"
									header={i18n.t('text[system][footer][follow-us]', 'Follow us?')}
								>
									<ul>
										<Footer.ColumnList items={columns.follow} />
									</ul>
								</Footer.Column>

								<div className="lang-separ cf" />

								<Footer.Column
									name="lang"
									header={i18n.t('text[system][footer][languages]', 'Languages')}
								>
									<ul>
										<Footer.ColumnList items={columns.languages.basic} localizedUrls={activeCategory?.localizedUrls}  />
										<li>
											<ActionLink onClick={() => this.handleToggleColumn('lang')} className="all show-lang">
												{i18n.t('text[system][footer][more-languages]', 'Show all')}
											</ActionLink>
										</li>
									</ul>
									<div className={"lang-modal " + (this.state.lang ? 'open' : '')}>
										<div className="lang-modal-content cf">
											<ul className="lang-column first">
												<Footer.ColumnList items={columns.languages.all.first} localizedUrls={activeCategory?.localizedUrls}/>
											</ul>
											<ul className="lang-column second">
												<Footer.ColumnList items={columns.languages.all.second}  localizedUrls={activeCategory?.localizedUrls}/>
											</ul>
											<ul className="lang-column third">
												<Footer.ColumnList items={columns.languages.all.third}  localizedUrls={activeCategory?.localizedUrls}/>
											</ul>
											<ul className="lang-column fourth">
												<Footer.ColumnList items={columns.languages.all.fourth}  localizedUrls={activeCategory?.localizedUrls}/>
											</ul>
											<ActionLink onClick={() => this.handleToggleColumn('lang')} className="lang-close"><span>{i18n.t('text[portal-2015][claim][side][close]', 'Close')}</span></ActionLink>
										</div>
									</div>
								</Footer.Column>
							</div>
						</div>
					</div>
				}

				<div className="footer-line">
					<div className="section-inner">
						<div className="logo">
							<a href={footerHref}>
								<WebnodeLogo/>
							</a>
						</div>

						<div className="copyright">
							&copy; {year} Webnode AG. All rights reserved
							{lineLinks.map((item, inx) => <React.Fragment key={inx}>
								&nbsp;•&nbsp;
								<a
									href={item.link}
									onClick={item.onClickReact ? eval(item.onClickReact) : (() => undefined)}>
									{item.text}
								</a>
							</React.Fragment>)}
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

Footer.propTypes = {
	footerHref: PropTypes.string,
	additionalLink: PropTypes.string,
	year: PropTypes.string.isRequired,
	showColumns: PropTypes.bool,
};
