import React from 'react';
import UnsafeHtml from "../../components/Ui/UnsafeHtml";
import i18n from "i18next";
import PropTypes from "prop-types";
import Trans from "../../components/Ui/Trans";
import ShoutBoard from '../../components/Ui/ShoutBoard.jsx';

export function TermsOfUse({termsAndConsUrl, privacyUrl})
{
	return (
		<>
			<p>
				<UnsafeHtml content={
					i18n.t(
						'text[portal-2015][loginPage][terms]',
						'By signing in you consent to terms and conditions'
					)
						.replace('%0%', termsAndConsUrl)
						.replace('%1%', privacyUrl)
				} elem="span"/>
				<br/>
				<Trans
					i18nKey="text[portal][recaptchaTerms]"
					defaults="This site is protected by reCAPTCHA and the Google <link1>Privacy Policy</link1> and <link2>Terms of Service</link2> apply."
					components={{
						link1: <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"/>,
						link2: <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer"/>,
					}}
				/>
			</p>
		</>
	);
}

export function CustomCareInfo({anonymousContactFormUrl})
{
	return <ShoutBoard show={true} scheme={ShoutBoard.SCHEME_4} className="type-ico ico-info">
		<p>
			<span>
				{i18n.t('text[portal-2015][loginPage][contactFormLoggedInfo]', 'Do you wish to contact Customer support? Please, log in first and than use our contact form.  We will be able to respond faster and more specifically. ')}
			</span>

			<Trans
				i18nKey="text[portal-2015][loginPage][contactFormPublicInfo]"
				defaults="If you don't have an account yet or you can't login, please use public <link1><span>contact form</span></link1>."
				components={{
					link1: <a href={anonymousContactFormUrl} target="_blank" rel="noreferrer"/>,
					span: <span/>,
				}}
			/>
		</p>
	</ShoutBoard>;

}

TermsOfUse.propTypes = {
	termsAndConsUrl: PropTypes.string.isRequired,
	privacyUrl: PropTypes.string.isRequired,
};

CustomCareInfo.propTypes = {
	anonymousContactFormUrl: PropTypes.string.isRequired,
};