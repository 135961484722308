/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 3. 4. 2020 11:15
 */

export function calculateNewMaxWidth(containerElem)
{
	return getWindowWidth < containerElem.offsetWidth * 1.5 ? getWindowWidth / 2 + 75 : '';
}

function getWindowWidth()
{
	return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

export function calculateValues(containerElem, target, viewPortElement )
{
	let isInverted = false;
	const targetBoundRect = target.getBoundingClientRect();
	const targetOffset = {
		top: targetBoundRect.top + document.documentElement.scrollTop,
		left: targetBoundRect.left + document.documentElement.scrollLeft
	};


	const windowWidth = getWindowWidth(containerElem);
	const windowHeight = parseFloat(getComputedStyle(document.documentElement, null).height.replace("px", ""));
	const maxWidth = windowWidth < containerElem.offsetWidth * 1.5 ?
					 windowWidth / 2 + 75 :
					 '';

	const scrollTop = document.documentElement.scrollTop;
	const scrollBottom = windowHeight + scrollTop;

	//default pozicování nahoru nad target prvek
	let pos_left = targetOffset.left + (target.offsetWidth / 2) - (containerElem.offsetWidth / 2);
	let pos_top = targetOffset.top - containerElem.offsetHeight - 10;
	const pos_arrow_left = targetOffset.left + (target.offsetWidth / 2) - 10;
	let pos_arrow_top = targetOffset.top - 10;
	let special_case_positioning = false;

	//záporný left (může nastat kvůli odečtu poloviny šířky tooltipu při targetu který je hodně vlevo)
	if (pos_left < 0)
	{
		pos_left = targetOffset.left + target.offsetWidth / 2 - 20;
		special_case_positioning = true;
	}

	//když by se pravá hrana tooltipu nevešla do okna prohlížeče
	if (pos_left + containerElem.offsetWidth > windowWidth)
	{
		pos_left = targetOffset.left - containerElem.offsetWidth + target.offsetWidth / 2 + 20;
		if (pos_left < 0)
		{
			pos_left = 0;
			special_case_positioning = true;
		}
	}

	//když by okno vyšlo nad nascrolovanou oblast dokumentu v okně prohlížeče, umístí se tooltip dolů
	if (pos_top < scrollTop)
	{
		pos_top = targetOffset.top + target.offsetHeight + 10;
		pos_arrow_top = pos_top - 10;
		isInverted = true;
	}


	//do hry vstupuje ještě snaha zůstat uvnitř zvoleného kontejneru, pokud je to možné
	if (viewPortElement) {
		const viewportBoundRect = viewPortElement.getBoundingClientRect();
		const viewportOffset = {
			top: viewportBoundRect.top + document.documentElement.scrollTop,
			left: viewportBoundRect.left + document.documentElement.scrollLeft
		};

		const vpe_pos_top = viewportOffset.top;
		const vpe_pos_right = viewportOffset.left + viewPortElement.offsetWidth;

		if (!isInverted && (vpe_pos_top > pos_top)) {
			const pos_top_candidate = targetOffset.top + target.offsetHeight + 10;
			if (pos_top_candidate + containerElem.offsetHeight < scrollBottom) {
				pos_top = pos_top_candidate;
				pos_arrow_top = pos_top - 10;
				isInverted = true;
			}
		}
		if (special_case_positioning === false && (vpe_pos_right < pos_left + containerElem.offsetWidth)) {
			pos_left = targetOffset.left - containerElem.offsetWidth + target.offsetWidth / 2 + 20;
		}
	}
	return {
		isInverted,
		arrow: {left: pos_arrow_left, top: pos_arrow_top},
		tooltip: {left: pos_left, top: pos_top}
	};
}