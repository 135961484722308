import React from 'react';
import classNames from 'classnames';
import {deviceIsMobile} from "../../../utils/deviceUtils";
import PropTypes from 'prop-types';
import {calculateNewMaxWidth, calculateValues} from "./TooltipComputations";
import UnwrappedTransition from "../Transitions/UnwrappedTransition";

export default class TooltipContainer extends React.Component {

	static DELAY_ON = deviceIsMobile() ? 0 : 300;
	static DELAY_OFF = deviceIsMobile() ? 0 : 500;
	static ANIMATION_FROM_TOP = {
		transitionStyles: {
			exited: {opacity: 0, transform: 'translate(0,-20px)',},
			entering: {opacity: 1, transform: 'translate(0,0px)',},
			entered: {opacity: 1, transform: 'translate(0,0px)'},
			exiting: {opacity: 0, transform: 'translate(0,-20px)',},
		},
		defaultStyle: {opacity: 0, transform: 'translate(0,-20px)',},
		durationIn: 150,
		durationOut: 50
	};
	static ANIMATION_FROM_BOTTOM = {
		transitionStyles: {
			exited: {opacity: 0, transform: 'translate(0,20px)',},
			entering: {opacity: 1, transform: 'translate(0,0px)',},
			entered: {opacity: 1, transform: 'translate(0,0px)'},
			exiting: {opacity: 0, transform: 'translate(0,20px)',},
		},
		defaultStyle: {opacity: 0, transform: 'translate(0,20px)',},
		durationIn: 150,
		durationOut: 50
	};
	static ANIMATION_NONE = {
		transitionStyles: {
			exited: {opacity: 0},
			entering: {opacity: 1},
			entered: {opacity: 1},
			exiting: {opacity: 0},
		},
		defaultStyle: {opacity: 0},
		durationIn: 0,
		durationOut: 0
	};

	static defaultState = {
		tooltipStyle: {top: 0, left: 0,},
		tooltipClassNames: {},
		arrowStyle: {top: 0, left: 0,},
		arrowClassNames: {},
		animation: TooltipContainer.ANIMATION_FROM_TOP,
		render: false,
	};

	static defaultProps = {
		interactive: false,
		animate: true,
	};

	timerOn;
	timerOff;
	// destruktory event specific callbacků
	eventDestructors = [];

	constructor(props, context)
	{
		super(props, context);
		this.state = {...TooltipContainer.defaultState};
		this.containerRef = React.createRef();
		this.arrowRef = React.createRef();
	}


	destruct()
	{
		document.removeEventListener('hidewebnodetooltip', this.removeTooltip);
		this.eventDestructors.forEach(d => d());
		this.eventDestructors = [];
	}

	removeTooltip = () =>
	{
		// tohle je osetreni, kvuli mazani už na smazane komponentě (je více eventt listenerů na jeden event a občas se překrývají)
		// je to tim ze sem kopcil eventy od JMA, kde to nevadilo
		// nejsem si jistej ale jak tomu zamezit (resp nevim jestli bych nekde neco nerozbil)
		// todo hezky ;)
		if (!this.containerRef.current)
		{
			return;
		}

		clearTimeout(this.timerOn);
		clearTimeout(this.timerOff);
		this.setState({render: false});
		// preemtivně odstraníme listenery
		this.destruct();
		setTimeout(() => this.props.onClose(), this.state.animation.durationOut);
	};

	onRecalculate(isResize = false)
	{
		const {invokerElement, interactive, viewPortElement, animate} = this.props;
		if (!isResize)
		{
			this.setState({
				render: false,
			});
		}

		const maxWidth =  calculateNewMaxWidth(this.containerRef.current);
		this.setState((prevState) => ({
			tooltipStyle: {
				...prevState.tooltipStyle,
				maxWidth
			}
		}));

		const {isInverted, arrow, tooltip} = calculateValues(
			this.containerRef.current,
			invokerElement,
			viewPortElement
		);

		this.setState(
			prevState => ({
				arrowStyle: {...prevState.arrowStyle, ...arrow},
				tooltipStyle: {...prevState.tooltipStyle, ...tooltip},
				arrowClassNames: {...prevState.arrowClassNames, top: isInverted},
				tooltipClassNames: {...prevState.tooltipClassNames, top: isInverted, interactive},
				animation: animate ?
					(
						isInverted ?
							TooltipContainer.ANIMATION_FROM_BOTTOM :
							TooltipContainer.ANIMATION_FROM_TOP
					) :
					TooltipContainer.ANIMATION_NONE,
			}),
			// až se nastaví state a aktualizuje dom -> vykresli (spust animaci)
			() => this.setState({render: true})
		);
	};


	resizeTooltip = () =>
		this.state.render && this.onRecalculate(true);


	bindMultiEvent(target, events, callback)
	{
		if (!target)
		{
			return () => undefined;
		}

		let destructors = events.map((event) =>
		{
			target.addEventListener(event, callback);
			return () => target.removeEventListener(event, callback);
		});
		return () => destructors.forEach(d => d());
	}

	bindRenderEvents()
	{
		// v 99% se volá bindRenderEvents jen jednou
		// ale obča se firne když se lagne PC furne se mousenter dvakrát
		this.eventDestructors.forEach(d => d());
		this.eventDestructors = [];

		const removeResizeCallback = () =>
		{
			if (deviceIsMobile())
			{
				this.removeTooltip();
			}
			else
			{
				this.resizeTooltip();
			}
		};

		this.bindMultiEvent(
			document.documentElement,
			['resize', 'orientationchange', 'scroll', 'mousewheel'],
			removeResizeCallback
		);

		if (this.props.viewPortElement) {
			this.eventDestructors.push(this.bindMultiEvent(
				this.props.viewPortElement,
				['resize', 'scroll', 'mousewheel'],
				removeResizeCallback
			));

		}

		this.eventDestructors.push(this.bindMultiEvent(
			document.querySelector('.touched .table-data-modal.open .table-data-modal-content'),
			['scroll', 'mousewheel'],
			() => this.removeTooltip()
		));
	}


	componentWillUnmount()
	{
		// ikdyz se toto děje i v remove, občas když 5 minut jezdim na tooltipu, vyskočí v konzoli chyba závislá na tom,
		// že se provedla akce z timeru timer (realne to envadi, bo se provedla chyba v unmounted komponentě ale stejně mě to štve)
		clearTimeout(this.timerOn);
		clearTimeout(this.timerOff);
		this.destruct();
	}


	handleChange()
	{
		const {action, interactive} = this.props;

		if ((action === 'mouseenter' || action === 'click'))
		{
			if ((action === 'click' && !deviceIsMobile()) || (action === 'mouseenter' && deviceIsMobile()))
			{
				return;
			}
			clearTimeout(this.timerOff);

			this.timerOn = setTimeout(() =>
			{
				this.onRecalculate();
				this.bindRenderEvents();

			}, TooltipContainer.DELAY_ON);
		}
		if (action === 'mouseleave')
		{
			this.timerOff = setTimeout(() =>
			{
				this.removeTooltip();
			}, interactive ? TooltipContainer.DELAY_OFF : 0);

		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
		if (this.props.action !== prevProps.action)
		{
			this.handleChange();
		}
	}


	componentDidMount()
	{
		document.addEventListener('hidewebnodetooltip', this.removeTooltip);
		this.handleChange();
	}


	handleBubbleEvents = (e) =>
	{
		if (this.props.interactive)
		{
			clearTimeout(this.timerOff);
		}

		if (e.type === 'mouseleave')
		{
			clearTimeout(this.timerOff);
			this.removeTooltip();
		}
		if (e.type === 'click')
		{
			e.stopPropagation();
			// klikání na odkazy nezavře bublinu
			if (e.target.tagName.toLowerCase() !== 'a')
			{
				this.removeTooltip();
			}
		}
	};


	render()
	{
		const animationSettings = {
			transitionIn: this.state.render,
			transitionStyles: this.state.animation.transitionStyles,
			duration: this.state.render ? this.state.animation.durationIn : this.state.animation.durationOut,
			transitionProperty: 'opacity,transform'
		};

		return (
			<>
				{
					TooltipContainer.defaultState.tooltipStyle !== this.state.tooltipStyle &&
					<UnwrappedTransition
						{...animationSettings}
						defaultStyle={{
							...this.state.animation.defaultStyle,
							...this.state.tooltipStyle
						}}
					>
						<div
							className={classNames('w-tooltip', this.state.tooltipClassNames)}
							style={{...this.state.tooltipStyle}}
							role="tooltip"
							onMouseEnter={this.handleBubbleEvents}
							onMouseOver={this.handleBubbleEvents}
							onMouseLeave={this.handleBubbleEvents}
							onClick={this.handleBubbleEvents}
						>
							{this.props.children}
						</div>
					</UnwrappedTransition>
				}
				{
					TooltipContainer.defaultState.arrowStyle !== this.state.arrowStyle &&
					<UnwrappedTransition
						{...animationSettings}
						defaultStyle={{
							...this.state.animation.defaultStyle,
							...this.state.arrowStyle
						}}
					>
						<div
							id="tooltip-arrow"
							className={classNames('w-tooltip-arrow', this.state.arrowClassNames)}
							style={{...this.state.arrowStyle}}
							ref={this.arrowRef}
						/>
					</UnwrappedTransition>
				}


				{/*vypocetni element*/}
				<div className="w-tooltip" style={{
					...this.state.tooltipStyle,
					opacity: 0, zIndex: -999,
					top:0, left:0,
				}} ref={this.containerRef}>
					{this.props.children}
				</div>
			</>
		);
	}
}

TooltipContainer.propTypes = {
	onClose: PropTypes.func.isRequired,
	interactive: PropTypes.bool.isRequired,
	animate: PropTypes.bool.isRequired,
	action: PropTypes.string.isRequired,
	invokerElement: PropTypes.object,  // dom element
	viewportElement: PropTypes.object, // dom element
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};

