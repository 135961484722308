import React from 'react';
import PropTypes from 'prop-types';
import UnwrappedTransition from "../Transitions/UnwrappedTransition";
import exact from 'prop-types-exact';


/**
 * Reusable Collapsible component, opens html content on click, with transition
 *
 * Used for example in Cookie bar (variant Strip), in Footer
 */
// Collapsible by měl po otevření vyhodit maximální výšku, protože některé prvky se mohou chovat špatně při zadané max height (ikdyž ten element není nijak omezen)
export default class Collapsible extends React.Component
{
	constructor(props, context) {
		super(props, context);
		this.maxHeightRef = React.createRef()
		this.maxHeightRef.current = 1000;
		this.openingAnimationFinished = this.props.opened;

		this.state = {
			enteredStyle: this.#getEnteredState(this.props.opened),
			transitionIn: this.props.opened
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
		// mění se stav opened -> propíše se do transitionIn
		if (this.props.opened !== prevProps.opened)
		{

			this.openingAnimationFinished = false;
			this.setState(
				{enteredStyle: this.#getEnteredState(this.state.transitionIn)},
				() => requestAnimationFrame(() => {
					this.setState({transitionIn: this.props.opened});
				})
			);
		}
	}

	#getEnteredState = (currentTransitionIn) => (
		(currentTransitionIn && this.props.opened && this.openingAnimationFinished)
			? {}
			: {maxHeight: this.maxHeightRef}
	)

	handleOnEntered = () => {
		this.openingAnimationFinished = true;
		this.setState({enteredStyle: this.#getEnteredState(this.state.transitionIn)});
	}

	render()
	{
		const {opened, ...transitionProps} = this.props;

		return <UnwrappedTransition
			{...transitionProps}
			transitionIn={this.state.transitionIn}
			maxHeightRef={this.maxHeightRef}
			transitionStyles={{
				exited:  { maxHeight: 0, overflow: 'hidden', display: 'none'},
				entering: { maxHeight: this.maxHeightRef, overflow: 'hidden', },
				entered:  this.state.enteredStyle,
				exiting:  { maxHeight: 0, overflow: 'hidden', },
			}}
			defaultStyle={this.state.transitionIn ? {} : {maxHeight: 0}}
			onEntered={this.handleOnEntered}
		/>
	}
}

Collapsible.propTypes = exact({
	opened: PropTypes.bool.isRequired,
	duration: PropTypes.number,
	onComplete: PropTypes.func,
	forwardRef: PropTypes.object,
	transitionProps: PropTypes.object,
	interruptBehavior: UnwrappedTransition.propTypes.interruptBehavior,
	wrapperElement: PropTypes.string,
	wrapperProps: PropTypes.object,
	easing: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
})
