import React from 'react';
import PropTypes from 'prop-types';
import MessageBar, {ICON_INFO} from "../Layout/MessageBar";
import Trans from "../Trans";
import exact from 'prop-types-exact';



export default function UpgradeNotificationMessageBar({kbLink})
{
	return <MessageBar
		icon={ICON_INFO}
		showCloseButton={false}
		opened={true}
		duration={150}
	>
		<MessageBar.CenteredContent>
			<Trans
				i18nKey={'text[dashboard][notification][upgrade][messageBar]'}
				defaults={'Webnode is currently upgrading its infrastructure. We appreciate your patience during this process. <link1><span>Learn more</span></link1>.'}
				components={{
					link1: <a href={kbLink} target={'_blank'}/>,
					span: <span/>,
				}}
			/>
		</MessageBar.CenteredContent>
	</MessageBar>;
}

UpgradeNotificationMessageBar.propTypes = exact({
	kbLink: PropTypes.string,
});
