import * as modal from '../../components/Modal/actions';
import {FormRequest} from '../../utils/FormRequest';
import {createTrackingDataString} from '../../utils/tracking';
import * as Validator from '../../utils/validators';
import i18n from 'i18next';
import {productionLog} from '../../utils/utils';
import RecaptchaApi from '../../components/Ui/ReCaptcha/RecaptchaApi';

export const REGISTRATION_CHANGE_INPUT = 'REGISTRATION_CHANGE_INPUT';
export const REGISTRATION_SET_ERROR = 'REGISTRATION_SET_ERROR';
export const REGISTRATION_SET_DATA = 'REGISTRATION_SET_DATA';
export const REGISTRATION_TRACK_INPUT = 'REGISTRATION_TRACK_INPUT';

export const MAX_PASSWORD_LENGTH = 64;


export const trackEvent = (trackingEvent) => ({type: REGISTRATION_TRACK_INPUT, trackingEvent});

export const changeInput = (name, value) => ({type: REGISTRATION_CHANGE_INPUT, name, value});
export const setFullName = (fullName) => ({type: REGISTRATION_CHANGE_INPUT, name: 'fullname', value: fullName});
export const setError = (name, error) => ({type: REGISTRATION_SET_ERROR, name, error});
export const setData = (data) => ({type: REGISTRATION_SET_DATA, data});

export const send = (url, formState) => dispatch =>
{
	if (!Validator.filled(formState.inputs.fullname.value))
	{
		dispatch(setError('fullname',  i18n.t(
			'text[webnode][__global_signup-error-user-4__]',
			'You can use your name, the name of your company or association.',
		)));
		return Promise.reject().catch(() => 
		{});
	}
	else if(!Validator.email(formState.inputs.email.value))
	{
		dispatch(setError('email', i18n.t(
			'text[webnode][__global_signup-error-email__]',
			'Fill in valid email address',
		)));
		return Promise.reject().catch(() => 
		{});
	}
	else if(!Validator.minlength(6)(formState.inputs.password.value))
	{
		dispatch(setError('email', i18n.t(
			'text[webnode][__global_signup-error-user-pwd-short__]',
			'Password must contain atleast __PASSWORD_MIN_LENGTH__ caharacters.',
		).replace('__PASSWORD_MIN_LENGTH__', '6')));
		return Promise.reject().catch(() => 
		{});
	}
	else if (!Validator.maxlength(MAX_PASSWORD_LENGTH)(formState.inputs.password.value))
	{
		dispatch(setError('password', i18n.t(
			'text[webnode][passwordToLong]',
			'Maximum password length is {length}.',
			{length: MAX_PASSWORD_LENGTH},
		)));
		return Promise.reject().catch(() => 
		{});
	}
	dispatch(modal.setLoading(true));

	url = url ?? window.location.href;

	productionLog('Register: recaptchaV3');
	return RecaptchaApi.executeV3WhenReady(RecaptchaApi.ACTION_REGISTRATION).then(token => 
	{
		productionLog('Register: prepare-request');
		const gaCookie = document.getElementById('gaCookie');
		const gclid = JSON.parse(localStorage.getItem('gclid'));
		const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate && gclid.value;

		const request = new FormRequest(url, {
			...FormRequest.inputsToData(formState.inputs),
			...formState.data,
			gaCookie: gaCookie && gaCookie.value,
			gclid: isGclidValid && gclid.value,
			trackingData: createTrackingDataString(formState.trackingData),
			reCaptchaV3Token: token,
		}, {id: 'headerSignUp'}); // headerSignUp pro gtm
		productionLog('Register: send-request');
		request.send();
	});
};

export const sendSocial = (url, type, formState) => (dispatch) =>
{
	if (!Validator.filled(formState.inputs.fullname.value))
	{
		const errorMessage = i18n.t(
			'text[webnode][__global_signup-error-user-4__]',
			'You can use your name, the name of your company or association.',
		);
		dispatch(setError('fullname',  errorMessage));
		return Promise.reject(new Error(errorMessage)).catch(() =>
		{});
	}
	dispatch(modal.setLoading(true));
	const gaCookie = document.getElementById('gaCookie');
	const gclid = JSON.parse(localStorage.getItem('gclid'));
	const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate && gclid.value;
	productionLog('Register: recaptchaV3');
	return RecaptchaApi.executeV3WhenReady(RecaptchaApi.ACTION_REGISTRATION).then(token =>
	{
		productionLog('Register: prepare-request');
		const request = new FormRequest(url, {
			socialAction: 'sign-up',
			application: type,
			trackingData: createTrackingDataString(formState.trackingData),
			...FormRequest.inputsToData(formState.inputs),
			...formState.data,
			gaCookie: gaCookie && gaCookie.value,
			gclid: isGclidValid && gclid.value,
			facebookTest: window.location.search.includes('facebook-test') ? 1 : 0,
			reCaptchaV3Token: token,
		}, {id: 'headerSignUp'}); // headerSignUp pro gtm
		productionLog('Register: send-request');
		request.send();
	});
};
