import React from 'react';
import PropTypes from 'prop-types';

export default class ActionLink extends React.Component
{
	handleClick = (event) =>
	{
		event.preventDefault();

		if (this.props.onClick)
		{
			this.props.onClick(event);
		}
	};

	render()
	{
		const {forwardedRef, children, ...props} = this.props;
		return (
			<a
				href=""
				{...props}
				onClick={this.handleClick}
				role="button"
				aria-disabled={this.props.onClick ? null : true}
				{...(forwardedRef ? {ref: forwardedRef} : {})}
				tabIndex={this.props.tabIndex}
			>
				{children}
			</a>
		);
	}

}

ActionLink.propTypes = {
	onClick: PropTypes.func,
	tabIndex: PropTypes.number,
};
