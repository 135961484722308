/* eslint-disable */
// @ts-nocheck
import type { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: { input: any; output: any; }
};

export type AdditionalItemIdentifier =
  | 'premium_protection'
  | 'vip_security';

export type AdditiveColor = {
  __typename?: 'AdditiveColor';
  color?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type BranchType = {
  __typename?: 'BranchType';
  id: Scalars['Int']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Scalars['String']['output']>;
  seedQuestion?: Maybe<Scalars['String']['output']>;
  seedQuestionHelp?: Maybe<Scalars['String']['output']>;
};

export type Contact = ContactInterface & {
  __typename?: 'Contact';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company: Scalars['String']['output'];
  country?: Maybe<Country>;
  dic: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  ico: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  surname: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type ContactFieldTypeInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ContactInput = ContactInputInterface & {
  __typename?: 'ContactInput';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  validation: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ContactInputInterface = {
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  validation: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ContactInterface = {
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company: Scalars['String']['output'];
  country?: Maybe<Country>;
  dic: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  ico: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  surname: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type ContentType = {
  __typename?: 'ContentType';
  description: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  recommended: Scalars['Boolean']['output'];
};

export type CookieBarSettings = {
  __typename?: 'CookieBarSettings';
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  langId: Scalars['Int']['output'];
};

export type CookieBarSettingsInput = {
  code: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  langId: Scalars['Int']['input'];
};

export type CookiePolicySettings = {
  __typename?: 'CookiePolicySettings';
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  langId: Scalars['Int']['output'];
};

export type CookiePolicySettingsInput = {
  code: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  langId: Scalars['Int']['input'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  normalized: Scalars['String']['output'];
  /** Vrací číselné kódy zemí */
  numericCode: Scalars['Int']['output'];
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String']['output'];
};

export type CustomCheckboxFieldSettings = {
  __typename?: 'CustomCheckboxFieldSettings';
  label?: Maybe<Scalars['String']['output']>;
  langId: Scalars['Int']['output'];
  mode: FieldModeType;
};

export type CustomCheckboxFieldSettingsInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  langId: Scalars['Int']['input'];
  mode: FieldModeType;
};

export type CustomCheckoutSettingsType = {
  __typename?: 'CustomCheckoutSettingsType';
  customCheckboxFieldSettings: Array<CustomCheckboxFieldSettings>;
  customCheckoutLogo?: Maybe<ProjectImageData>;
  customInputFieldSettings: Array<CustomInputFieldSettings>;
  customerNoteFieldSettings: Array<CustomerNoteFieldSettings>;
  digitalOrderBillingAddressRequired: Scalars['Boolean']['output'];
  phoneNumberFieldSettings: Array<PhoneNumberFieldSettings>;
  showCompanyBilling: Scalars['Boolean']['output'];
  showDifferentShippingAddress: Scalars['Boolean']['output'];
  showNewsletterCheckbox: Scalars['Boolean']['output'];
  showVoucherInput: Scalars['Boolean']['output'];
};

export type CustomInputFieldSettings = {
  __typename?: 'CustomInputFieldSettings';
  label?: Maybe<Scalars['String']['output']>;
  langId: Scalars['Int']['output'];
  mode: FieldModeType;
};

export type CustomInputFieldSettingsInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  langId: Scalars['Int']['input'];
  mode: FieldModeType;
};

export type CustomerNoteFieldSettings = {
  __typename?: 'CustomerNoteFieldSettings';
  label?: Maybe<Scalars['String']['output']>;
  langId: Scalars['Int']['output'];
  mode: FieldModeType;
  placeholder?: Maybe<Scalars['String']['output']>;
};

export type CustomerNoteFieldSettingsInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  langId: Scalars['Int']['input'];
  mode: FieldModeType;
  placeholder?: InputMaybe<Scalars['String']['input']>;
};

export type DomainContact = ContactInterface & {
  __typename?: 'DomainContact';
  address: Scalars['String']['output'];
  birthdate?: Maybe<Scalars['String']['output']>;
  /** Vrátí CPF kód pro TLD *.com.br */
  brCpf?: Maybe<Scalars['String']['output']>;
  /** Vrátí typ firmy pro TLD *.ca */
  caEntityType?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  company: Scalars['String']['output'];
  /** Vrátí typ firmy */
  companyType?: Maybe<Scalars['String']['output']>;
  /** Vrátí typ kontaktu (personal|company) */
  contactType?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  dic: Scalars['String']['output'];
  email: Scalars['String']['output'];
  /** Vrátí číslo občanky pro TLD *.es */
  esEntityNr?: Maybe<Scalars['String']['output']>;
  /** Vrátí typ firmy pro TLD *.es */
  esEntityType?: Maybe<Scalars['String']['output']>;
  /** Vrátí DIČ pro TLD *.es */
  esVatNumber?: Maybe<Scalars['String']['output']>;
  firstname: Scalars['String']['output'];
  ico: Scalars['String']['output'];
  /** Vrátí číslo občasnského průkazu nebo rodné číslo */
  identityNr?: Maybe<Scalars['String']['output']>;
  /** Vrátí IČO pro TLD *.it */
  itCodiceFiscale?: Maybe<Scalars['String']['output']>;
  /** Vrátí typ firmy pro TLD *.it */
  itEntityType?: Maybe<Scalars['String']['output']>;
  /** Vrátí DIČ pro TLD *.it */
  itVatNumber?: Maybe<Scalars['String']['output']>;
  /** Vrátí speciální pole "Accept date" z Noridu pro TLD *.no */
  noAcceptDate?: Maybe<Scalars['String']['output']>;
  /** Vrátí speciální pole "Accept name" z Noridu pro TLD *.no */
  noAcceptName?: Maybe<Scalars['String']['output']>;
  /** Vrátí číslo občanského průkazu pro TLD *.no */
  noIdentityNr?: Maybe<Scalars['String']['output']>;
  noRegistrationNumber?: Maybe<Scalars['String']['output']>;
  /** Vrátí speciální pole z "Version number" z Noridu pro TLD *.no */
  noVersionNumber?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  /** Vrátí registrační číslo firmy pro TLD *.ro */
  roRegistrationNumber?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  surname: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type ExtendedSettingsType = {
  __typename?: 'ExtendedSettingsType';
  conversion?: Maybe<Scalars['String']['output']>;
  verifiedCustomers?: Maybe<Scalars['String']['output']>;
  verifiedCustomersWidgetPosition?: Maybe<Scalars['String']['output']>;
};

export type FieldModeType =
  | 'hidden'
  | 'optional'
  | 'required';

export type InvoicingSzamlazzSettingsType = {
  __typename?: 'InvoicingSzamlazzSettingsType';
  szamlazzApiKey: Scalars['String']['output'];
  szamlazzApiKeyValid: Scalars['Boolean']['output'];
  szamlazzConnected: Scalars['Boolean']['output'];
  szamlazzEInvoice: Scalars['String']['output'];
  szamlazzInvoiceBankAccount: Scalars['String']['output'];
  szamlazzInvoiceBankName: Scalars['String']['output'];
  szamlazzInvoiceComment: Scalars['String']['output'];
  szamlazzInvoicePrefix: Scalars['String']['output'];
  szamlazzSendEmail: Scalars['String']['output'];
};

export type InvoicingWebnodeSettingsType = {
  __typename?: 'InvoicingWebnodeSettingsType';
  invoiceContentBottomText?: Maybe<Scalars['String']['output']>;
  invoiceContentUpperText?: Maybe<Scalars['String']['output']>;
  invoiceSending?: Maybe<Scalars['String']['output']>;
  numberingCharacterCount?: Maybe<Scalars['String']['output']>;
  numberingPrefix?: Maybe<Scalars['String']['output']>;
  numberingStartFrom?: Maybe<Scalars['String']['output']>;
};

export type IubendaSettingsType = {
  __typename?: 'IubendaSettingsType';
  cookieBarSettings: Array<CookieBarSettings>;
  cookiePolicySettings: Array<CookiePolicySettings>;
  privacyPolicySettings: Array<PrivacyPolicySettings>;
  termsAndConditionsSettings: Array<TermsAndConditionsSettings>;
};

export type LangFileType = {
  __typename?: 'LangFileType';
  langIdentifier: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LogoInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
};

export type MainOrderType =
  | 'individual'
  | 'newDomain'
  | 'newPackage'
  | 'newPackageAndDomain'
  | 'renewDomain'
  | 'renewPackage';

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float']['output'];
  currency: Currency;
};

export type Mutation = {
  __typename?: 'Mutation';
  addOrderAdditionalItem: WebnodeOrder;
  assignDomain: Scalars['String']['output'];
  cancelOrder: Scalars['Boolean']['output'];
  changePreviewOptions: Scalars['Boolean']['output'];
  chooseTemplate: Scalars['Boolean']['output'];
  clearUploadedMedia: Scalars['Boolean']['output'];
  createAddonOrder: WebnodeOrder;
  /**
   * Error codes:
   *  `DOMAIN_IN_REGISTRATION_PROCESS` - domain is already in webnode registration process
   */
  createDomainRegistrationOrder: WebnodeOrder;
  createDomainRenewalOrder: WebnodeOrder;
  /**
   * Creates order for the package. By default, additional services for the package will be added if according
   * features are released.
   * Error codes:
   * `BAD_PACKAGE` - attempt to create order with a free package or package which is lower that the current one
   */
  createPackageOrder: WebnodeOrder;
  createPromotion: Promotion;
  deleteOrderAdditionalItem: WebnodeOrder;
  /**
   * Pokud exceptionIds je `null`, smaze promotions podle id predanych v `ids`. Jinak
   * smaze vsechny promotions mimo id predanych v `exceptionIds`
   */
  deletePromotions: Array<Scalars['Int']['output']>;
  finishOrder: WebnodeOrder;
  logSeed: Scalars['Boolean']['output'];
  prepareParameterSets: Array<ParametersSet>;
  preparePreviews: Scalars['Boolean']['output'];
  prepareTexts: Scalars['Boolean']['output'];
  regenerate: Scalars['Boolean']['output'];
  setContactInfo: Scalars['Boolean']['output'];
  setCookieConsent: Scalars['Boolean']['output'];
  setOrderPackage: WebnodeOrder;
  updateAutomatedInvoicingState: Scalars['Boolean']['output'];
  updateCustomCheckboxFieldSettings: Scalars['Boolean']['output'];
  updateCustomInputFieldSettings: Scalars['Boolean']['output'];
  updateCustomerNoteFieldSettings: Scalars['Boolean']['output'];
  updateDigitalOrdersRequireBillingAddress: Scalars['Boolean']['output'];
  updateEshopInvoiceSettings: Scalars['Boolean']['output'];
  updateEshopInvoicingSzamlazzApiKey: Scalars['Boolean']['output'];
  updateEshopInvoicingSzamlazzPrefix: SzamlazzInvoicePrefixResponseType;
  updateIubendaCookieBar: Scalars['Boolean']['output'];
  updateIubendaCookiePolicy: Scalars['Boolean']['output'];
  updateIubendaPrivacyPolicy: Scalars['Boolean']['output'];
  updateIubendaTermsAndConditions: Scalars['Boolean']['output'];
  updateOrderDomainContactData: WebnodeOrder;
  updateOrderPrivateRegistration: WebnodeOrder;
  updateOrderServicePeriod: WebnodeOrder;
  updateOrderVoucher: WebnodeOrder;
  updatePhoneNumberFieldSettings: Scalars['Boolean']['output'];
  updatePromotion: Promotion;
  /**
   * Pokud exceptionIds je `null`, zmeni stav promotions podle id predanych v `ids`. Jinak
   * zmeni stav vsech promotions mimo id predanych v `exceptionIds`
   */
  updatePromotionsState: Array<Promotion>;
  updateShowCompanyBilling: Scalars['Boolean']['output'];
  updateShowDifferentShippingAddress: Scalars['Boolean']['output'];
  updateShowNewsletterCheckbox: Scalars['Boolean']['output'];
  updateShowVoucherInput: Scalars['Boolean']['output'];
  uploadCustomCheckoutLogo?: Maybe<ProjectImageData>;
  uploadLogo: Scalars['Boolean']['output'];
  uploadMediaSkipped: Scalars['Boolean']['output'];
  uploadPhoto: Scalars['Boolean']['output'];
};


export type MutationAddOrderAdditionalItemArgs = {
  integrityToken?: InputMaybe<Scalars['String']['input']>;
  itemIdentifier: AdditionalItemIdentifier;
  order: OrderRefInput;
  period?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAssignDomainArgs = {
  domainName: Scalars['String']['input'];
  projectName: Scalars['String']['input'];
};


export type MutationCancelOrderArgs = {
  order: OrderRefInput;
};


export type MutationChangePreviewOptionsArgs = {
  parametersSet: UpdateParametersSetInput;
  projectName: Scalars['String']['input'];
  selectedTemplate: Scalars['Int']['input'];
};


export type MutationChooseTemplateArgs = {
  branch?: InputMaybe<Scalars['String']['input']>;
  projectName: Scalars['String']['input'];
  seed?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['Int']['input'];
};


export type MutationClearUploadedMediaArgs = {
  projectName: Scalars['String']['input'];
};


export type MutationCreateAddonOrderArgs = {
  addonIdentifier: AdditionalItemIdentifier;
  project: ProjectRefInput;
};


export type MutationCreateDomainRegistrationOrderArgs = {
  domainName: Scalars['String']['input'];
  project: ProjectRefInput;
};


export type MutationCreateDomainRenewalOrderArgs = {
  domainName: Scalars['String']['input'];
  project: ProjectRefInput;
};


export type MutationCreatePackageOrderArgs = {
  action: PackageOrderActionEnum;
  packageIdentifier: PackageIdentifierEnum;
  project: ProjectRefInput;
};


export type MutationCreatePromotionArgs = {
  appliesTo: Scalars['String']['input'];
  appliesToMinValue?: InputMaybe<Scalars['Float']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  maximumUses?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  projectName: Scalars['String']['input'];
  state: Scalars['String']['input'];
  type: Scalars['String']['input'];
  valueOff?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationDeleteOrderAdditionalItemArgs = {
  hash: Scalars['String']['input'];
  integrityToken?: InputMaybe<Scalars['String']['input']>;
  order: OrderRefInput;
};


export type MutationDeletePromotionsArgs = {
  exceptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  ids: Array<Scalars['Int']['input']>;
  projectName: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type MutationFinishOrderArgs = {
  contact?: InputMaybe<OrderContactInput>;
  integrityToken?: InputMaybe<Scalars['String']['input']>;
  order: OrderRefInput;
  paymentServiceName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLogSeedArgs = {
  projectName: Scalars['String']['input'];
  seed?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPrepareParameterSetsArgs = {
  branch: Scalars['String']['input'];
  keywords?: InputMaybe<Scalars['String']['input']>;
  optionalContent: Array<Scalars['String']['input']>;
  projectName: Scalars['String']['input'];
  seed?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPreparePreviewsArgs = {
  projectName: Scalars['String']['input'];
};


export type MutationPrepareTextsArgs = {
  branch: Scalars['String']['input'];
  projectName: Scalars['String']['input'];
  seed?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegenerateArgs = {
  projectName: Scalars['String']['input'];
};


export type MutationSetContactInfoArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  projectName: Scalars['String']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetCookieConsentArgs = {
  consent: Scalars['String']['input'];
};


export type MutationSetOrderPackageArgs = {
  order: OrderRefInput;
  package: PackageIdentifierEnum;
};


export type MutationUpdateAutomatedInvoicingStateArgs = {
  projectName: Scalars['String']['input'];
  state: Scalars['String']['input'];
};


export type MutationUpdateCustomCheckboxFieldSettingsArgs = {
  fieldSettings: CustomCheckboxFieldSettingsInput;
  projectName: Scalars['String']['input'];
};


export type MutationUpdateCustomInputFieldSettingsArgs = {
  fieldSettings: CustomInputFieldSettingsInput;
  projectName: Scalars['String']['input'];
};


export type MutationUpdateCustomerNoteFieldSettingsArgs = {
  fieldSettings: CustomerNoteFieldSettingsInput;
  projectName: Scalars['String']['input'];
};


export type MutationUpdateDigitalOrdersRequireBillingAddressArgs = {
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationUpdateEshopInvoiceSettingsArgs = {
  field: Scalars['String']['input'];
  projectName: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationUpdateEshopInvoicingSzamlazzApiKeyArgs = {
  projectName: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationUpdateEshopInvoicingSzamlazzPrefixArgs = {
  projectName: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationUpdateIubendaCookieBarArgs = {
  pluginSettings: CookieBarSettingsInput;
  projectName: Scalars['String']['input'];
};


export type MutationUpdateIubendaCookiePolicyArgs = {
  pluginSettings: CookiePolicySettingsInput;
  projectName: Scalars['String']['input'];
};


export type MutationUpdateIubendaPrivacyPolicyArgs = {
  pluginSettings: PrivacyPolicySettingsInput;
  projectName: Scalars['String']['input'];
};


export type MutationUpdateIubendaTermsAndConditionsArgs = {
  pluginSettings: TermsAndConditionsSettingsInput;
  projectName: Scalars['String']['input'];
};


export type MutationUpdateOrderDomainContactDataArgs = {
  contactFields: Array<ContactFieldTypeInput>;
  order: OrderRefInput;
};


export type MutationUpdateOrderPrivateRegistrationArgs = {
  domain: Scalars['String']['input'];
  enable: Scalars['Boolean']['input'];
  integrityToken?: InputMaybe<Scalars['String']['input']>;
  order: OrderRefInput;
  period: Scalars['String']['input'];
};


export type MutationUpdateOrderServicePeriodArgs = {
  integrityToken?: InputMaybe<Scalars['String']['input']>;
  itemIdentifier: Scalars['String']['input'];
  order: OrderRefInput;
  period: Scalars['String']['input'];
  units: Scalars['Int']['input'];
};


export type MutationUpdateOrderVoucherArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  integrityToken?: InputMaybe<Scalars['String']['input']>;
  order: OrderRefInput;
};


export type MutationUpdatePhoneNumberFieldSettingsArgs = {
  fieldSettings: PhoneNumberFieldSettingsInput;
  projectName: Scalars['String']['input'];
};


export type MutationUpdatePromotionArgs = {
  appliesTo: Scalars['String']['input'];
  appliesToMinValue?: InputMaybe<Scalars['Float']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  maximumUses?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  projectName: Scalars['String']['input'];
  state: Scalars['String']['input'];
  type: Scalars['String']['input'];
  valueOff?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdatePromotionsStateArgs = {
  exceptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  ids: Array<Scalars['Int']['input']>;
  projectName: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
};


export type MutationUpdateShowCompanyBillingArgs = {
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationUpdateShowDifferentShippingAddressArgs = {
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationUpdateShowNewsletterCheckboxArgs = {
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationUpdateShowVoucherInputArgs = {
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationUploadCustomCheckoutLogoArgs = {
  logo?: InputMaybe<Scalars['Upload']['input']>;
  projectName: Scalars['String']['input'];
};


export type MutationUploadLogoArgs = {
  logo?: InputMaybe<Scalars['Upload']['input']>;
  projectName: Scalars['String']['input'];
};


export type MutationUploadMediaSkippedArgs = {
  projectName: Scalars['String']['input'];
};


export type MutationUploadPhotoArgs = {
  file: Scalars['Upload']['input'];
  projectName: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type OrderContactInput = {
  contactFields: Array<ContactFieldTypeInput>;
};

export type OrderRefInput = {
  identifier: Scalars['String']['input'];
};

export type PackageIdentifierEnum =
  | 'business'
  | 'free'
  | 'limited'
  | 'mini'
  | 'professional'
  | 'standard';

export type PackageOrderActionEnum =
  | 'purchase'
  | 'renew';

export type ParametersSet = {
  __typename?: 'ParametersSet';
  additiveColor: Scalars['String']['output'];
  layout: Scalars['String']['output'];
  typography: Scalars['String']['output'];
};

export type PaymentServiceIconType = {
  __typename?: 'PaymentServiceIconType';
  class?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PaymentServiceInputType = {
  __typename?: 'PaymentServiceInputType';
  editable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type PaymentServiceType = {
  __typename?: 'PaymentServiceType';
  className: Scalars['String']['output'];
  elementId: Scalars['String']['output'];
  forceBillingForm: Scalars['Boolean']['output'];
  icon: Scalars['String']['output'];
  icons: Array<PaymentServiceIconType>;
  iframe: Scalars['Boolean']['output'];
  infoboxText: Scalars['String']['output'];
  inputs: Array<PaymentServiceInputType>;
  largeIcon: Scalars['String']['output'];
  name: Scalars['String']['output'];
  newWindow: Scalars['Boolean']['output'];
  open: Scalars['Boolean']['output'];
  oppositeElementId: Scalars['String']['output'];
  recurringEnabled: Scalars['Boolean']['output'];
  redirectUrl: Scalars['String']['output'];
  requiredBillingFields: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PhoneNumberFieldSettings = {
  __typename?: 'PhoneNumberFieldSettings';
  langId: Scalars['Int']['output'];
  mode: FieldModeType;
};

export type PhoneNumberFieldSettingsInput = {
  langId: Scalars['Int']['input'];
  mode: FieldModeType;
};

export type PorpaginasResult_Promotion = {
  __typename?: 'PorpaginasResult_Promotion';
  /** The total count of items. */
  count?: Maybe<Scalars['Int']['output']>;
  items: Array<Promotion>;
};


export type PorpaginasResult_PromotionItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PrivacyPolicySettings = {
  __typename?: 'PrivacyPolicySettings';
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  langId: Scalars['Int']['output'];
};

export type PrivacyPolicySettingsInput = {
  code: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  langId: Scalars['Int']['input'];
};

export type ProductFeedSettingsType = {
  __typename?: 'ProductFeedSettingsType';
  extended: ExtendedSettingsType;
  help: Scalars['String']['output'];
  langFiles: Array<LangFileType>;
  on: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProjectImageData = {
  __typename?: 'ProjectImageData';
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProjectRefInput = {
  name: Scalars['String']['input'];
};

export type Promotion = {
  __typename?: 'Promotion';
  appliesTo: Scalars['String']['output'];
  appliesToMinValue?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  maximumUses: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
  typeName: Scalars['String']['output'];
  uses: Scalars['Int']['output'];
  valueOff?: Maybe<Scalars['Float']['output']>;
};


export type PromotionTypeNameArgs = {
  currency: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  branchContents: Array<ContentType>;
  branches: Array<BranchType>;
  customCheckoutSettings: CustomCheckoutSettingsType;
  eshopAutomaticInvoicing: Scalars['String']['output'];
  eshopInvoiceEnabledExample: Scalars['String']['output'];
  eshopInvoiceSzamlazzConnected: Scalars['Boolean']['output'];
  eshopInvoicingEnabledSettings: InvoicingWebnodeSettingsType;
  eshopInvoicingSzamlazzSettings: InvoicingSzamlazzSettingsType;
  hello: Scalars['String']['output'];
  iubendaSettings: IubendaSettingsType;
  previewLinks?: Maybe<Array<Scalars['String']['output']>>;
  progressivePreviewLinks: Array<Maybe<Scalars['String']['output']>>;
  promotions: PorpaginasResult_Promotion;
  scrapeSiteName: Scalars['String']['output'];
  seedInfo: SeedInfo;
  serviceSettings: ProductFeedSettingsType;
  shouldExecuteReCaptchaV3: Scalars['Boolean']['output'];
  templateSettings: TemplateSettings;
  /** Summary of a Webnode services order */
  webnodeOrder: WebnodeOrder;
  webnodePaymentMethods: Array<PaymentServiceType>;
};


export type QueryBranchContentsArgs = {
  branch: Scalars['String']['input'];
  ownBranch?: InputMaybe<Scalars['String']['input']>;
  projectName: Scalars['String']['input'];
};


export type QueryCustomCheckoutSettingsArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryEshopAutomaticInvoicingArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryEshopInvoiceEnabledExampleArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryEshopInvoiceSzamlazzConnectedArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryEshopInvoicingEnabledSettingsArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryEshopInvoicingSzamlazzSettingsArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryHelloArgs = {
  name: Scalars['String']['input'];
};


export type QueryIubendaSettingsArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryPreviewLinksArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryProgressivePreviewLinksArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryPromotionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectName: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryScrapeSiteNameArgs = {
  url: Scalars['String']['input'];
};


export type QuerySeedInfoArgs = {
  projectName: Scalars['String']['input'];
  seed: Scalars['String']['input'];
};


export type QueryServiceSettingsArgs = {
  projectName: Scalars['String']['input'];
  serviceName: Scalars['String']['input'];
};


export type QueryShouldExecuteReCaptchaV3Args = {
  email: Scalars['String']['input'];
};


export type QueryTemplateSettingsArgs = {
  projectName: Scalars['String']['input'];
};


export type QueryWebnodeOrderArgs = {
  order: OrderRefInput;
};


export type QueryWebnodePaymentMethodsArgs = {
  lastReloadBeforeSubmit: Scalars['Boolean']['input'];
  merchantTransactionId?: InputMaybe<Scalars['String']['input']>;
  methodId?: InputMaybe<Scalars['String']['input']>;
  order: OrderRefInput;
  ref?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type SeedInfo = {
  __typename?: 'SeedInfo';
  branch: BranchType;
  contents: Array<ContentType>;
};

export type SelectContactInput = ContactInputInterface & {
  __typename?: 'SelectContactInput';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options: Array<SelectOption>;
  priority: Scalars['Int']['output'];
  selectedOption?: Maybe<SelectOption>;
  type: Scalars['String']['output'];
  validation: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  text: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type SzamlazzInvoicePrefixResponseType =
  | 'INVALID_API_KEY'
  | 'INVALID_INVOICE_PREFIX'
  | 'SUCCESS';

export type TemplateSettings = {
  __typename?: 'TemplateSettings';
  additiveColors: Array<AdditiveColor>;
  typos: Array<Typography>;
};

export type TermsAndConditionsSettings = {
  __typename?: 'TermsAndConditionsSettings';
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  langId: Scalars['Int']['output'];
};

export type TermsAndConditionsSettingsInput = {
  code: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  langId: Scalars['Int']['input'];
};

export type Typography = {
  __typename?: 'Typography';
  id: Scalars['String']['output'];
  preview?: Maybe<Scalars['String']['output']>;
};

export type UpdateParametersSetInput = {
  additiveColor?: InputMaybe<Scalars['String']['input']>;
  typography?: InputMaybe<Scalars['String']['input']>;
};

export type VoucherType = {
  __typename?: 'VoucherType';
  code: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type WebnodeOrder = {
  __typename?: 'WebnodeOrder';
  anonymouseDomainsUrl: Scalars['String']['output'];
  checkoutUrl: Scalars['String']['output'];
  companyBilling: Scalars['Boolean']['output'];
  contact: ContactInterface;
  contactInputs: Array<ContactInputInterface>;
  coveredByVoucher: Scalars['Boolean']['output'];
  customerCareDomainRestoration: Scalars['Boolean']['output'];
  domainExpirationDate: Scalars['String']['output'];
  domainNames: Array<Scalars['String']['output']>;
  domainRegistrationDetailsUrl: Scalars['String']['output'];
  fromDate: Scalars['String']['output'];
  fullTitle: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  items: Array<WebnodeOrderItemTypeInterface>;
  mainOrderType?: Maybe<MainOrderType>;
  nextPaymentDate: Scalars['String']['output'];
  originalPriceFormatted?: Maybe<Scalars['String']['output']>;
  originalPriceWrapped?: Maybe<Scalars['String']['output']>;
  packageChooseUrl: Scalars['String']['output'];
  packageExpirationDate: Scalars['String']['output'];
  paymentFailUrl: Scalars['String']['output'];
  paymentSuccessUrl: Scalars['String']['output'];
  pluginExpirationDate: Scalars['String']['output'];
  price?: Maybe<Money>;
  priceFormatted: Scalars['String']['output'];
  priceWrapped: Scalars['String']['output'];
  /**
   * If an order is an add-on order i.e. does not contain package and domain items it will return a target add-on hash
   * which is the add-on which was the first item in the order.
   */
  targetAddonHash?: Maybe<Scalars['String']['output']>;
  /** The end date of a package to which addon will be bound. */
  targetAddonParentExpirationDate?: Maybe<Scalars['String']['output']>;
  /** Identifier of a package to which addon will be bound. */
  targetAddonParentIdentifier?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  transfer: Scalars['Boolean']['output'];
  type?: Maybe<WebnodeOrderTypeEnum>;
  voucher?: Maybe<VoucherType>;
};


export type WebnodeOrderCheckoutUrlArgs = {
  ref?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type WebnodeOrderDomainRegistrationDetailsUrlArgs = {
  ref?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type WebnodeOrderPackageChooseUrlArgs = {
  ref?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type WebnodeOrderDomainItemType = WebnodeOrderItemTypeInterface & {
  __typename?: 'WebnodeOrderDomainItemType';
  checked: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  currentVariant: WebnodeOrderItemCurrentVariant;
  disabled: Scalars['Boolean']['output'];
  domain: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  period: Scalars['String']['output'];
  supportsFreeVoucher: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variants: Array<WebnodeOrderItemVariantType>;
  variantsHeader: Scalars['String']['output'];
};

export type WebnodeOrderItemCurrentVariant = {
  __typename?: 'WebnodeOrderItemCurrentVariant';
  basePrice: Scalars['String']['output'];
  firstYearFree: Scalars['Boolean']['output'];
  free: Scalars['Boolean']['output'];
  price: Scalars['String']['output'];
  priceExplained: Scalars['String']['output'];
};

export type WebnodeOrderItemType = WebnodeOrderItemTypeInterface & {
  __typename?: 'WebnodeOrderItemType';
  checked: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  currentVariant: WebnodeOrderItemCurrentVariant;
  disabled: Scalars['Boolean']['output'];
  hash: Scalars['String']['output'];
  period: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variants: Array<WebnodeOrderItemVariantType>;
  variantsHeader: Scalars['String']['output'];
};

export type WebnodeOrderItemTypeInterface = {
  checked: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  currentVariant: WebnodeOrderItemCurrentVariant;
  disabled: Scalars['Boolean']['output'];
  hash: Scalars['String']['output'];
  period: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variants: Array<WebnodeOrderItemVariantType>;
  variantsHeader: Scalars['String']['output'];
};

export type WebnodeOrderItemVariantInterval = {
  __typename?: 'WebnodeOrderItemVariantInterval';
  period: Scalars['String']['output'];
  units: Scalars['Int']['output'];
};

export type WebnodeOrderItemVariantType = {
  __typename?: 'WebnodeOrderItemVariantType';
  active: Scalars['Boolean']['output'];
  interval?: Maybe<WebnodeOrderItemVariantInterval>;
  period: Scalars['String']['output'];
  price: Scalars['String']['output'];
  recommendationLevel: Scalars['Int']['output'];
  savings?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  variantHeader: Scalars['String']['output'];
};

export type WebnodeOrderPackageAddonItemType = WebnodeOrderItemTypeInterface & {
  __typename?: 'WebnodeOrderPackageAddonItemType';
  checked: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  currentVariant: WebnodeOrderItemCurrentVariant;
  disabled: Scalars['Boolean']['output'];
  hash: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  info?: Maybe<Scalars['String']['output']>;
  kbArticleUrl?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  period: Scalars['String']['output'];
  showMoreText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variants: Array<WebnodeOrderItemVariantType>;
  variantsHeader: Scalars['String']['output'];
};

export type WebnodeOrderPackageItemType = WebnodeOrderItemTypeInterface & {
  __typename?: 'WebnodeOrderPackageItemType';
  checked: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  currentVariant: WebnodeOrderItemCurrentVariant;
  disabled: Scalars['Boolean']['output'];
  discountLabel: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  levelDiscountedAmount: Scalars['Int']['output'];
  packageIdentifier: Scalars['String']['output'];
  period: Scalars['String']['output'];
  priceLevelDiscount: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variants: Array<WebnodeOrderItemVariantType>;
  variantsHeader: Scalars['String']['output'];
};

export type WebnodeOrderPluginItemType = WebnodeOrderItemTypeInterface & {
  __typename?: 'WebnodeOrderPluginItemType';
  checked: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  currentVariant: WebnodeOrderItemCurrentVariant;
  disabled: Scalars['Boolean']['output'];
  hash: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  info?: Maybe<Scalars['String']['output']>;
  kbArticleUrl?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  period: Scalars['String']['output'];
  showMoreText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variants: Array<WebnodeOrderItemVariantType>;
  variantsHeader: Scalars['String']['output'];
};

export type WebnodeOrderPrivateRegistrationItemType = WebnodeOrderItemTypeInterface & {
  __typename?: 'WebnodeOrderPrivateRegistrationItemType';
  checked: Scalars['Boolean']['output'];
  contact?: Maybe<DomainContact>;
  contactRequired: Array<Scalars['Boolean']['output']>;
  currency: Scalars['String']['output'];
  currentVariant: WebnodeOrderItemCurrentVariant;
  disabled: Scalars['Boolean']['output'];
  domain: Scalars['String']['output'];
  editPersonalInfoUrl: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  missingAnyPersonalData: Scalars['Boolean']['output'];
  period: Scalars['String']['output'];
  privateChangeDisabled: Scalars['Boolean']['output'];
  renew: Scalars['Boolean']['output'];
  supportsFreeVoucher: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variants: Array<WebnodeOrderItemVariantType>;
  variantsHeader: Scalars['String']['output'];
};

export type WebnodeOrderTypeEnum =
  | 'INITIAL'
  | 'RECURR'
  | 'SINGLE';

export type GetCustomCheckoutSettingsQueryVariables = Exact<{
  projectName: Scalars['String']['input'];
}>;


export type GetCustomCheckoutSettingsQuery = { __typename?: 'Query', customCheckoutSettings: { __typename?: 'CustomCheckoutSettingsType', digitalOrderBillingAddressRequired: boolean, showCompanyBilling: boolean, showDifferentShippingAddress: boolean, showNewsletterCheckbox: boolean, showVoucherInput: boolean, customCheckoutLogo?: { __typename?: 'ProjectImageData', filename: string, url: string } | null, phoneNumberFieldSettings: Array<{ __typename?: 'PhoneNumberFieldSettings', mode: FieldModeType, langId: number }>, customInputFieldSettings: Array<{ __typename?: 'CustomInputFieldSettings', label?: string | null, mode: FieldModeType, langId: number }>, customCheckboxFieldSettings: Array<{ __typename?: 'CustomCheckboxFieldSettings', label?: string | null, mode: FieldModeType, langId: number }>, customerNoteFieldSettings: Array<{ __typename?: 'CustomerNoteFieldSettings', label?: string | null, placeholder?: string | null, mode: FieldModeType, langId: number }> } };

export type UpdateShowVoucherInputMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
}>;


export type UpdateShowVoucherInputMutation = { __typename?: 'Mutation', updateShowVoucherInput: boolean };

export type UploadCustomCheckoutLogoMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['Upload']['input']>;
}>;


export type UploadCustomCheckoutLogoMutation = { __typename?: 'Mutation', uploadCustomCheckoutLogo?: { __typename?: 'ProjectImageData', filename: string, url: string } | null };

export type UpdateDigitalOrdersRequireBillingAddressMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
}>;


export type UpdateDigitalOrdersRequireBillingAddressMutation = { __typename?: 'Mutation', updateDigitalOrdersRequireBillingAddress: boolean };

export type UpdateShowCompanyBillingMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
}>;


export type UpdateShowCompanyBillingMutation = { __typename?: 'Mutation', updateShowCompanyBilling: boolean };

export type UpdatePhoneNumberFieldSettingsMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  fieldSettings: PhoneNumberFieldSettingsInput;
}>;


export type UpdatePhoneNumberFieldSettingsMutation = { __typename?: 'Mutation', updatePhoneNumberFieldSettings: boolean };

export type UpdateShowDifferentShippingAddressMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
}>;


export type UpdateShowDifferentShippingAddressMutation = { __typename?: 'Mutation', updateShowDifferentShippingAddress: boolean };

export type UpdateShowNewsletterCheckboxMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
}>;


export type UpdateShowNewsletterCheckboxMutation = { __typename?: 'Mutation', updateShowNewsletterCheckbox: boolean };

export type UpdateCustomInputFieldSettingsMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  fieldSettings: CustomInputFieldSettingsInput;
}>;


export type UpdateCustomInputFieldSettingsMutation = { __typename?: 'Mutation', updateCustomInputFieldSettings: boolean };

export type UpdateCustomCheckboxFieldSettingsMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  fieldSettings: CustomCheckboxFieldSettingsInput;
}>;


export type UpdateCustomCheckboxFieldSettingsMutation = { __typename?: 'Mutation', updateCustomCheckboxFieldSettings: boolean };

export type UpdateCustomerNoteFieldSettingsMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  fieldSettings: CustomerNoteFieldSettingsInput;
}>;


export type UpdateCustomerNoteFieldSettingsMutation = { __typename?: 'Mutation', updateCustomerNoteFieldSettings: boolean };

export type GetIubendaPluginsSettingsQueryVariables = Exact<{
  projectName: Scalars['String']['input'];
}>;


export type GetIubendaPluginsSettingsQuery = { __typename?: 'Query', iubendaSettings: { __typename?: 'IubendaSettingsType', privacyPolicySettings: Array<{ __typename?: 'PrivacyPolicySettings', langId: number, code: string, enabled: boolean }>, cookieBarSettings: Array<{ __typename?: 'CookieBarSettings', langId: number, code: string, enabled: boolean }>, cookiePolicySettings: Array<{ __typename?: 'CookiePolicySettings', langId: number, code: string, enabled: boolean }> } };

export type UpdateIubendaPrivacyPolicyMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  settings: PrivacyPolicySettingsInput;
}>;


export type UpdateIubendaPrivacyPolicyMutation = { __typename?: 'Mutation', updateIubendaPrivacyPolicy: boolean };

export type UpdateIubendaCookieBarMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  settings: CookieBarSettingsInput;
}>;


export type UpdateIubendaCookieBarMutation = { __typename?: 'Mutation', updateIubendaCookieBar: boolean };

export type UpdateIubendaCookiePolicyMutationVariables = Exact<{
  projectName: Scalars['String']['input'];
  settings: CookiePolicySettingsInput;
}>;


export type UpdateIubendaCookiePolicyMutation = { __typename?: 'Mutation', updateIubendaCookiePolicy: boolean };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const GetCustomCheckoutSettingsDocument = new TypedDocumentString(`
    query GetCustomCheckoutSettings($projectName: String!) {
  customCheckoutSettings(projectName: $projectName) {
    digitalOrderBillingAddressRequired
    showCompanyBilling
    showDifferentShippingAddress
    showNewsletterCheckbox
    showVoucherInput
    customCheckoutLogo {
      filename
      url
    }
    phoneNumberFieldSettings {
      mode
      langId
    }
    customInputFieldSettings {
      label
      mode
      langId
    }
    customCheckboxFieldSettings {
      label
      mode
      langId
    }
    customerNoteFieldSettings {
      label
      placeholder
      mode
      langId
    }
  }
}
    `) as unknown as TypedDocumentString<GetCustomCheckoutSettingsQuery, GetCustomCheckoutSettingsQueryVariables>;
export const UpdateShowVoucherInputDocument = new TypedDocumentString(`
    mutation UpdateShowVoucherInput($projectName: String!, $value: Boolean!) {
  updateShowVoucherInput(projectName: $projectName, value: $value)
}
    `) as unknown as TypedDocumentString<UpdateShowVoucherInputMutation, UpdateShowVoucherInputMutationVariables>;
export const UploadCustomCheckoutLogoDocument = new TypedDocumentString(`
    mutation UploadCustomCheckoutLogo($projectName: String!, $logo: Upload) {
  uploadCustomCheckoutLogo(projectName: $projectName, logo: $logo) {
    filename
    url
  }
}
    `) as unknown as TypedDocumentString<UploadCustomCheckoutLogoMutation, UploadCustomCheckoutLogoMutationVariables>;
export const UpdateDigitalOrdersRequireBillingAddressDocument = new TypedDocumentString(`
    mutation UpdateDigitalOrdersRequireBillingAddress($projectName: String!, $value: Boolean!) {
  updateDigitalOrdersRequireBillingAddress(
    projectName: $projectName
    value: $value
  )
}
    `) as unknown as TypedDocumentString<UpdateDigitalOrdersRequireBillingAddressMutation, UpdateDigitalOrdersRequireBillingAddressMutationVariables>;
export const UpdateShowCompanyBillingDocument = new TypedDocumentString(`
    mutation UpdateShowCompanyBilling($projectName: String!, $value: Boolean!) {
  updateShowCompanyBilling(projectName: $projectName, value: $value)
}
    `) as unknown as TypedDocumentString<UpdateShowCompanyBillingMutation, UpdateShowCompanyBillingMutationVariables>;
export const UpdatePhoneNumberFieldSettingsDocument = new TypedDocumentString(`
    mutation UpdatePhoneNumberFieldSettings($projectName: String!, $fieldSettings: PhoneNumberFieldSettingsInput!) {
  updatePhoneNumberFieldSettings(
    projectName: $projectName
    fieldSettings: $fieldSettings
  )
}
    `) as unknown as TypedDocumentString<UpdatePhoneNumberFieldSettingsMutation, UpdatePhoneNumberFieldSettingsMutationVariables>;
export const UpdateShowDifferentShippingAddressDocument = new TypedDocumentString(`
    mutation UpdateShowDifferentShippingAddress($projectName: String!, $value: Boolean!) {
  updateShowDifferentShippingAddress(projectName: $projectName, value: $value)
}
    `) as unknown as TypedDocumentString<UpdateShowDifferentShippingAddressMutation, UpdateShowDifferentShippingAddressMutationVariables>;
export const UpdateShowNewsletterCheckboxDocument = new TypedDocumentString(`
    mutation UpdateShowNewsletterCheckbox($projectName: String!, $value: Boolean!) {
  updateShowNewsletterCheckbox(projectName: $projectName, value: $value)
}
    `) as unknown as TypedDocumentString<UpdateShowNewsletterCheckboxMutation, UpdateShowNewsletterCheckboxMutationVariables>;
export const UpdateCustomInputFieldSettingsDocument = new TypedDocumentString(`
    mutation UpdateCustomInputFieldSettings($projectName: String!, $fieldSettings: CustomInputFieldSettingsInput!) {
  updateCustomInputFieldSettings(
    projectName: $projectName
    fieldSettings: $fieldSettings
  )
}
    `) as unknown as TypedDocumentString<UpdateCustomInputFieldSettingsMutation, UpdateCustomInputFieldSettingsMutationVariables>;
export const UpdateCustomCheckboxFieldSettingsDocument = new TypedDocumentString(`
    mutation UpdateCustomCheckboxFieldSettings($projectName: String!, $fieldSettings: CustomCheckboxFieldSettingsInput!) {
  updateCustomCheckboxFieldSettings(
    projectName: $projectName
    fieldSettings: $fieldSettings
  )
}
    `) as unknown as TypedDocumentString<UpdateCustomCheckboxFieldSettingsMutation, UpdateCustomCheckboxFieldSettingsMutationVariables>;
export const UpdateCustomerNoteFieldSettingsDocument = new TypedDocumentString(`
    mutation UpdateCustomerNoteFieldSettings($projectName: String!, $fieldSettings: CustomerNoteFieldSettingsInput!) {
  updateCustomerNoteFieldSettings(
    projectName: $projectName
    fieldSettings: $fieldSettings
  )
}
    `) as unknown as TypedDocumentString<UpdateCustomerNoteFieldSettingsMutation, UpdateCustomerNoteFieldSettingsMutationVariables>;
export const GetIubendaPluginsSettingsDocument = new TypedDocumentString(`
    query GetIubendaPluginsSettings($projectName: String!) {
  iubendaSettings(projectName: $projectName) {
    privacyPolicySettings {
      langId
      code
      enabled
    }
    cookieBarSettings {
      langId
      code
      enabled
    }
    cookiePolicySettings {
      langId
      code
      enabled
    }
  }
}
    `) as unknown as TypedDocumentString<GetIubendaPluginsSettingsQuery, GetIubendaPluginsSettingsQueryVariables>;
export const UpdateIubendaPrivacyPolicyDocument = new TypedDocumentString(`
    mutation UpdateIubendaPrivacyPolicy($projectName: String!, $settings: PrivacyPolicySettingsInput!) {
  updateIubendaPrivacyPolicy(projectName: $projectName, pluginSettings: $settings)
}
    `) as unknown as TypedDocumentString<UpdateIubendaPrivacyPolicyMutation, UpdateIubendaPrivacyPolicyMutationVariables>;
export const UpdateIubendaCookieBarDocument = new TypedDocumentString(`
    mutation UpdateIubendaCookieBar($projectName: String!, $settings: CookieBarSettingsInput!) {
  updateIubendaCookieBar(projectName: $projectName, pluginSettings: $settings)
}
    `) as unknown as TypedDocumentString<UpdateIubendaCookieBarMutation, UpdateIubendaCookieBarMutationVariables>;
export const UpdateIubendaCookiePolicyDocument = new TypedDocumentString(`
    mutation UpdateIubendaCookiePolicy($projectName: String!, $settings: CookiePolicySettingsInput!) {
  updateIubendaCookiePolicy(projectName: $projectName, pluginSettings: $settings)
}
    `) as unknown as TypedDocumentString<UpdateIubendaCookiePolicyMutation, UpdateIubendaCookiePolicyMutationVariables>;