import React from 'react';
import ActionLink from "../ActionLink";
import i18n from "i18next";
import PropTypes from 'prop-types';


export default function SocialFormSection({onClick, className})
{
	//text z BE, s blbym nahrazovacem
	const text = i18n.t('text[portal-2015][form][continueWith]', 'Continue with %service%')

	return <div className={className + ' cf'}>
		<div className={className + '-btn facebook'}>
			<ActionLink tabIndex="-1" onClick={() => onClick(SocialFormSection.SOCIAL_TYPE_FACEBOOK)}>
				<span>
					<span className="title-long">
						{text.replace('%service%', 'Facebook')}
					</span>
					<span className="title-short">Facebook</span>
				</span>
			</ActionLink>
		</div>
		<div className={className + '-btn google'}>
			<ActionLink tabIndex="-1" onClick={() => onClick(SocialFormSection.SOCIAL_TYPE_GOOGLE)}>
				<span>
					<span className="title-long">
						{text.replace('%service%', 'Google')}
					</span>
					<span className="title-short">Google</span>
				</span>
			</ActionLink>
		</div>
	</div>
}

SocialFormSection.propTypes = {
	onClick: PropTypes.func.isRequired,
};

SocialFormSection.SOCIAL_TYPE_FACEBOOK = 'Facebook';
SocialFormSection.SOCIAL_TYPE_GOOGLE = 'Google';