import {productionLog} from "./utils";

/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 28. 10. 2020 12:59
 */

const getCurrentTimestamp = () => (new Date()).getTime();
const START_TIME = getCurrentTimestamp();


// formát daný API a starým JS
export const getEventTrackingValue = (name, type) => [`${name}-${type}`, getCurrentTimestamp()];

// changeEvent si nakoenc vyřeší zvláť
export const inputTrackingEvents = (name, callback, handlers = {}, events = ['onBlur', 'onFocus']) => {
	const handler = (eventName, e) => {
		e.persist();
		callback(getEventTrackingValue(name, e.nativeEvent.type));
		if (handlers[eventName])
		{
			handlers[eventName](e);
		}
	};
	return events.reduce((acc, curr) => ({...acc, [curr]: (e) => handler(curr, e)}),{});
};

export const createTrackingDataString = (trackingDataArray) => {

	productionLog('Antispam Assembly: start');
	const NOT_AVAILABLE = 'NOT_AVAILABLE';

	productionLog('Antispam Assembly: step 1');
	const requests = trackingDataArray.map(([name, timestamp]) => [name, timestamp - START_TIME]);

	productionLog('Antispam Assembly: step 2');
	const errorAbleData = getErrorAbleData();
	productionLog('Antispam Assembly: step 3');
	const navigatorData = window.navigator ? {
		'language': window.navigator.language
			|| window.navigator.userLanguage
			|| window.navigator.browserLanguage
			|| window.navigator.systemLanguage
			|| NOT_AVAILABLE,
		'deviceMemoryKey': window.navigator.deviceMemory || NOT_AVAILABLE,
		'NavigatorCpuClass': window.navigator.cpuClass || NOT_AVAILABLE,
	} : {};



	productionLog('Antispam Assembly: step 4');
	const trackingData = JSON.stringify({
		'request_time': START_TIME,
		'requests': requests,
		...errorAbleData,
		// tyhle byly puvodně v t2, ale fuckit, vykreslovani reactem dělá t2 nepodstatný
		...navigatorData,
		'colorDepth': window.screen && window.screen.colorDepth || NOT_AVAILABLE,
		'hasLocalStorage': !!window.localStorage,
	});

	productionLog('Antispam Assembly: step 5');

	let result = '';
	try {
		// fakt nevim, nějaký magic string z cisla na base 36
		const obfuscatingHack = Math.random().toString(36);
		const base64String = window.btoa(obfuscatingHack + '|' + trackingData);
		// checksum-ish záležitost
		result = base64String + window.btoa(base64String).substr(0, 8);
	}
	catch (e)
	{
		productionLog('Antispam Assembly: failed');
		productionLog(e);
	}

	productionLog('Antispam Assembly: done');
	return result;
}


/**
 * Todo zjistit co je odtud možno vyhazet (bohužel se to špatně testuje, ale např canvas je podle mě k hovnu)
 * @return {{}}
 */
function getErrorAbleData()
{
	const result = {};
	try {
		let plugins = [];
		// navigator.plugins je itterable ale ne pole, tedy nejde map
		for (let plugin of window.navigator.plugins)
		{
			// encodeURIComponent by se mělo zbavit problému btoa a utf8 znaků
			plugins.push({
				'name': encodeURIComponent(plugin.name),
				'description': encodeURIComponent(plugin.description),
				'filename': encodeURIComponent(plugin.filename)
			})
		}
		// čitelná verze sortu z UserTracker.js
		plugins.sort((a, b) => {
			const nameA = a['name'].toUpperCase();
			const nameB = b['name'].toUpperCase();
			return (nameA > nameB) - (nameB > nameA);
		});

		result['plugins'] = plugins;

		// rozliseni a barevna hloubka
		result['resolution'] = window.screen.width+'x'+window.screen.height+'x'+window.screen.colorDepth;

		// timezone
		result['timezone'] = new Date().getTimezoneOffset();

		// browser info
		result['browser'] = {};
		result['browser']['codename'] = window.navigator.appCodeName;
		result['browser']['name'] = window.navigator.appName;
		result['browser']['version'] = window.navigator.appVersion;
		result['browser']['enabled'] = window.navigator.cookieEnabled;
		result['browser']['language'] = window.navigator.language;
		result['browser']['online'] = window.navigator.onLine;
		result['browser']['platform'] = window.navigator.platform;
		result['browser']['ua_header'] = window.navigator.userAgent;
		result['browser']['ua_language'] = window.navigator.systemLanguage;

		// pro jistotu testnem btoa resultu, takže se tyhle data zahodí, pokud v nich je chyba co by to schodila
		window.btoa(JSON.stringify(result));
	}
	catch (e)
	{
		result['errors'] = [e];
	}

	try
	{
		const c = document.createElement("CANVAS");
		const ctx = c.getContext('2d');
		const txt = 'Ows1trd čö46 _´tˇh Ro/f'; // uchylny text z UserTracker
		ctx.textBaseline = 'top';
		ctx.font = "14px 'Arial'";
		ctx.textBaseline = 'alphabetic';
		ctx.fillStyle = '#f60';
		ctx.fillRect(125,1,62,20);
		ctx.fillStyle = '#069';
		ctx.fillText(txt, 2, 15);
		ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
		ctx.fillText(txt, 4, 17);
		result['canvas'] = c.toDataURL();
	}
	catch (e)
	{
		result['errors'] = [...(result['errors'] || []), e];
	}
	return result;
}