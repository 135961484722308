import React from 'react';
import PropTypes from 'prop-types';
import i18n from "i18next";
import NonStandardModal from "../../components/Modal/NonStandardModal";
import SocialFormSection from "../../components/Ui/Layout/SocialFormSection";
import {HiddenInputs, RegisterHintTooltip, Step2Text, TermsOfUse} from "./RegisterParts";
import Trans from "../../components/Ui/Trans";
import {EmailField, FullNameField, PasswordField} from "./inputs";
import ReCaptchaV2 from "../../components/Ui/ReCaptcha/ReCaptchaV2";

export default class RegisterModalContent extends React.Component {


	handleSubmit = (e) => {
		e.preventDefault();
		this.props.onSubmit();
	}


	render()
	{
		return (
			<NonStandardModal.Content
				className="form-reg form-reg-project-name-and-credentials form-reg-wide modal-box"
				opened={this.props.opened} loading={this.props.loading}

			>
				<NonStandardModal.Form
					onSubmit={this.handleSubmit}
					className="form modal-box-content"
					onClose={this.props.onClose}
				>
					<h3>{i18n.t('text[portal-2015][claim][registrationForm][header]', 'Create your website')}</h3>

					<div className="form-reg-container">
						<FullNameField
							id="registrantFullName"
							onChange={this.props.onChange}
							data={this.props.inputs.fullname}
							projectMaxLength={this.props.projectMaxLength}
							onTrack={this.props.trackEvent}
							label={i18n.t('text[portal-2015][registration][step1]', '1. Insert your webpage name')}
							placeholder={i18n.t('text[signup][registrantFullName]', 'Website name')}
							hint={
								// tohle je hack, kvuli tomu, že starý design popupu nesedí s novým tooltipem
								<RegisterHintTooltip text={Array(5).fill('\u00A0').join('')}/>
							}
							className={'form-input nrm'}
							classic={false}
							setError={this.props.setError}
						/>
					</div>

					<Step2Text/>

					<div className="form-log-container">
						<SocialFormSection className="form-log-social" onClick={this.props.onSocial}/>

						<div className="form-log-classic">

							<div className="form-log-delimiter">
								<h5>
									<span>
										{i18n.t('text[portal-2015][registration][orEmail]', 'or with your email')}
									</span>
								</h5>
							</div>
							<EmailField
								id="signupUserEMail"
								classic={false}
								onChange={this.props.onChange}
								onTrack={this.props.trackEvent}
								data={this.props.inputs.email}
								setError={this.props.setError}
								defaultAtChar={true}
							/>
							<PasswordField
								id="signupUserPwd"
								classic={false}
								onChange={this.props.onChange}
								onTrack={this.props.trackEvent}
								data={this.props.inputs.password}
								setError={this.props.setError}
								label={<Trans
									i18nKey={'text[portal-2015][registration][passwordLabel]'}
									components={{span: <span/>}}
									defaults={'Password<span>&nbsp;(At least 6 characters)</span>'}
								/>}
							/>

							<div className="cleaner boxSpacer"/>
							{
								this.props.captchaChallenge &&
								<ReCaptchaV2
									onChange={response => this.props.onChange('g-recaptcha-response', response)}
								/>
							}
							<div className="form-submit">
								<input
									type="submit" tabIndex="0"
									value={i18n.t('text[portal-2015][registration][submit]', 'Register & Create')}
									name="register"
								/>
							</div>
						</div>
					</div>

					<div className="form-log-terms-of-use">
						<TermsOfUse termsAndConsUrl={this.props.termsAndConsUrl} privacyUrl={this.props.privacyUrl}/>
					</div>

					<HiddenInputs />
				</NonStandardModal.Form>
			</NonStandardModal.Content>
		);
	}
}

RegisterModalContent.propTypes = {
	termsAndConsUrl: PropTypes.string.isRequired,
	privacyUrl: PropTypes.string.isRequired,
	onSocial: PropTypes.func.isRequired,
	setError: PropTypes.func,
	opened: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	projectMaxLength: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	trackEvent: PropTypes.func.isRequired,

	inputs: PropTypes.shape({
		fullname: PropTypes.shape({value: PropTypes.string, error: PropTypes.string}).isRequired,
		email: PropTypes.shape({value: PropTypes.string, error: PropTypes.string}).isRequired,
		password: PropTypes.shape({value: PropTypes.string, error: PropTypes.string}).isRequired,
	}).isRequired,

	captchaChallenge: PropTypes.bool,
};