import React from 'react';
import PropTypes from 'prop-types';
import Footer from "./Footer";
import Navline from "./Navline/Navline";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "./actions";
import i18n from "i18next";
import RegisterLoginModal from "../../Modules/RegisterLoginModal/RegisterLoginModal";
import WebnodeLogo from "../../components/Ui/Layout/WebnodeLogo";
import {getStaticData} from '../../utils/utils';

/**
 * TODO asi taky přesunout do Modules
 */
class Layout extends React.Component {

	static defaultProps = {
		top: null,
		tabs: null,
		showFooter: true,
		showFooterColumns: false,
		showCreateWebsite: true,
		showUserLogin: true,
		showHelp: false,
		navlineRole: Navline.ROLE_NONE,
	}

	componentDidMount()
	{
		this.props.initPage(getStaticData());
	}

	/**
	 * Vykreslí defaultni "taby" navline, pokud se ji nepředají jiné
	 *
	 * pokud je uživatel přihlášen vypisuje se "My projects", pokud je vybraný projekt tak i ten, a dál potom zadané breadcrumbs
	 */
	renderDefaultTabs = () => (
		<ul>
			<li>
				<a href={this.props.featuresUrl}>{i18n.t('text[portal-2019][claim][nav][features]', 'Features')}</a>
			</li>
			<li>
				<a href={this.props.templatesUrl}>{i18n.t('text[portal-2019][claim][nav][templates]', 'Templates')}</a>
			</li>
			<li>
				<a href={this.props.pricelistUrl}>{i18n.t('text[portal-2019][claim][nav][premium]', 'Premium services')}</a>
			</li>
			<li>
				<a href={this.props.supportUrl} target="_blank">{i18n.t('text[portal-2019][claim][nav][support]', 'Support')}</a>
			</li>
			<li>
				<a href={this.props.blogUrl} target="_blank">{i18n.t('text[portal-2019][claim][nav][blog]', 'Webnode Blog')}</a>
			</li>
		</ul>
	);

	/**
	 * Vykreslí layout
	 *
	 * @returns {JSX.Element}
	 */
	render()
	{
		return (
			<>
				{this.props.top}
				<Navline
					role={this.props.navlineRole}

					showCreateWebsite={this.props.showCreateWebsite}
					showUserLogin={this.props.showUserLogin}
					showHelp={this.props.showHelp}

					homepageUrl={this.props.homepageUrl}
					supportUrl={this.props.supportUrl}
					myProjectsUrl={this.props.menuData.myProjectsUrl}
					logoutUrl={this.props.menuData.logoutUrl}


					logo={this.props.logo || <WebnodeLogo/>}

					userIsLoggedIn={this.props.userIsLoggedIn}
					userName={this.props.menuData.userName}
					userEmail={this.props.menuData.userEmail}
					userMenuItems={this.props.menuData.userMenu}
					onCreateWebsiteClick={this.props.openRegisterModal}
					onLoginClick={this.props.openLoginModal}



					isPartner={this.props.isPartner}
					project={this.props.menuData.project}
				>
					{this.props.tabs ?? this.renderDefaultTabs()}
				</Navline>


				{this.props.children}

				{
					!this.props.isPartner &&
						<>
							{this.props.showFooter &&
							<Footer
								{...this.props.footer}
								activeCategory={this.props.activeCategory}
								// na dashboardových stránkách sloupce nezobrazujeme, jinde ano
								showColumns={this.props.showFooterColumns}
							/>
							}

							{this.props.modal.section && this.props.modal.section.startsWith('RegisterLoginModal/') &&
							<RegisterLoginModal
								termsAndConsUrl={this.props.termsAndConsUrl}
								upgradeNotificationUrl={this.props.upgradeNotificationUrl}
								privacyUrl={this.props.privacyUrl}
								maxProjectNameLength={this.props.maxProjectNameLength}
								forgotPasswordUrl={this.props.forgotPasswordUrl}
								socialUrl={this.props.socialUrl}
								loginUrl={this.props.loginUrl}
								registerUrl={this.props.registerUrl}
								userIsLoggedIn={this.props.userIsLoggedIn}

								jsAntiSpamData={this.props.jsAntiSpamData}
								captchaChallenge={this.props.captchaChallenge}

								{...this.props.modal}
							/>
							}
						</>
				}
			</>
		);
	}
}

Layout.propTypes = {
	top: PropTypes.node,
	showCreateWebsite: PropTypes.bool,
	showUserLogin: PropTypes.bool,
	showHelp: PropTypes.bool,
	showFooter: PropTypes.bool,
	showFooterColumns: PropTypes.bool,

	// z reduceru
	projectId: PropTypes.string,
	projectName: PropTypes.string,
	myProjectsAction: PropTypes.string,
	upgradeNotificationUrl: PropTypes.string,
	pages: PropTypes.arrayOf(PropTypes.object),

	userName: PropTypes.string,
	userIsLoggedIn:  PropTypes.bool.isRequired,
	userIsMailReader: PropTypes.bool,

	menuData: PropTypes.exact({
		myProjectsUrl: PropTypes.string,
		logoutUrl: PropTypes.string,
		userName: PropTypes.string,
		userEmail: PropTypes.string,
		project: PropTypes.any,
		userMenu: PropTypes.any,
	}),

	addProjectModal: PropTypes.exact({
		error: PropTypes.string,
		value: PropTypes.string,
		trackingData: PropTypes.array,
		jsAntiSpamData: PropTypes.any,
	}),


	termsAndConsUrl:  PropTypes.string,
	privacyUrl:  PropTypes.string,
	maxProjectNameLength:  PropTypes.number,
	forgotPasswordUrl:  PropTypes.string,
	socialUrl:  PropTypes.string,
	loginUrl:  PropTypes.string,
	homepageUrl: PropTypes.string,
	submitTicketUrl: PropTypes.string,
	myProjectsUrl: PropTypes.string,
	mailboxUrl: PropTypes.string,
	featuresUrl:  PropTypes.string,
	templatesUrl:  PropTypes.string,
	pricelistUrl:  PropTypes.string,
	supportUrl:  PropTypes.string,
	blogUrl:  PropTypes.string,
	registerUrl:  PropTypes.string,

	jsAntiSpamData: PropTypes.any,
	captchaChallenge: PropTypes.string,

	footer: PropTypes.object,
	modal: PropTypes.object,

	tabs: PropTypes.object,

	isPartner: PropTypes.bool.isRequired,
};


export default connect(
	(state, ownProps) => ({...state.layout, ...ownProps}),
	(dispatch) => bindActionCreators(actions, dispatch)
)(Layout)

