import * as modal from "../../components/Modal/actions";
import {FormRequest} from "../../utils/FormRequest";
import {createTrackingDataString} from "../../utils/tracking";
import {productionLog} from "../../utils/utils";
import * as Validator from "../../utils/validators";
import queries from './queries';
import RecaptchaApi from "../../components/Ui/ReCaptcha/RecaptchaApi";

export const LOGIN_CHANGE_INPUT = 'LOGIN_CHANGE_INPUT';
export const LOGIN_TRACK_INPUT = 'LOGIN_TRACK_INPUT';
export const LOGIN_SET_ERROR = 'LOGIN_SET_ERROR';
export const LOGIN_SET_DATA = 'LOGIN_SET_DATA';


export function changeInput(name, value)
{
	// prefetch reCaptchaV3 info result
	if (name === 'email' && Validator.email((value + '').trim()))
	{
		queries.shouldExecuteReCaptchaV3(value)
	}


	return {type: LOGIN_CHANGE_INPUT, name, value};
}

export function setError(name, error)
{
	return {type: LOGIN_SET_ERROR, name, error};
}

export const setData = (data) => ({type: LOGIN_SET_DATA, data});

export const send = (url, formState) => (dispatch) =>
{
	dispatch(modal.setLoading(true));

	return checkRecaptchaV3Token(formState.inputs.email?.value).then(token => {
		productionLog('Login: prepare-request');
		const request = new FormRequest(url, {
			...FormRequest.inputsToData(formState.inputs),
			...formState.data,
			trackingData: createTrackingDataString(formState.trackingData),
			reCaptchaV3Token: token,
		});
		productionLog('Login: send-request');
		request.send();
	});
}

export const sendSocial = (url, type, formState) => (dispatch) =>
{
	dispatch(modal.setLoading(true));
	productionLog('login: prepare-request');
	const request = new FormRequest(url, {
		...formState.data,
		...FormRequest.inputsToData(formState.inputs),
		socialAction: 'sign-in',
		application: type,
		trackingData: createTrackingDataString(formState.trackingData),
		facebookTest: window.location.search.includes('facebook-test') ? 1 : 0,
		redirectUri: formState.redirectUri,
	})
	productionLog('Login: send-request');
	request.send();
}

export const trackEvent = (trackingEvent) => ({type: LOGIN_TRACK_INPUT, trackingEvent});


async function checkRecaptchaV3Token(email)
{
	email = email + '';
	productionLog('Login: should execute recaptchaV3');
	if (Validator.email((email + '').trim()) && await queries.shouldExecuteReCaptchaV3(email))
	{
		productionLog('Login: recaptchaV3');
		return await RecaptchaApi.executeV3WhenReady(RecaptchaApi.ACTION_LOGIN);
	}
	return undefined;
}