import React from 'react';
import NonStandardModal from "../../components/Modal/NonStandardModal";
import PropTypes from "prop-types";
import i18n from "i18next";
import SocialFormSection from "../../components/Ui/Layout/SocialFormSection";
import {TermsOfUse} from "./LoginParts";
import {EmailField, PasswordField} from "../Register/inputs";
import ReCaptchaV2 from "../../components/Ui/ReCaptcha/ReCaptchaV2";
import UpgradeNotificationShoutBoard from '../../components/Ui/SystemNotifications/UpgradeNotificationShoutBoard.jsx';


export default class LoginModalContent extends React.Component {

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.onSubmit();
	}

	render()
	{
		return (
			<NonStandardModal.Content className="modal-box form-log-credentials form-log-wide form-log" opened={this.props.opened}
									  loading={this.props.loading}>
				<NonStandardModal.Form
					onSubmit={this.handleSubmit}
					className="form modal-box-content"
					onClose={this.props.onClose}
				>
					<h3>{
						i18n.t('text[portal-2015][loginPage][main][title]', 'Login')
					}</h3>
					<div className="form-log-container">

						<SocialFormSection onClick={this.props.onSocial} className="form-log-social"/>

						<div className="form-log-classic">
							<EmailField
								id="signInName"
								classic={false}
								onChange={this.props.onChange}
								data={this.props.inputs.email}
								onTrack={this.props.trackEvent}
							/>
							<PasswordField
								id="signInPassword"
								classic={false}
								onChange={this.props.onChange}
								onTrack={this.props.trackEvent}
								data={this.props.inputs.password}
								label={i18n.t('text[portal-2015][loginPage][form][passwordTitle]', 'Password')}
							/>
							<div className="form-text">
								<a className="link" href={this.props.forgotPasswordUrl}>
									{i18n.t('text[portal-2015][loginPage][form][passwordForgot]', 'Forgot your password?')}
								</a>
							</div>

							{
								this.props.captchaChallenge &&
								<ReCaptchaV2
									onChange={response => this.props.onChange('g-recaptcha-response', response)}
								/>
							}
							<div className="form-submit">
								<input tabIndex="0"
									   type="submit"
									   value={i18n.t('text[portal-2015][loginPage][form][submitButton]', 'Login')}
									   name="login"
								/>
							</div>
						</div>
					</div>

					<div className="form-log-terms-of-use">
						{
							this.props.upgradeNotificationUrl &&
							<UpgradeNotificationShoutBoard kbLink={this.props.upgradeNotificationUrl}/>
						}
						<TermsOfUse termsAndConsUrl={this.props.termsAndConsUrl} privacyUrl={this.props.privacyUrl}/>
					</div>
				</NonStandardModal.Form>
			</NonStandardModal.Content>
		);
	}
}

LoginModalContent.propTypes = {
	loading: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	forgotPasswordUrl: PropTypes.string.isRequired,
	upgradeNotificationUrl: PropTypes.string,
	termsAndConsUrl: PropTypes.string.isRequired,
	privacyUrl: PropTypes.string.isRequired,
	opened: PropTypes.bool.isRequired,
	onSocial: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	trackEvent: PropTypes.func.isRequired,

	inputs: PropTypes.shape({
		email: PropTypes.shape({value: PropTypes.string, error:PropTypes.string}).isRequired,
		password: PropTypes.shape({value: PropTypes.string, error:PropTypes.string}).isRequired,
	}).isRequired,

	captchaChallenge: PropTypes.bool,
};