import React from 'react';
import ReactDOM from "react-dom";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from "i18next";
import ActionLink from "../Ui/ActionLink";

/**
 * Rovnou vytvářím jako deprecated, nemělo by to být používáno, mimo přepis starých modalů co nejdou předesignovat
 * @deprecated
 */
export default class NonStandardModal extends React.Component {


	constructor(props, context)
	{
		super(props, context);
	}

	/**
	 * Před vytvoření komponenty
	 */
	componentDidMount()
	{
		window.document.body.classList.add(this.props.bodyActiveClass);
		window.document.addEventListener('keydown', this.handleKeyDown);

		// this.updateDimensions();
	}

	/**
	 * Před ztušením komponenty
	 */
	componentWillUnmount()
	{
		window.document.body.classList.remove(this.props.bodyActiveClass);
		window.document.removeEventListener('keydown', this.handleKeyDown);
	}

	/**
	 * Zpracování udalosti pri kliknutí na klávesnici
	 * @param event
	 */
	handleKeyDown = (event) =>
	{
		// prechod na predchozi | nasledujici sipkami na klavesnici | zavření
		switch(event.keyCode)
		{
			case 27: // esc
				this.props.onEsc ? this.props.onEsc(event) : this.props.onClose();
				break;
			case 37: // left
			case 38: // up
				this.props.onPrev ? this.props.onPrev(event) : null;
				break;
			case 39: // right
			case 40: // down
				this.props.onNext ? this.props.onNext(event) : null;
				break;

			default:
				this.props.onKeyDown ? this.props.onKeyDown(e) : null;
				break;
		}
	}

	render()
	{
		let opened = false;
		for (let element of React.Children.toArray(this.props.children))
		{
			if (React.isValidElement(element)){
				opened = !!element.props.opened;
				break;
			}
		}

		// vyrenderuje do body
		return ReactDOM.createPortal((
			<div className="modal">
				<div className="modal-content">
					<div
						className="modal-overlay"
						onClick={this.props.onClose}
					/>
					{this.props.children}
				</div>
			</div>
		), document.body);
	}
}

NonStandardModal.defaultProps = {
	bodyActiveClass: 'active'
}

NonStandardModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onEsc: PropTypes.func,
	onPrev: PropTypes.func,
	onNext: PropTypes.func,
	onKeyDown: PropTypes.func,
	bodyActiveClass: PropTypes.string
};

NonStandardModal.Content = function NonStandardModal_Content(
	{
		children,
		className = '',
		opened = false,
		loading = false,
		...props
	}
)
{
	return (
		<div className={classNames(className, {'open': opened, 'wait': loading})} {...props}>
			{children}
		</div>
	);
}


NonStandardModal.Content.propTypes = {
	className: PropTypes.string,
	opened: PropTypes.bool,
	loading: PropTypes.bool,
}

NonStandardModal.Loading = function NonStandardModal_Loading()
{
	return (
		<div className="form-wait">
			<div className="svg-animation"/>
			<h3>{i18n.t('text[webnode][__global_loading__]', 'Wait please...')}</h3>
		</div>
	);
}

NonStandardModal.Close = function NonStandardModal_Close({onClose})
{
	return (
		<ActionLink className="modal-close" onClick={onClose}>
			<span>
				{i18n.t('text[portal-2015][claim][side][close]', 'Close')}
			</span>
		</ActionLink>
	);
}

NonStandardModal.Close.propTypes = {
	onClose: PropTypes.func.isRequired,
}


NonStandardModal.Form =  function NonStandardModal_Form({children, onClose, onSubmit,...props})
{
	return (
		<form {...props} onSubmit={onSubmit}>
			{children}
			<NonStandardModal.Close onClose={onClose}/>
			<NonStandardModal.Loading/>
		</form>
	);
}
