import React from 'react';
import PropTypes from 'prop-types';
import {Transition} from "react-transition-group";
import {isObject} from "../../../utils/utils";
import exact from 'prop-types-exact';


/**
 * Komponenta umožní provést transition bez obalení extra elementem a s autodetekcí display/height
 * Komponenta simulující chování Velocity component (aktuálně NENÍ vhodná k použití v TransitionGroup)
 */
export default class UnwrappedTransition extends React.Component{
    static DEFAULT_DURATION = 250;
    static INTERRUPT_STOP = 'stop';
    static INTERRUPT_WAIT = 'wait';
    static PRE_DEFINED_TIMING_FUNCTIONS = {
        swing: 'cubic-bezier(.02, .01, .47, 1)',
    };
    static defaultProps = {
        duration: UnwrappedTransition.DEFAULT_DURATION,
        transitionProps: {},
        defaultStyle: {},
        interruptBehavior: UnwrappedTransition.INTERRUPT_STOP, // stop, wait
        wrapperElement: undefined,
        wrapperProps: {},
        easing: 'swing',
        transitionProperty: 'all',
    }

    constructor(props, context) {
        super(props, context);
        this.elRef = this.props.forwardRef ?? React.createRef();
        this.maxHeightRef = this.props.maxHeightRef ?? React.createRef();
        this.maxHeightRef.current = this.maxHeightRef.current ?? 1000;
        this.maxWidthRef = this.props.maxWidthRef ?? React.createRef();
        this.maxWidthRef.current = this.maxWidthRef.current ?? 1000;
        this.state = {transitionIn: this.props.transitionIn};
        this.isAnimationInProggress = false;
    }

    componentDidMount()
    {
        if (!this.elRef.current || !this.elRef.current.tagName)
        {
            throw new Error('SmartTransition Child should be DOM and referenceable and accept style property (try using wrapper props)');
        }
        this.maxHeightRef.current = this.elRef.current.scrollHeight;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.transitionIn !== this.props.transitionIn)
        {
            if (this.canStartAnimationNow())
            {
                this.isAnimationInProggress = true;
                this.setState({transitionIn: this.props.transitionIn});
            }
        }
        if (!this.elRef.current || !this.elRef.current.tagName)
        {
            throw new Error('SmartTransition Child should be DOM and referenceable and accept style property (try using wrapper props)');
        }
        // výšku přebírá jen pokud by měl být element vidět (aby se nezebrala velikost při display:none)
        if (this.props.transitionIn)
        {
            this.maxHeightRef.current = this.elRef.current.scrollHeight;
            const rect = this.elRef.current.getBoundingClientRect();
            this.maxWidthRef.current = rect.right - rect.left;
        }
    }

    canStartAnimationNow()
    {
        return !this.isAnimationInProggress || this.props.interruptBehavior === UnwrappedTransition.INTERRUPT_STOP;
    }

    getDisplayType() {
        if (!this.elRef.current)
        {
            return 'block';
        }

        const tagName = this.elRef.current.tagName.toString().toLowerCase();
        if (/^(b|big|i|small|tt|abbr|acronym|cite|code|dfn|em|kbd|strong|samp|var|a|bdo|br|img|map|object|q|script|span|sub|sup|button|input|label|select|textarea)$/i.test(tagName))
        {
            return "inline";
        }
        else if (/^(li)$/i.test(tagName))
        {
            return "list-item";
        }
        else if (/^(tr)$/i.test(tagName))
        {
            return "table-row";
        }
        else if (/^(table)$/i.test(tagName))
        {
            return "table";
        }
        else if (/^(tbody)$/i.test(tagName))
        {
            return "table-row-group";
        }
        else
        {
            return "block";
        }
    }

    handleEntered = (element) => {
        this.handleComplete(element)
        this.props.onEntered && this.props.onEntered(element || this.elRef.current);
    }

    handleExited = (element) => {
        this.handleComplete(element)
        this.props.onExited && this.props.onExited(element || this.elRef.current);
    }

    handleComplete = (element) =>
    {
        element = element || this.elRef.current;
        if (this.props.onComplete)
        {
            this.props.onComplete(element);
        }
        // přechod zůstane v progresu, pokud je hned proveditelná další animace
        this.isAnimationInProggress = this.props.transitionIn !== this.state.transitionIn;
        // tato animace se pak provede
        if (this.isAnimationInProggress)
        {
            this.setState({transitionIn: this.props.transitionIn});
        }

    }

    getEasing = () => UnwrappedTransition.PRE_DEFINED_TIMING_FUNCTIONS[this.props.easing] ?? this.props.easing;

    unPackTransitionStyle(transitionStyle)
    {
        let style = {};
        for (let [key, value] of Object.entries(transitionStyle))
        {
            if (!isObject(value))
            {
                style[key] = value;
            }
            else if (value.current)
            {
                style[key] = value.current;
            }
            else
            {
                style[key] = this.unPackTransitionStyle(value);
            }
        }
        return style;
    }

    render() {
        const {
            children:child,
            duration,
            transitionProps,
            transitionProperty,
            transitionStyles:ts,
            defaultStyle:ds,
        } = this.props;

        if (!React.Children.only(child))
        {
            throw new Error('SmartTransition can have only ONE direct child (you can use wrapper props)');
        }
        const defaultStyle = {
            ...ds,
            transitionDuration: `${duration}ms`,
            transitionTimingFunction: this.getEasing(),
            transitionProperty: transitionProperty,
        }
        const display = this.getDisplayType();
        const transitionStyles = {
            entering: { ...ts.entering, display,},
            entered:  { ...ts.entered, display,},
            exiting:  { ...ts.exiting, display,},
            // display jen pokud se má otevřít, aby se provedla a zpočítala výška (jinak není display třeba)
            // animace (zpětně kompatibilní s velocity verzí)
            exited:  { ...ts.exited, ...(this.props.transitionIn ? {display}: {}) },
        };

        const Wrapper = this.props.wrapperElement;


        return (
            <Transition
                in={this.state.transitionIn}
                timeout={duration}
                onEntered={this.handleEntered}
                onExited={this.handleExited}
                {...transitionProps}
            >
                {state => {
                    const childProps = {
                        ref: this.elRef,
                        style: {
                            ...child.style,
                            ...defaultStyle,
                            ...this.unPackTransitionStyle(transitionStyles[state])
                        }
                    }
                    return (
                        Wrapper
                            ? <Wrapper {...this.wrapperProps} {...childProps}/>
                            : React.cloneElement(child, childProps)
                    );

                }}
            </Transition>
        );
    }
}


UnwrappedTransition.propTypes = exact({
    transitionIn: PropTypes.bool.isRequired,
    transitionStyles: PropTypes.exact({
        entering: PropTypes.object.isRequired,
        entered: PropTypes.object.isRequired,
        exiting: PropTypes.object.isRequired,
        exited: PropTypes.object.isRequired,
    }).isRequired,
    defaultStyle: PropTypes.object,
    duration: PropTypes.number,
    onComplete: PropTypes.func,
    onEntered: PropTypes.func,
    onExited: PropTypes.func,
    forwardRef: PropTypes.object,
    interruptBehavior: PropTypes.oneOf([UnwrappedTransition.INTERRUPT_STOP, UnwrappedTransition.INTERRUPT_WAIT]),

    transitionProps: PropTypes.object,

    wrapperElement: PropTypes.string,
    wrapperProps: PropTypes.object,

    easing: PropTypes.string,
    transitionProperty: PropTypes.string,
    maxHeightRef: PropTypes.object,
    maxHeightWidth: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node), //pro pole je potřeba použít wrapperElement
        PropTypes.node
    ]).isRequired
})
