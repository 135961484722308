export const deepEqual = (x, y) => {
    // rovnají se pointery, nebo se rovnají skalárně
    if (x === y)
    {
        return true;
    }
    // jedná se o pole
    if (Array.isArray(x) || Array.isArray(y))
    {
        // jsou to pole stejné délky
        if (Array.isArray(x) && Array.isArray(y) && x.length === y.length)
        {
            for (let i = 0; i < x.length; i++)
            {
                // jeden item se nerovná -> nerovná se celé
                if(!deepEqual(x[i], y[i]))
                {
                    return false;
                }
            }
            return true;
        }
        // jinak se nerovnají
        return false;
    }
    // jsou ro objekty
    else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null))
    {
        // jsou to objekty se stejným množstvím prvků
        if (Object.keys(x).length !== Object.keys(y).length)
        {
            return false;
        }

        for (let prop in x)
        {
            // pokud se jeden klíč nerovná tak false
            if (x.hasOwnProperty(prop) && y.hasOwnProperty(prop))
            {
                if (!deepEqual(x[prop], y[prop]))
                {
                    return false;
                }
            }
            else
            {
                return false;
            }
        }

        return true;
    }
    else
    {
        return false;
    }
};

export const deepClone = (obj) => {
    // skalár vrátím
    if (!obj || typeof obj !== 'object')
    {
        return obj;
    }

    if (Array.isArray(obj))
    {
        return obj.map(deepClone);
    }

    return Object.keys(obj).reduce((acc, curr) => (acc[curr] = deepClone(obj[curr]), acc),{})
}


export function objectsDiffer(objectA, objectB, level = 1)
{
    // zastavující podmínka
    if (
        // poslední iterace
        level === 0 ||
        // jeden z nich je faly, tedy skalár nebo null
        !objectA || !objectB ||
        // jeden z nich není objekt
        typeof objectA !== 'object'|| typeof objectB !== 'object'
    )
    {
        return objectA !== objectB;
    }
    return Object.keys(objectA).reduce(
        (acc, key) => acc || objectsDiffer(objectA[key], objectB[key], level - 1),
        false
    )
}

// @see http://andrewdupont.net/2009/08/28/deep-extending-objects-in-javascript/
export function deepExtend(destination, source) {
    for (let property in source) {
        if (
            source[property] && source[property].constructor &&
            source[property].constructor === Object
        )
        {
            destination[property] = destination[property] || {};
            deepExtend(destination[property], source[property]);
        } else {
            destination[property] = source[property];
        }
    }
    return destination;
}
