import React from 'react';
import classNames from 'classnames';
import ActionLink from "./ActionLink";
import PropTypes from 'prop-types';
import {isString} from "../../utils/utils";
import Collapsible from "./Accordion/Collapsible";

/**
 * Reusable Shoutboard component
 */
export default class ShoutBoard extends React.Component {

	static duration = 250;

	static SCHEME_1 = '1';
	static SCHEME_2 = '2';
	static SCHEME_3 = '3';
	static SCHEME_4 = '4';
	static SCHEME_5 = '5';
	static SCHEME_6 = '6';
	static SCHEME_7 = '7';

	static SCHEME_SUCCESS = 'SUCCESS';
	static SCHEME_INFO = 'INFO';
	static SCHEME_WARNING = 'WARNING';
	static SCHEME_ALERT = 'ALERT';

	static SCHEME_MAP = {
		[ShoutBoard.SCHEME_WARNING] : {
			scheme: '3',
			iconClassName: 'type-ico ico-warning',
		},
		[ShoutBoard.SCHEME_INFO] : {
			scheme: '4',
			iconClassName: 'type-ico ico-info',
		},
		[ShoutBoard.SCHEME_SUCCESS] : {
			scheme: '5',
			iconClassName: 'type-ico ico-check',
		},
		[ShoutBoard.SCHEME_ALERT] : {
			scheme: '7',
			iconClassName: 'type-ico ico-alert',
		}
	};

	static defaultProps = {
		scheme: '1',
		className: '',
		animationDuration: ShoutBoard.duration,
		isLoading: false,
	};


	constructor(props, context) {
		super(props, context);
		this.state = {isRemoved: false};
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
		if(this.props.show && this.state.isRemoved)
		{
			this.setState({isRemoved: false});
		}
	}


	render()
	{
		if (this.state.isRemoved)
		{
			return '';
		}

		const {
			show, isLoading, animationDuration,
			className, title, htmlTitle,
			imageUrl, buttons, onCloseClick
		} = this.props;

		const {scheme, iconClassName} = ShoutBoard.SCHEME_MAP[this.props.scheme] ?? {scheme: this.props.scheme, iconClassName: ''};


		return (
			<Collapsible
				opened={show}
				duration={animationDuration}
				onComplete={() => !show && this.setState({isRemoved: true})}
			>
				<div className={classNames(
					'dashboard-shoutboard',
					'scheme-' + scheme,
					className,
					iconClassName,
					isLoading && 'state-waiting'
				)}>


					<div className="dashboard-shoutboard-text">
						{htmlTitle ?
							<h2 dangerouslySetInnerHTML={{
								__html: title
							}}
							/>
							: title && <h2>{title}</h2>
						}

						{this.props.children}

					</div>

					{
						imageUrl && (
							<div className="dashboard-shoutboard-image">
								<img src={imageUrl}/>
							</div>
						)
					}


					<div className="dashboard-shoutboard-toolbar">
						{buttons}
					</div>

					{onCloseClick !== undefined && <ActionLink className="dashboard-shoutboard-close" onClick={onCloseClick}>
						<span/>
					</ActionLink>}

				</div>
			</Collapsible>
		);
	}
}


ShoutBoard.Button = ({type, action, text, isLoading = false}) => (
	<div
		className={classNames(
			'dashboard-shoutboard-button',
			"type-" + type,
			'role-inline',
			isLoading && 'state-waiting'
		)}
	>
		{isString(action) ? <a href={action}>{text}</a> : <ActionLink onClick={action}>{text}</ActionLink>}
	</div>
);

ShoutBoard.Button.propTypes ={
	action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
	type: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	isLoading: PropTypes.bool,
};


ShoutBoard.propTypes = {
	show: PropTypes.bool.isRequired,
	scheme: PropTypes.oneOf([
		ShoutBoard.SCHEME_1, ShoutBoard.SCHEME_2, ShoutBoard.SCHEME_3,
		ShoutBoard.SCHEME_4, ShoutBoard.SCHEME_5, ShoutBoard.SCHEME_6,
		ShoutBoard.SCHEME_7, ShoutBoard.SCHEME_INFO,ShoutBoard.SCHEME_WARNING,
		ShoutBoard.SCHEME_ALERT,ShoutBoard.SCHEME_SUCCESS,
	]).isRequired,
	animationDuration: PropTypes.number,
	className: PropTypes.string,
	imageUrl: PropTypes.string,
	title: PropTypes.node,
	htmlTitle: PropTypes.bool,
	buttons: PropTypes.arrayOf(PropTypes.node),
	onCloseClick: PropTypes.func,
	isLoading: PropTypes.bool,
};
