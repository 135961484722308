import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ActionLink from "../ActionLink";
import Collapsible from "../Accordion/Collapsible";

export const ICON_DONE = 'ico-done';
export const ICON_INFO = 'ico-info';
export const ICON_MAGIC = 'ico-magic';
export const ICON_WARNING = 'ico-warning';

export const SCHEME_NEUTRAL = 'scheme-neutral';
export const SCHEME_SUCCESS = 'scheme-success';
export const SCHEME_FAIL = 'scheme-fail';
export const SCHEME_WARNING = 'scheme-warning';

export const LAYOUT_DEFAULT = '';
export const LAYOUT_FULL = 'dashboard-message-bar-layout-full';

export const LAYOUTS = [LAYOUT_DEFAULT, LAYOUT_FULL];
export const ICONS = [
	ICON_DONE,
	ICON_INFO,
	ICON_MAGIC,
	ICON_WARNING,
];
export const SCHEMES = [
	SCHEME_NEUTRAL,
	SCHEME_SUCCESS,
	// SCHEME_FAIL,
	// SCHEME_WARNING,
];

/**
 * Reusable Message bar component
 */
export default function MessageBar(
	{
		children,
		onCloseClick = ()=>{},
		icon = ICON_INFO,
		scheme = SCHEME_NEUTRAL,
		layout = LAYOUT_DEFAULT,
		showCloseButton = true,
		opened,
		duration = Collapsible.DEFAULT_DURATION,
	}
)
{
	const wrapper = React.useRef(null);
	return (
		<Collapsible
			opened={opened}
			duration={duration}
			forwardRef={wrapper}
			onComplete={() => opened && (wrapper.current && wrapper.current.removeAttribute('style'))}
		>
			<div className={classNames('dashboard-message-bar', scheme, icon, layout) }>
				<div className="dashboard-message-bar-inner">{children}</div>
				{
					showCloseButton &&
					<ActionLink className="dashboard-message-bar-close" onClick={onCloseClick}><span/></ActionLink>
				}
			</div>
		</Collapsible>
	);
}

MessageBar.propTypes = {
	icon: PropTypes.oneOf(ICONS),
	scheme: PropTypes.oneOf(SCHEMES),
	onCloseClick: PropTypes.func,
	layout: PropTypes.oneOf(LAYOUTS),
	showCloseButton: PropTypes.bool,
	opened: PropTypes.bool,
	duration: PropTypes.number,
};

MessageBar.CenteredContent = ({children, actions = []}) => (<p>
	{children}{' '}
	{
		actions.map((action, key) => (
			<React.Fragment key={key}>
				<br className="wbr"/>
				{action}
			</React.Fragment>
		))
	}
</p>);

MessageBar.CenteredContent.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.instanceOf(MessageBar.Link),
		PropTypes.instanceOf(MessageBar.Action),
	]))
}

MessageBar.FullContent = ({children, actions = []}) => (<>
	<p>{children}</p>
	<div className="dashboard-message-bar-toolbar">
		<p>
			{
				actions.map((action, key) => (
					<React.Fragment key={key}>
						{key > 0 && <br className="wbr"/>}
						{action}
					</React.Fragment>
				))
			}
		</p>
	</div>
</>);

MessageBar.CenteredContent.propTypes = MessageBar.FullContent.propTypes;

MessageBar.Link = ({href, children, ...props}) => <a href={href} {...props}><span>{children}</span></a>;
MessageBar.Link.propTypes = {
	href: PropTypes.string.isRequired,
};

MessageBar.Action = ({onClick, children, ...props}) => <ActionLink onClick={onClick} {...props}><span>{children}</span></ActionLink>;
MessageBar.Action.propTypes = {
	onClick: PropTypes.func.isRequired,
};
